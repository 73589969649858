<template>
  <div class="page">
    <component
      :is="component"
    />
  </div>
</template>

<script>
import PrivacyPolicyEN from './PrivacyPolicyEN.vue';
import PrivacyPolicyRU from './PrivacyPolicyRU.vue';
import TermsOfServiceEN from './TermsOfServiceEN.vue';
import TermsOfServiceRU from './TermsOfServiceRU.vue';

export default {
  props: {
    document: {
      type: String,
      required: true,
      validator: (value) => {
        const docs = [
          'terms-of-service',
          'privacy-policy',
        ];

        return docs.includes(value);
      },
    },
  },
  computed: {
    component() {
      const component = {
        'terms-of-service': {
          ru: TermsOfServiceRU,
          en: TermsOfServiceEN,
        },
        'privacy-policy': {
          ru: PrivacyPolicyRU,
          en: PrivacyPolicyEN,
        },
      };

      const locale = this.$route.query.lang || this.$root.$i18n.locale;

      return component[this.document][locale];
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  margin: 0 16px;
}

.container {
  max-width: 1200px;
  margin: 50px auto;
  text-align: justify;
  font: $font-rw-400;

  a {
    color: var(--clr-brand);
    text-decoration: underline;
  }
}
</style>
