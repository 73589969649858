import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';

import CreateStream from '../views/CreateStream/Index.vue';
import EmailVerify from '../views/EmailVerify.vue';
import GetApp from '../views/GetApp.vue';
import Home from '../views/Home/Index.vue';
import Legal from '../views/Legal/Index.vue';
import NotFound from '../views/NotFound.vue';
import Notifications from '../views/Notifications/Index.vue';
import Record from '../views/Record.vue';
import Round from '../views/Round/Index.vue';
import Search from '../views/Search/Index.vue';
import Settings from '../views/Settings/Index.vue';
import Stream from '../views/Stream/Index.vue';

import handleDeepLink from './handleDeepLink';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: {
      name: 'Home',
      params: {
        type: 'rounds',
      },
    },
  },
  {
    path: '/feed/:type',
    name: 'Home',
    props: true,
    component: Home,
    meta: {
      unprotected: true,
    },
  },
  {
    path: '/round/:id',
    name: 'Round',
    props: true,
    component: Round,
    meta: {
      unprotected: true,
    },
  },
  {
    path: '/stream/:id',
    name: 'Stream',
    props: true,
    component: Stream,
  },
  {
    path: '/createstream',
    name: 'CreateStream',
    component: CreateStream,
  },
  {
    path: '/profile/:id?',
    name: 'Profile',
    props: true,
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile/Index.vue'),
  },
  {
    path: '/edit',
    name: 'EditProfile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/EditProfile/Index.vue'),
  },
  {
    path: '/promocodes',
    name: 'Promocodes',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Promocodes/Index.vue'),
  },
  {
    path: '/messenger/:chatId?',
    name: 'Messenger',
    props: true,
    component: () => import(/* webpackChunkName: "messenger" */ '../views/Messenger/Index.vue'),
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: () => import(/* webpackChunkName: "wallet" */ '../views/Wallet/Index.vue'),
  },
  {
    path: '/record',
    name: 'Record',
    component: Record,
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
    props: {
      default: true,
      props: (route) => ({
        query: route.query.q,
        category: route.query.c,
      }),
    },
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications,
  },
  {
    path: '/new',
    name: 'RegisterSn',
    component: () => import(/* webpackChunkName: "register" */ '../views/RegisterSn/Index.vue'),
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
  },
  {
    path: '/settings/blocked-users',
    name: 'BlockedUsers',
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings/views/BlockedUsers/Index.vue'),
  },
  {
    path: '/switch-social-network',
    name: 'SwitchSocialNetwork',
    component: () => import(/* webpackChunkName: "settings" */ '../views/SwitchSocialNetwork/Index.vue'),
  },
  {
    path: '/legal/:document',
    name: 'Legal',
    component: Legal,
    props: true,
    meta: {
      unprotected: true,
    },
  },
  {
    path: '/email-verify',
    name: 'EmailVerify',
    props: true,
    component: EmailVerify,
    meta: {
      unprotected: true,
    },
  },
  {
    path: '/ref',
    name: 'Ref',
    meta: {
      unprotected: true,
    },
    beforeEnter: handleDeepLink,
  },
  {
    path: '/get-app',
    name: 'GetApp',
    component: GetApp,
    props: true,
    meta: {
      unprotected: true,
    },
  },
  {
    path: '/lottie',
    name: 'Lottie',
    component: () => import(/* webpackChunkName: "lottie" */ '../views/Lottie.vue'),
    meta: {
      unprotected: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: {
      unprotected: true,
    },
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

router.beforeEach((to, from, next) => {
  store.commit('setRoute', to);

  const { isAuthenticated } = store.getters;
  if (!isAuthenticated && (!to.meta.unprotected || (to.name === 'Home' && to.params.type !== 'rounds'))) {
    next({ name: 'Home', params: { type: 'rounds' } });
  } else next();
});

export default router;
