<template>
  <Item
    :video="video"
    :text="post.text"
    :post="post"
    @openComments="openComments"
  />
</template>

<script>
import Item from './Item.vue';

import Round from '@/models/Round';
import VideoFile from '@/models/VideoFile';

export default {
  components: {
    Item,
  },
  props: {
    post: {
      type: Round,
      required: true,
    },
  },
  computed: {
    video() {
      return new VideoFile({ url: this.post.video, ...this.post });
    },
  },
  methods: {
    openComments() {
      this.$emit('openComments');
    },
  },
};
</script>
