import auth from './auth';
import feed from './feed';
import messenger from './messenger';
import sn from './sn';
import user from './user';

export default {
  auth,
  feed,
  messenger,
  sn,
  user,
};
