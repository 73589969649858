import base from './base';
import onlyData from './onlyData';

export default {
  phoneLogin: ({ phone, code }) => base.v2().post('/auth/paid/token', {
    phone,
    code,
  }).then(onlyData),

  logout: () => base.v1().delete('/user/session'),

  sendPhoneCode: ({ phone, transport }) => base.v2().post('/auth/paid/code', {
    phone,
    transport,
  }).then(onlyData)
    .catch((error) => error.response?.data),

  signup: ({ phoneToken, inviteCode }) => base.v2().post('/auth/paid/grant', {
    phone_token: phoneToken,
    invite_code: inviteCode,
  }).then(onlyData)
    .catch((error) => error.response?.data),
};
