<template>
  <router-link
    class="result"
    :to="result.to"
  >
    <img
      :src="result.avatar"
      class="avatar supel"
    >
    <div class="text">
      <div class="name">
        {{ result.name }}
      </div>
      <div class="tag">
        {{ result.tag }}
      </div>
    </div>
  </router-link>
</template>

<script>
import ChannelResult from './searchResults/ChannelResult';
import RoundResult from './searchResults/RoundResult';
import UserResult from './searchResults/UserResult';

export default {
  props: {
    searchResult: {
      type: Object,
      required: true,
    },
  },
  computed: {
    result() {
      switch (this.searchResult.category) {
        case 'hashtags':
          return new RoundResult(
            this.searchResult.category,
            this.searchResult.url_preview_200,
            this.searchResult.message,
            this.searchResult.from.username,
            this.searchResult.idt_round,
            this.$t('post'),
          );

        case 'channels':
          return new ChannelResult(
            this.searchResult.category,
            this.searchResult.avatar,
            this.searchResult.name,
            this.searchResult.username,
            this.searchResult.chat_id,
            this.$t('messenger.channel'),
          );

        case 'users':
        default:
          return new UserResult(
            this.searchResult.category,
            this.searchResult.avatar,
            this.searchResult.name,
            this.searchResult.username,
            this.searchResult.idt_user,
          );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.result {
  display: flex;
  margin-bottom: 8px;

  .avatar {
    width: 48px;
    min-width: 48px;
    height: 48px;
    margin-right: 8px;
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: calc(100% - 56px);

    .name {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .tag {
      color: $clr-text-secondary;
      font: $font-rw-300;
    }
  }
}
</style>
