<template>
  <div
    v-if="loaded"
    id="app"
  >
    <div class="_app-bg" />
    <Modal ref="login">
      <Login @loggedIn="$refs.login.close()" />
    </Modal>
    <Modal ref="getApp">
      <GetApp />
    </Modal>
    <TopNav />
    <router-view class="view" />
    <TabBar />

    <div id="modal-spot" />
  </div>
  <div v-else>
    Loading...
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import api from '@/api';
import handleMessages from '@/mixins/handleMessagesMixin';

import GetApp from '@/components/GetApp.vue';
import Modal from '@/components/Modal.vue';
import Login from '@/components/TheLogin/Index.vue';
import TabBar from '@/components/TheTabBar.vue';
import TopNav from '@/components/TheTopNav/Index.vue';

import snFromBackend from '@/models/dto/snFromBackend';

export default {
  components: {
    TopNav,
    TabBar,
    Login,
    GetApp,
    Modal,
  },
  mixins: [handleMessages],
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapState({
      selectedSn: (state) => state.api.sn.selectedSn,
    }),
  },
  watch: {
    selectedSn() {
      if (!this.selectedSn) return;

      document.title = this.selectedSn.name;
      const faviconElement = document.getElementById('favicon');
      faviconElement.href = this.selectedSn.avatar;
    },
  },
  beforeCreate() {
    this.$store.commit('setToken');
    this.$store.commit('setLocale', { vm: this });
  },
  async mounted() {
    this.$emitter.on('unauthedAction', () => this.$refs.login.open());
    this.$emitter.on('getApp', () => this.$refs.getApp.open());

    await this.setSnFromDomain();
    await this.getUser();
    this.loaded = true;
  },
  methods: {
    ...mapMutations(['setSelectedSn']),
    async setSnFromDomain() {
      const baseSn = window.location.host === process.env.VUE_APP_BASE_URL;

      let snUsername;
      if (process.env.NODE_ENV === 'development') {
        snUsername = 'profi';
      } else if (baseSn) {
        snUsername = 'zhara';
      } else {
        [snUsername] = document.location.host.split('.');
      }

      const sn = await api.sn.fetchSn({ username: snUsername });
      if (sn.code && !baseSn) {
        window.location.href = `https://${process.env.VUE_APP_BASE_URL}`;
      } else {
        this.setSelectedSn(snFromBackend(sn));
      }

      if (snUsername === 'zhara') {
        document.documentElement.style.setProperty('--clr-brand', '#FF8214');
      } else {
        document.documentElement.style.setProperty('--clr-brand', 'royalblue');
      }
    },
    async getUser() {
      const { profile } = await api.user.fetchProfile({});
      this.$store.commit('setProfile', profile);
    },
  },
};
</script>

<style lang="scss">
@import "reset-css";
@import "scss/style";

:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}

#app {
  font-family: $text-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $clr-05;
  width: 100%;

  .view {
    padding-top: 96px;
  }

  ._app-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-color: $clr-05;
  }
}
</style>
