<template>
  <div>
    <img
      :src="round.preview"
    >
  </div>
</template>

<script>
import Round from '@/models/Round';

export default {
  props: {
    round: {
      type: Round,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  display: block;
  object-fit: cover;
  height: 100%;
  width: 100%;
}
</style>
