import Round from '../Round';
import VideoRound from '../VideoRound';

import userFromBackend from './userFromBackend';

const hasThreeD = (post) => {
  const threeDKeys = ['modification', 'photos', 'audios'];
  return threeDKeys.reduce((keyFound, key) => keyFound || Object.keys(post).includes(key), false);
};

export default function roundFromBackend(data) {
  let round;

  if (data.media && data.media.url) {
    round = new VideoRound();
  } else {
    round = new Round();
  }

  round.id = data.idt_round;
  round.title = data.title;
  round.description = data.message;
  round.liked = data.likes.is_liked;
  round.lat = data.lat;
  round.lng = data.lng;
  round.viewCount = data.view_count;
  round.likeCount = data.likes.likes_count;
  round.commentCount = data.comments_count;
  round.preview = data.url_preview;
  round.createdAt = new Date(data.a_time * 1000);
  round.author = userFromBackend(data.from);
  round.hasThreeD = hasThreeD(data);
  round.link = {
    name: 'Round',
    params: {
      id: round.id,
    },
  };

  if (round instanceof VideoRound) {
    round.video = data.media.url;
    round.duration = data.media.duration;
    round.size = data.media.size;
  }

  return round;
}
