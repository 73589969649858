<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      defaultSn: (state) => state.api.sn.defaultSn ?? state.api.sn.selectedSn,
    }),
    brand() {
      return {
        contactEmail: 'support@zhara.social',
      };
    },
  },
};
</script>

<!-- eslint-disable -->
<template>
  <div class="container">
    <h1>User Agreement</h1>
    <div>{{ defaultSn.name }} INC.</div>
    <div>Version 2.2 | Created: 11.05.2021</div>
    <p>Welcome to {{ defaultSn.name }} (the "Platform")!</p>
    <p>
      This Agreement governs your use of the {{ defaultSn.name }} Platform and
      contains the information about the {{ defaultSn.name }} Platform set forth
      below. The text of the Agreement is a public offer, by creating an account
      in {{ defaultSn.name }}, you confirm that you have read the current version of
      the Agreement and unconditionally accept these terms (make the acceptance
      of the offer), and the Agreement becomes legal between you and the
      {{ defaultSn.name }} Platform:
    </p>
    <h2>The {{ defaultSn.name }} Platform.</h2>
    <p>
      We agree to grant you access to the {{ defaultSn.name }} Platform. The
      Platform includes all {{ defaultSn.name }} products, features, applications,
      services, technology, and software that we provide to fulfill our mission.
      To help you get closer to the people you love in the world of augmented
      reality. The platform includes the following components:
    </p>
    <ul>
      <li>
        Offering a personalized opportunity to create content, communicate,
        learn new things and share experiences and experiences with others.
      </li>
      <br />
      <li>Creating a supportive, safe and inclusive environment.</li>
      <br />
      <li>
        We develop specific tools and offer our community members innovative
        technologies that help make {{ defaultSn.name }} a universe that is
        supportive and open in the way that everyone wants it.
      </li>
      <br />
      <li>Maintaining a stable ecosystem for Platform users.</li>
      <br />
      <li>
        Connecting you with the brands, products and services of interest.
      </li>
      <br />
      <li>Research and innovation.</li>
    </ul>
    <br />
    <h2>Data Use Policy</h2>
    <p>
      The Privacy Policy explains how we collect, use and share information
      across {{ defaultSn.name }}'s various services. To use {{ defaultSn.name }}, you
      must accept the
      <router-link
        :to="{ name: 'Legal', params: { document: 'privacy-policy' }}"
        target="_blank"
      >
        Privacy Policy
      </router-link>.
    </p>
    <br />
    <h2>Your Commitment</h2>
    <p>
      In exchange for our commitment to provide access to the Platform, you
      assume the following obligations to us.
    </p>
    <h3>Who may use {{ defaultSn.name }}.</h3>
    <p>
      We want our Platform to be as open and accessible to everyone as possible,
      but also safe, secure and legally compliant. Therefore, in order to become
      part of the {{ defaultSn.name }} community, you must comply with certain
      restrictions:
    </p>
    <ul>
      <li>
        You must be at least 12 years old, or the minimum age at which
        {{ defaultSn.name }} is legally allowed in your country.
      </li>
      <br />
      <li>
        Your account must not have been previously disabled by us for violating
        the law or any of our policies.
      </li>
      <br />
      <li>
        You must not have been convicted of a sexual or terrorist offense.
      </li>
    </ul>
    <h3>Prohibited uses of {{ defaultSn.name }}.</h3>
    <p>
      Providing a safe and open Platform to the general community requires that
      each of us abide by the rules.
    </p>
    <ul>
      <li>
        You may not impersonate others or provide inaccurate information, post
        any information within the Platform on behalf of third parties without
        permission/consent.
      </li>
      <br />
      <li>
        You are not required to disclose your identity to {{ defaultSn.name }}, but
        you must provide us with accurate and up-to-date information (including
        registration information).
      </li>
      <br />
      <li>
        You may not commit unlawful, misleading or deceptive acts or other acts
        for unlawful or unauthorized purposes. Which includes supporting or
        glorifying terrorist activities, organized crime and hate groups.
        {{ defaultSn.name }} is prohibited from offering or providing sexual
        services, buying and selling firearms, alcohol and tobacco products
        individually, or buying and selling drugs, psychotropic drugs or
        prescription medications (even if they are legal in your area). Poaching
        and the sale of endangered species and their body parts is strictly
        prohibited at {{ defaultSn.name }}. {{ defaultSn.name }} prohibits advertising
        or otherwise soliciting information about terrorist activities,
        organized crime and hate groups, sexual services, firearms, alcohol and
        tobacco products individually, and drugs or prescription drugs (even if
        these are legal in your area). We consider threats to publish intimate
        photos of others, as well as the publication of sexual material
        involving minors, to be unacceptable.
      </li>
      <br />
      <li>
        You may not violate (or assist or encourage others to violate or
        encourage others to violate) these Terms or our policies, including but
        not limited to our
        <router-link
        :to="{ name: 'Legal', params: { document: 'privacy-policy' }}"
        target="_blank"
      >
        Privacy Policy
      </router-link>.
      </li>
      <br />
      <li>
        You may not interfere with or disrupt the normal operation of the
        Platform, or attempt to gain unauthorized access to any part or function
        of the Platform, or to any network connected to the Platform.
      </li>
      <br />
      <li>
        You may not attempt to create accounts, collect information, or access
        it by unauthorized means. This includes creating accounts and collecting
        information through automated means without our express permission -
        using any algorithms, methods, techniques, processes, devices to gain
        access to third party information using the Platform; accessing,
        purchasing, copying or tracking any portion of the Platform to reproduce
        or gain access bypassing the navigation or display system used by the
        Platform; obtain or attempt to obtain any materials, documents or
        information by any means not specifically provided through the Platform.
      </li>
      <br />
      <li>
        You may not attempt to buy, sell, or transfer any component of your
        account (including your username) or solicit, collect, or use
        credentials or avatars of other users.
      </li>
      <br />
      <li>
        You may not publish someone else's private or confidential information
        or perform any violation of the rights of others, including intellectual
        property rights.
      </li>
      <br />
      <li>
        You may not use any inappropriate, obscene, abusive, insulting,
        threatening, discriminatory (on any grounds) words and expressions, use
        as avatars, attach to comments, or post links to images containing
        pornography, violence, propaganda of terrorism, neo-Nazism,
        discrimination on any grounds, alcohol or drugs, foul, offensive
        messages, profanity.
      </li>
      <br />
      <li>
        You may not insult, defame, humiliate, threaten, damage business
        reputation, or otherwise infringe on the rights of other Users or third
        parties.
      </li>
      <br />
      <li>
        You may not post, publish or distribute unlawful, obscene, harmful
        information, information that incites or promotes unlawful or criminal
        activity, inter-ethnic or ethnic conflicts, wars, clashes, riots, social
        unrest, distribute information that incites racial or ethnic hatred and
        hostility, post instructions to commit criminal or unlawful acts,
        instructions to commit suicide.
      </li>
      <br />
      <li>
        You may not distribute any software viruses, trojans, corrupted files,
        or any other items of a destructive or misleading nature.
      </li>
    </ul>
    <p>
      If you notice any of the above, you can complain about content you think
      is illegal or violates your intellectual property rights via a Message to
      the {{ defaultSn.name }} team in the app, or you can email us at
      {{ brand.contactEmail }}.
    </p>
    <h3>Permissions You Grant.</h3>
    <p>
      As part of our agreement, you also give us the permissions necessary to
      grant you access to the Platform.
    </p>
    <ul>
      <li>
        Your rights in your content remain unaltered. We do not claim ownership
        of your content that you publish on or through the Platform. Instead,
        when you publish or upload content that is subject to intellectual
        property rights (such as photos or videos) to our Platform or share such
        content, you grant us a non-exclusive, royalty-free, worldwide,
        transferable and sublicensable license to store, use, distribute,
        modify, launch, copy, publicly perform or display, translate your
        content and create derivative works based on it. You may terminate this
        license at any time by deleting your content or account. To learn how we
        use information and how to manage or remove your content, see our
        <router-link
          :to="{ name: 'Legal', params: { document: 'privacy-policy' }}"
          target="_blank"
        >
          Privacy Policy
        </router-link>.
      </li>
      <br />
      <li>
        Permission to use your username, profile picture and information about
        your relationships and activities in connection with accounts,
        advertisements and sponsored content.
      </li>
      <br />
      <li>
        You grant us permission to display your username, profile picture, and
        information about your activities (such as "Likes") or relationships
        (such as subscriptions) beside or in connection with accounts,
        advertisements, offers, or other sponsored content that you subscribe to
        or interact with.
      </li>
      <br />
      <li>
        You agree that embedded services may download and install updates to the
        Platform on your device.
      </li>
    </ul>
    <br />
    <h2>Monetization</h2>
    <p>
      We give creative and talented people a unique chance to make money from
      their content. To this end, in {{ defaultSn.name }} there is a possibility for
      every user to earn money. With full information about possibilities of
      earnings and compensation for these or other actions in
      {{ defaultSn.name }} you can read in the profile of User in the section of the
      Wallet.
    </p>
    <p>
      Currency of payments between the Parties is Euro (Euro), Dollar (USD), or
      any form of electronic (digital, cryptographic) currency in accordance
      with the individual rules developed and approved by the Company for the
      purposes of payments and settlements for the Service.
    </p>
    <p>
      Remuneration is paid by the Company within 30 business days from the date
      the remuneration amount is placed in the Wallet section, provided that the
      User provides all the necessary information for remuneration payment.
    </p>
    <p>
      In the payment of remuneration in an amount exceeding 1,999 Euros,
      additional requirements may be imposed on {{ defaultSn.name }} by credit
      institutions in terms of justification of payments made. In this case, the
      term of payment is extended by the period of fulfillment of the
      requirements of the credit institution. Thus the Company has the right to
      request from the User additional documents and the information necessary
      for payment realization payment. If the User fails to provide the
      necessary documents and information, the User may not file any claims
      against the Company.
    </p>
    <p>
      All payments are made to the User after deduction of value added tax,
      sales tax or other taxes, if the obligation to calculate and pay such tax
      is imposed on {{ defaultSn.name }} by applicable law.
    </p>
    <p>
      Any questions you may have can be answered through the Message to the
      {{ defaultSn.name }} team in the app or by emailing us at
      <a :href="`mailto:${brand.contactEmail}`">{{ brand.contactEmail }}</a>
    </p>
    <br />
    <h2>Trials, demos and betas</h2>
    <p>
      From time to time, {{ defaultSn.name }} may offer users the opportunity to
      access and use trial, beta or demo versions of the Platform for a publicly
      available commercial release of the Platform. Trial, beta or demo versions
      may (at {{ defaultSn.name }}'s discretion) be limited in scope and offered
      only to users who meet certain criteria defined by {{ defaultSn.name }}. In
      addition, trials, betas, or demos may be subject to additional terms and
      conditions.
    </p>
    <p>
      Trials, betas, and demos are often offered for a limited period of time to
      test new features or functions of the application before launching such
      features. {{ defaultSn.name }} reserves the right to suspend access to trials,
      betas, or demos (or portions thereof) at any time, without notice.
    </p>
    <p>
      You acknowledge and agree that trial, beta, or demo versions of the
      Application are released for testing and improvement purposes only, in
      particular to provide {{ defaultSn.name }} with feedback on the quality and
      usability of such trial, beta, or demo versions. Certain features may also
      be disabled in a trial, beta, or demo. In addition, betas or demos may
      contain bugs and create incompatibilities or damage to your mobile device.
      Therefore, we recommend that you keep full backups of any system you
      choose to install or use trial, beta or demo versions of the app.
    </p>
    <br />
    <h2>Additional rights that we reserve</h2>
    <p>
      {{ defaultSn.name }} may modify the software comprising the Platform at any
      time to improve the Platform, to correct defects, errors, malfunctions in
      the Platform, and to suspend the Platform upon discovery of unauthorized
      access to the Platform. If you use content that is subject to intellectual
      property rights held by us and provided by us on our Platform (for
      example, when creating a round and you may add 3D models, images, artwork,
      videos or sounds provided by us), we reserve all rights to our content
      (but not to your content).
    </p>
    <p>
      You may only use our intellectual property and trademarks with our prior
      written permission. To do this, email us at
      <a :href="`mailto:${brand.contactEmail}`">{{ brand.contactEmail }}</a
      >.
    </p>
    <p>
      You must obtain written permission from us or permission under an open
      license before modifying, creating derivative works, decompiling, or
      otherwise attempting to obtain source code from us.
    </p>
    <p>
      Taxes and fees on remuneration amounts to be assessed and payable under
      applicable law, you are responsible for your own payment.
      {{ defaultSn.name }} is not responsible for your failure to meet your tax
      obligations. You are not entitled to claim from {{ defaultSn.name }} the
      reimbursement (compensation) of tax or other mandatory payments withheld
      by the Company pursuant to applicable law from the amounts of compensation
      paid to you.
    </p>
    <br />
    <h2>
      Removal of Content and Temporary or Permanent Disabling of Your Account
    </h2>
    <p>
      We may remove any content or information you share on the Platform if we
      believe it violates these Terms of Use or our policies, or if permitted or
      required by law. We may refuse or immediately terminate your access to all
      or part of the Platform (including temporarily or permanently disabling
      your account) to protect our community or services, or if you pose a risk
      or adverse legal effect to us, violate these Terms of Use or our policies,
      systematically violate the intellectual property rights of others, or are
      permitted or required by law. If action is taken to temporarily or
      permanently disable your account, we will notify you, if appropriate. If
      you believe your account has been disabled in error, or you wish to
      disable or delete your account, please email us at
      {{ brand.contactEmail }}.
    </p>
    <br />
    <h2>If there is a disagreement.</h2>
    <p>
      Any amendment or waiver of our agreement must be in writing and signed by
      us. If you wish to withdraw from this agreement, you must email
      <a :href="`mailto:${brand.contactEmail}`">{{ brand.contactEmail }}</a> a
      statement to that effect in a scanned written document certified by your
      signature and cease all use of the Platform. If we do not seek to enforce
      any aspect of this agreement, it is not considered a waiver.
    </p>
    <h3>Who has rights under this agreement.</h3>
    <p>This agreement does not give any rights to third parties.</p>
    <p>
      You may not assign your rights or obligations under this agreement without
      our consent.
    </p>
    <p>
      We may assign our rights and obligations to others. For example, this may
      occur in the event of a change in ownership (by merger, takeover or sale
      of assets) or by operation of law.
    </p>
    <h3>Who would be liable if anything happened.</h3>
    <ul>
      <li>
        Our Platform is provided "as is" and we cannot guarantee its safety,
        security or perfect operation. TO THE EXTENT PERMITTED BY LAW, WE ALSO
        DISCLAIM ALL EXPRESS AND IMPLIED WARRANTIES, INCLUDING IMPLIED
        WARRANTIES OF FITNESS FOR A PURPOSE FOR WHICH SUCH PRODUCTS ARE
        CUSTOMARILY USED OR FOR A PARTICULAR PURPOSE, INALIENABILITY OF TITLE
        AND NON-INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS.
      </li>
      <br />
      <li>
        We also have no control over the expressions and actions of people and
        others and are not responsible for their (or your) actions and conduct
        (online and offline) or content (including illegal or objectionable). In
        addition, we are not responsible for services or features offered by
        other people or companies, even if you access them through our Platform.
        Links posted on the Platform to any websites, products, services, or any
        information of a commercial or non-commercial nature do not constitute
        an endorsement or recommendation of those products (services) by
        {{ defaultSn.name }}.
      </li>
      <br />
      <li>
        Our liability for anything happening on the Platform (also referred to
        as "liability") is limited to the maximum extent permitted by law.
        Should problems arise with our Platform, we are unable to predict all of
        their potential consequences. You agree that we will not be liable for
        any lost profits or revenues, lost information or data, or for indirect,
        punitive or consequential damages arising out of or in connection with
        these Terms, even if we have been advised of the possibility of their
        occurrence. This includes any deletion by us of your content,
        information or account, as well as damages that may be caused to you as
        a result of your inability to use the Platform due to errors, omissions,
        interruptions, deletion of files, defects, delays in
      </li>
      <br />
      <li>
        You may not be able to use the Platform due to errors, omissions,
        interruptions, deletion of files, defects, delays in operation or data
        transmission or other causes of technological problems, or defects in
        the quality of the public communication channels through which you
        access the Platform.
      </li>
      <br />
      <li>
        Unless proven otherwise, any activity performed using a Platform User
        account shall be deemed to have been performed by the relevant User.
      </li>
      <br />
      <li>
        You are responsible for maintaining the confidentiality of your
        Credentials, for not transferring your Credentials to third parties and
        for choosing your own method of storing them.
      </li>
    </ul>
    <h3>How We Resolve Disputes.</h3>
    <p>
      In the event of any disagreement or dispute between the Parties,
      {{ defaultSn.name }} will use its best efforts to resolve it through internal
      negotiations. Unresolved disputes relating to the terms of this Agreement
      shall be resolved by the Court of Arbitration of the Republic of Cyprus,
      in accordance with the procedural rules and the applicable law.
    </p>
    <h3>Materials submitted at your initiative.</h3>
    <p>
      Feedback and other suggestions are important to us, but we may use them
      without any restrictions or obligation to pay you a fee for them, nor are
      we obligated to keep them confidential.
    </p>
    <br />
    <h2>Amendments and Revisions</h2>
    <p>
      These Terms of Use constitute the entire agreement between
      {{ defaultSn.name }} and the User regarding the Platform and supersede all
      prior agreements and understandings between us.
    </p>
    <p>
      {{ defaultSn.name }} reserves the right to amend or modify these Terms of Use
      at any time. Any changes to these Terms of Use will be notified to Users
      in advance and will become effective upon acceptance by Users. If you do
      not accept amended or new Terms of Use, you may not access, use or
      continue to use the Platform. {{ defaultSn.name }} recommends that you
      periodically review the Terms of Use to be informed of any changes.
      {{ defaultSn.name }} further reserves the right to change or modify the
      content of the Application at any time without notice and will not be
      liable to any party for the possible consequences of such changes.
      {{ defaultSn.name }} may suspend, terminate or restrict access to any part of
      the Platform at any time and without notice.
    </p>
    <p>
      By using the Platform after changes and/or additions have become
      effective, you consent to any and all such changes and/or additions.
    </p>
    <p>
      {{ defaultSn.name }} assumes no obligation to archive previous versions of
      these Terms of Use. Instead, {{ defaultSn.name }} recommends that you print
      and save a copy of each version of these Terms of Use.
    </p>
    <br />
    <h2>Contact Information</h2>
    <p>
      Questions, comments and requests regarding this Agreement should be
      addressed to:
    </p>
    <p>Boumpoulinas, 1, 3rd floor, Flat/Office 31, 1060, Nicosia, Cyprus</p>
    <p>
      <a :href="`mailto:${brand.contactEmail}`">{{ brand.contactEmail }}</a>
    </p>
  </div>
</template>
