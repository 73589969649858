export default (Api) => ({
  actions: {
    getStream({ _ }, id) {
      return Api.v2.get(`/streams/${id}`).then((response) => response.data);
    },

    likeStream({ _ }, id) {
      return Api.v2.post(`/streams/like/${id}`).then((response) => response.data.user_likes);
    },

    addStreamComment({ _ }, { id, text }) {
      return Api.v2.post(`/streams/comment/${id}`, {
        text,
      });
    },

    createStream({ _ }, stream) {
      const formData = new FormData();
      Object.keys(stream).forEach((prop) => {
        if (stream[prop] !== undefined) {
          formData.append(prop, stream[prop]);
        }
      });

      return Api.v2
        .post('/streams', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((response) => response.data)
        .catch((error) => error.response.data);
    },

    async openStreamSocket({ rootState }, id) {
      return new Promise((resolve) => {
        const { token } = rootState.api;
        const websocketURL = process.env.VUE_APP_API_URL.replace('https', 'wss');
        const socket = new WebSocket(
          `${websocketURL}/v2/streams/tracker/${id}?token=${token}`,
        );
        resolve(socket);
      });
    },
  },
});
