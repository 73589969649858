import File from './File';

export default class VideoFile extends File {
  constructor(source) {
    super(source);

    this.duration = source.duration;
    this.preview = source.preview;
  }
}
