<template>
  <div class="logo">
    <img
      class="avatar"
      :src="selectedSn.avatar"
    >
    <h1>
      {{ localizedName }}
    </h1>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      selectedSn: (state) => state.api.sn.selectedSn,
    }),
    localizedName() {
      switch (this.$root.$i18n.locale) {
        case 'ru':
          return this.selectedSn.nameRu;
        default:
          return this.selectedSn.name;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  display: flex;
  gap: 12px;
  align-items: center;
}

.avatar {
  height: 40px;
  width: 40px;
  border-radius: 8px;
}
</style>
