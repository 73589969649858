import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions(['openStreamSocket']),
    async initStreamSocket(id) {
      const socketStream = await this.openStreamSocket(id);

      socketStream.addEventListener('close', () => {
        setTimeout(() => {
          console.log('RECONNECT');
          this.initStreamSocket(id);
        }, 1000);
      });

      socketStream.addEventListener('message', this.handleResponse);

      return socketStream;
    },
    handleResponse(response) {
      if (!response.data) return;

      const handlers = {
        first: this.firstEvent,
        new_comment: this.newComment,
        new_like: this.newLike,
        user_joined: this.userJoined,
        user_left: this.userLeft,
      };

      const action = JSON.parse(response.data);
      const actionName = action.type;

      setTimeout(() => {
        handlers[actionName]?.(action); // eslint-disable-line
      });
    },
  },
};
