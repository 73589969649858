<template>
  <portal selector="#modal-spot">
    <div
      v-if="show"
      class="bg"
      @click="close"
    />
    <transition name="slide-up">
      <div
        v-if="show"
        class="modal"
      >
        <div
          class="close"
          @click="close"
        >
          <img src="@/assets/icons/times.svg">
        </div>
        <slot />
      </div>
    </transition>
  </portal>
</template>

<script>
export default {
  props: {
    callback: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    document.addEventListener('keyup', (event) => {
      if (event.key === 'Escape') {
        this.close();
      }
    });
  },
  methods: {
    open() {
      this.show = true;
    },
    close() {
      if (typeof this.callback === 'function') this.callback();
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(64, 68, 128, 0.08);
  backdrop-filter: blur(16px);
  z-index: 100;
}

.modal {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 328px;
  max-width: 400px;
  max-height: 90vh;
  position: fixed;
  z-index: 1000;
  background: white;
  border: solid 1px $clr-border-light;
  padding: 36px;
  border-radius: 16px 16px;
  box-sizing: border-box;

  .close {
    background-color: $clr-10;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    position: absolute;
    top: 12px;
    right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .modal {
    position: fixed;
    width: 100%;
    max-width: unset;
    transform: translateY(0);
    bottom: 0;
    left: 0;
    top: unset;
    padding: 16px 16px 96px 16px;
    border-radius: 16px 16px 0px 0px;
  }

  .slide-up-enter-active, .slide-up-leave-active {
    transition: transform .5s;
  }
  .slide-up-enter, .slide-up-leave-to {
    transform: translateY(100%);
  }
}
</style>
