export default {
  extractUrlsWithoutProtocol: true,
  targetBlank: true,
  mentionsWithDots: true,
  usernameClass: 'username-highlight',
  usernameUrlBase: '/profile/',
  hashtagClass: 'hashtag-highlight',
  hashtagUrlBase: '/search?c=hashtags&q=%23',
  urlClass: 'url-highlight',
};
