import i18n from '@/localization';

export default {
  id: 'jinny',
  name: i18n.t('messenger.jinny'),
  username: i18n.t('messenger.jinny'),
  type: 'jinny',
  verified: true,
  avatar: require('../assets/jinny.png'), // eslint-disable-line
  last_message: {
    text: i18n.t('messenger.your_personal_assistant'),
  },
};
