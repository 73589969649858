var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.round)?_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.visibilityChanged,
    once: true,
    throttle: 1000,
  }),expression:"{\n    callback: visibilityChanged,\n    once: true,\n    throttle: 1000,\n  }"}],staticClass:"item"},[_c('div',{staticClass:"user"},[_c('Poster',{attrs:{"poster":_vm.round.author,"created-at":_vm.round.createdAt}}),(!_vm.$store.state.api.feed.personalFeedSelected)?_c('div',[_c('FollowButton',{attrs:{"id":_vm.proxyRound.author.id,"is-following":_vm.proxyRound.author.isFollowing},on:{"update":status => _vm.$set(_vm.proxyRound.author, 'isFollowing', status)}})],1):_vm._e()],1),_c('div',{staticClass:"message",domProps:{"innerHTML":_vm._s(_vm.highlightedMessage)}}),_c('div',{staticClass:"video"},[_c('div',{staticClass:"report",on:{"click":_vm.report}},[_c('FlagIcon')],1),_c('div',{staticClass:"mute",on:{"click":_vm.mute}},[(_vm.muted)?_c('MuteIcon'):_c('SpeakerIcon')],1),(_vm.loaded)?_c('video',{ref:"video",staticClass:"video",attrs:{"loop":"","autoplay":_vm.first,"src":_vm.round.video,"preload":"auto","poster":_vm.round.preview},domProps:{"muted":_vm.muted},on:{"click":_vm.handleVideoClick}}):_c('img',{staticClass:"video",attrs:{"src":_vm.round.preview}}),_c('div',{staticClass:"interact"},[_c('div',{staticClass:"btn",on:{"click":_vm.like}},[(_vm.round.liked)?_c('div',{staticClass:"like liked",class:{
            clickable: _vm.isAuthenticated,
          }}):_c('HeartIcon',{staticClass:"like",class:{
            clickable: _vm.isAuthenticated,
          }}),_c('span',{staticClass:"number"},[_vm._v(" "+_vm._s(_vm.formattedLikes)+" ")])],1),_c('div',{staticClass:"btn",on:{"click":_vm.openComments}},[_c('CommentIcon',{staticClass:"clickable"}),_c('span',{staticClass:"number"},[_vm._v(" "+_vm._s(_vm.formattedComments)+" ")])],1)])]),_c('ReportModal',{ref:"report",attrs:{"id":_vm.round.id,"target":"round"}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }