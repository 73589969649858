<template>
  <video
    id="remoteVideo"
    ref="vid"
    autoplay
    playsinline
    webkit-playsinline
    :muted="muted"
    @canplay="handleCanPlay"
  />
</template>

<script>
import { WebRTCAdaptor } from './scripts/webrtc_adaptor';

export default {
  emits: ['canplay', 'streamEnded'],
  props: {
    stream: {
      type: Object,
      required: true,
    },
    muted: {
      type: Boolean,
    },
  },
  data() {
    return {
      webRTCAdaptor: undefined,
      playStarted: false,
      canPlay: false,
    };
  },
  async mounted() {
    this.initWebRTC();
  },
  // destroyed() {
  //   this.webRTCAdaptor.stop(this.stream.idt_stream);
  // },
  methods: {
    watchStream() {
      this.webRTCAdaptor.play(this.stream.idt_stream);
    },
    initWebRTC() {
      const pcConfig = null;

      const sdpConstraints = {
        OfferToReceiveAudio: true,
        OfferToReceiveVideo: true,
      };
      const mediaConstraints = {
        video: true,
        audio: true,
      };

      const vm = this;
      this.webRTCAdaptor = new WebRTCAdaptor({
        websocket_url: process.env.VUE_APP_STREAM_URL,
        mediaConstraints,
        peerconnection_config: pcConfig,
        sdp_constraints: sdpConstraints,
        remoteVideoId: 'remoteVideo',
        isPlayMode: true,
        callback(info) {
          if (info === 'initialized') {
            console.log('initialized');
            vm.watchStream();
          } else if (info === 'play_started') {
            console.log('play started');
            vm.playStarted = true;
            if (vm.canPlay) vm.$emit('canplay');
          } else if (info === 'play_finished') {
            setTimeout(() => {
              vm.initWebRTC();
            }, 1000);
            console.log('play finished, trying to reconnect');
            // vm.$emit('streamEnded');
          }
        },
        callbackError(error) {
          // some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError
          console.log(`error callback: ${error}`);
        },
      });
    },
    handleCanPlay() {
      this.canPlay = true;
      if (this.playStarted) this.$emit('canplay');
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
