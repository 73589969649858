<template>
  <div>
    <div class="text">
      {{ $t('download_app', { snName: selectedSn.name, snNameRu: selectedSn.nameRu }) }}
    </div>
    <AppLinks
      class="app-links"
      :color="$scss.n10"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import AppLinks from '@/components/AppLinks.vue';

export default {
  components: {
    AppLinks,
  },
  computed: {
    ...mapState({
      selectedSn: (state) => state.api.sn.selectedSn,
    }),
  },
};
</script>

<style lang="scss" scoped>
.text {
  text-align: center;
  margin: 32px 0;
  font: $font-rw-400;
}

.app-links {
  text-align: center;
}
</style>
