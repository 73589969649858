<template>
  <div
    class="item"
    :class="{
      active,
      disabled
    }"
    @click="handleClick"
  >
    <div
      class="icon"
      :class="{active}"
    >
      <slot />
    </div>
    <span>{{ name }}</span>
    <span
      v-if="disabled"
      class="soon"
    >{{ $t("soon") }}</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'rounds',
    },
    disabled: Boolean,
    active: Boolean,
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
    }),
  },
  methods: {
    handleClick() {
      if (!this.disabled) {
        if (this.isAuthenticated) {
          this.$router.push({ name: 'Home', params: { type: this.type } });
        } else {
          this.$emitter.emit('unauthedAction');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  font: $font-sbw-400;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;

  > * {
    margin-right: 12px;
  }

  &.active {
    color: var(--clr-brand);
  }

  &.disabled {
    cursor: default;
  }

  span {
    color: inherit;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: white;

    &.active {
      background: $grad-brand;
    }
  }

  .soon {
    color: $clr-text-trietary;
    font: $font-rw-200;
    transform: translate(-10px, -8px);
  }
}
</style>
