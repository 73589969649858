export default class InviteCode {
  id;

  value;

  createdAt;

  archivedAt;

  inviteeCount;

  sn;

  constructor(sn) {
    Object.assign(this, sn);
  }
}
