<template>
  <div class="media">
    <img
      :src="round.url_preview"
    >
  </div>
</template>

<script>
export default {
  props: {
    round: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import './media';

img {
  height: 100%;
  max-width: 100%;
  margin: auto;
  display: block;
  object-fit: contain;
}
</style>
