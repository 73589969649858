import Channel from '../Channel';
import File from '../File';
import Message from '../Message';
import VideoFile from '../VideoFile';

import roundFromBackend from './roundFromBackend';
import userFromBackend from './userFromBackend';

// handles
// https://arround-msg.gora.studio/doc/#api-Datatype-message
// https://arround-msg.gora.studio/doc/#api-Datatype-post
export default function messageFromBackend(data) {
  const fromFeed = !data.author;
  let author;

  if (fromFeed) {
    author = new Channel({
      name: data.name,
      username: data.username,
      avatar: data.avatar,
      id: data.channel_id,
    });
  } else {
    author = userFromBackend(data.author);
  }

  let round;
  if (data.round) {
    round = roundFromBackend(data.round);
  }

  let file;
  if (data.file) {
    file = new File(data.file);
  }

  const gallery = [];
  if (data.gallery) {
    data.gallery.forEach((media) => {
      if (media.image) {
        gallery.push(new File(media.image));
      }
      if (media.video) {
        gallery.push(new VideoFile(media.video));
      }
    });
  }

  let replied;
  if (data.replied_data) {
    replied = messageFromBackend(data.replied_data);
  }

  let forwarded;
  if (data.fwd_data) {
    forwarded = messageFromBackend(data.fwd_data);
  }

  return new Message({
    author,
    chatId: data.chat_id,
    id: data.id,
    text: data.text,
    createdAt: new Date(data.created_at),
    viewCount: data.view_count,
    liked: data.liked,
    likeCount: data.like_count,
    round,
    model: data.model,
    geo: data.geo,
    file,
    gallery,
    read: data.read,
    replied,
    forwarded,
    edited: data.edited,
    pinned: data.pinned,
  });
}
