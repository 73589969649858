import VuePortal from '@linusborg/vue-simple-portal';
import GmapVue from 'gmap-vue';
import Maska from 'maska';
import mitt from 'mitt';
import numeral from 'numeral';
import Viewer from 'v-viewer';
import Vue from 'vue';
import AudioVisual from 'vue-audio-visual';
import VueClipboard from 'vue-clipboard2';
import VueHighlights from 'vue-highlights';
import VueNativeNotification from 'vue-native-notification';
import VueObserveVisibility from 'vue-observe-visibility';
import Vue2TouchEvents from 'vue2-touch-events';
import Vuelidate from 'vuelidate';
import 'viewerjs/dist/viewer.css';

import App from './App.vue';
import stagingDirective from './directives/staging';
import i18n from './localization';
import makeServer from './mirageServer';
import router from './router';
import variables from './scss/_variables.scss';
import './registerServiceWorker';
import store from './store';

const emitter = mitt();

Vue.config.devtools = process.env.NODE_ENV !== 'production';
Vue.config.performance = process.env.NODE_ENV !== 'production';
Vue.config.productionTip = false;

Vue.prototype.$emitter = emitter;
Vue.prototype.$scss = variables;
Vue.prototype.$formatNum = (number) => numeral(number).format('0,0 a');

Vue.use(Vue2TouchEvents);
Vue.use(VueObserveVisibility);
Vue.use(Viewer);
Vue.use(AudioVisual);
Vue.use(Maska);
Vue.use(VueClipboard);
Vue.use(VueNativeNotification);
Vue.use(VuePortal);
Vue.use(Vuelidate);
Vue.use(GmapVue, {
  load: {
    key: process.env.VUE_APP_GMAP_KEY,
  },
  installComponents: true,
});

Vue.directive('staging', stagingDirective);

Vue.component(VueHighlights.name, VueHighlights);

// if (process.env.NODE_ENV === 'development') {
//   makeServer();
// }

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
