export default {
  data() {
    return {
      monthsArray: Object.values(this.$t('date.months.short_decl')),
    };
  },
  methods: {
    formatTime(date) {
      const hours = date.getHours().toLocaleString('en-US', { minimumIntegerDigits: 2 });
      const minutes = date.getMinutes().toLocaleString('en-US', { minimumIntegerDigits: 2 });
      return `${hours}:${minutes}`;
    },

    formatDateNumNum(date) {
      const day = (`0${date.getDate()}`).slice(-2);
      const month = (`0${date.getMonth() + 1}`).slice(-2);
      return `${day}.${month}`;
    },

    formatDateShort(date) {
      const day = date.getDate();
      const month = this.monthsArray[date.getMonth()];
      return `${day} ${month}`;
    },

    formatDateTime(date) {
      const month = this.monthsArray[date.getMonth()];
      return `${date.getDate()} ${month} ${date.getFullYear()} ${this.formatTime(date)}`;
    },

    timeSince(createdAtSeconds) {
      const currentDate = new Date();
      const timestamp = Math.floor(currentDate.getTime() / 1000);
      const secondsElapsed = timestamp - createdAtSeconds;

      const minutesElapsed = secondsElapsed / 60;
      const hoursElapsed = minutesElapsed / 60;
      const daysElapsed = hoursElapsed / 24;

      if (daysElapsed >= 1) {
        return `${Math.floor(daysElapsed)} ${
          this.$t('date.interval.day').toLowerCase()[0]
        }.`;
      }
      if (hoursElapsed >= 1) {
        return `${Math.floor(hoursElapsed)} ${
          this.$t('date.interval.hour').toLowerCase()[0]
        }.`;
      }
      if (minutesElapsed >= 1) {
        return `${Math.floor(minutesElapsed)} ${
          this.$t('date.interval.minute').toLowerCase()[0]
        }.`;
      }
      return `${Math.floor(secondsElapsed)} ${
        this.$t('date.interval.second').toLowerCase()[0]
      }.`;
    },
  },
};
