<template>
  <form @submit.prevent>
    <input
      v-model="inviteCode"
      type="text"
      class="classic-input"
      :placeholder="$t('profile.promocode')"
    >
    <div
      v-if="invalidCode"
      class="centered small error"
    >
      {{ $t("login.promocode_not_found") }}
    </div>
    <button
      class="btn primary"
      :disabled="inviteCode.length === 0"
      @click="signup"
    >
      {{ $t('login.signup') }}
    </button>
  </form>
</template>

<script>
import { mapActions } from 'vuex';

import api from '@/api';

export default {
  emits: ['loggedIn'],
  props: {
    phoneToken: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      inviteCode: '',
      invalidCode: false,
    };
  },
  methods: {
    ...mapActions(['login']),
    async signup() {
      const response = await api.auth.signup({
        phoneToken: this.phoneToken,
        inviteCode: this.inviteCode,
      });

      if (response.code) {
        this.invalidCode = true;
      } else {
        this.login(response.access_token);
        this.$emit('loggedIn');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: flex;
  flex-direction: column;

  > * {
    margin: 12px 0;
    border-radius: 12px;
    border: none;
  }

  .small {
    font: $font-rn-200;
    color: $clr-text-trietary;
  }

  .error {
    color: $clr-error;
  }
}
</style>
