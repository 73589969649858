<template>
  <div class="get-app">
    <GetApp />
  </div>
</template>

<script>
import storeLinks from '@/assets/external/storeLinks.json';

import GetApp from '@/components/GetApp.vue';

export default {
  components: {
    GetApp,
  },
  props: {
    desktopRedirect: {
      type: [Object, String, null],
      default: null,
    },
  },
  beforeCreate() {
    if (navigator.userAgent.toLowerCase().indexOf('iphone') > -1) {
      window.location.href = storeLinks.appStore;
    }
    if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
      window.location.href = storeLinks.googlePlay;
    }
  },
  mounted() {
    if (this.desktopRedirect) {
      this.$router.push(this.desktopRedirect);
    }
  },
};
</script>

<style lang="scss" scoped>
.get-app {
  padding: 16px;
}
</style>
