import Cookies from 'js-cookie';

const DOMAIN = process.env.NODE_ENV === 'development' ? 'localhost' : process.env.VUE_APP_BASE_URL;

const tokenManager = {
  getToken: () => Cookies.get('x-access-token'),

  setToken: (token) => {
    if (token) {
      Cookies.set('x-access-token', token, {
        expires: 365,
        secure: false,
        domain: DOMAIN,
      });
    } else {
      Cookies.remove('x-access-token');
    }
  },
};

export default tokenManager;
