<template>
  <div class="categories">
    <div
      class="category"
      :class="{ active: selected === 'all' }"
      @click="$emit('select', 'all')"
    >
      {{ $t('search.all') }}
    </div>
    <div
      class="category"
      :class="{ active: selected === 'users' }"
      @click="$emit('select', 'users')"
    >
      {{ $t('search.users') }}
    </div>
    <div
      class="category"
      :class="{ active: selected === 'hashtags' }"
      @click="$emit('select', 'hashtags')"
    >
      {{ $t('search.hashtags') }}
    </div>
    <div
      class="category"
      :class="{ active: selected === 'channels' }"
      @click="$emit('select', 'channels')"
    >
      {{ $t('search.channels') }}
    </div>
  </div>
</template>

<script>
export default {
  emits: ['select'],
  props: {
    selected: {
      type: String,
      default: 'all',
    },
  },
};
</script>

<style lang="scss" scoped>
.categories {
  display: flex;
  justify-content: space-between;

  .category {
    padding: 12px 0;
    cursor: pointer;
    border-bottom: none;
    color: $clr-text-secondary;

    &.active {
      color: $clr-text-primary;
      border-bottom: 1px solid var(--clr-brand);
    }
  }
}
</style>
