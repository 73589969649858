import InviteCode from '../InviteCode';

import snFromBackend from './snFromBackend';

// handles https://arapi.gora.studio/doc/#api-Datatype-invite_code
export default function inviteCodeFromBackend(data) {
  return new InviteCode({
    id: data.idt_invite_code,
    value: data.value,
    createdAt: new Date(data.created_at),
    archivedAt: data.archived_at ? new Date(data.archived_at) : null,
    inviteeCount: data.invitees_count,
    sn: snFromBackend(data.social_network),
  });
}
