<template>
  <div class="apps">
    <a
      :style="{'background-color': color}"
      :href="storeLinks.appStore"
    >
      <img src="@/assets/external/appstore.svg">
    </a>
    <a
      :style="{'background-color': color}"
      :href="storeLinks.googlePlay"
    >
      <img src="@/assets/external/google.svg">
    </a>
  </div>
</template>

<script>
import storeLinks from '@/assets/external/storeLinks.json';

export default {
  props: {
    color: {
      type: String,
      default: 'white',
    },
  },
  created() {
    this.storeLinks = storeLinks;
  },
};
</script>

<style lang="scss" scoped>
.apps {
  width: 100%;

  > * {
    display: inline-flex;
    margin: 4px;
    padding: 2px 10px;
    justify-content: center;
    align-items: center;
    width: min-content;
    height: 44px;
    border-radius: 4px;

    img {
      height: 32px;
    }
  }
}
</style>
