export default {
  root: {
    route: null,
    locale: 'ru',
  },
  api: {
    msgSocket: null,
  },
  feed: {
    feed: [],
    channelsFeed: [],
    personalFeedSelected: false,
    channelsCategories: [],
    selectedChannelsCategory: null,
  },
  profile: {
    profile: null,
  },
  messenger: {
    chats: [],
    archivedChats: [],
    selectedChat: null,
    selectedMessageId: null,
    jinnyHistory: [],
  },
  sn: {
    selectedSn: null,
    defaultSn: null,
  },
};
