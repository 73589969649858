<template>
  <div
    class="btn back-arrow"
    :class="{ black }"
    @click="$router.go(-1)"
  >
    <ArrowIcon />
    <span>
      {{ $t('back') }}
    </span>
  </div>
</template>

<script>
import ArrowIcon from '@/components/icons/Arrow.vue';

export default {
  components: {
    ArrowIcon,
  },
  props: {
    black: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  background: white;
  border-radius: 4px;
  border: 1px solid $clr-border-light;
  padding: 8px 16px;
  display: flex;
  align-items: center;

  svg {
    color: $clr-text-primary;
  }

  span {
    margin-left: 8px;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .back-arrow {
    background: none;
    border: none;
    padding: 0;

    svg {
      color: white;
    }

    span {
      display: none;
    }

    &.black {
      svg {
        color: $clr-text-primary;
      }
    }
  }
}
</style>
