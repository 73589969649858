export default class Chat {
  id;

  name;

  username;

  avatar;

  type;

  pinned;

  admin;

  verified;

  muted;

  archived;

  firstUnreadId;

  unreadCount;

  createdAt;

  updatedAt;

  lastMessage;

  constructor(chat) {
    Object.assign(this, chat);
  }

  update(chat) {
    Object.assign(this, chat);
  }
}
