<template>
  <div class="whole-page">
    <Sidebar class="sidebar-wrap" />
    <div class="notifications with-sidebar">
      <Notification
        v-for="notif in notifications"
        :key="notif.idt_notify"
        ref="items"
        :notif="notif"
      />
      <div
        v-if="loading"
        class="status"
      >
        <SpinnerIcon />
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'debounce';
import { mapActions } from 'vuex';

import SpinnerIcon from '@/components/icons/Spinner.vue';

import Sidebar from '@/components/TheSidebar/Index.vue';

import Notification from './Notification.vue';

export default {
  name: 'Notifications',
  components: {
    Sidebar,
    Notification,
    SpinnerIcon,
  },
  data() {
    return {
      notifications: [],
      offset: 20,
      onScreen: 0,
      loading: true,
      allLoaded: false,
    };
  },
  async created() {
    this.notifications = await this.listNotifications({ limit: 20 });
    this.loading = false;
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions(['listNotifications']),
    setOnScreen() {
      if (this.$refs.items) {
        const BreakException = {};
        try {
          Object.keys(this.$refs.items).forEach((key) => {
            if (this.$refs.items[key].$el.getBoundingClientRect().top >= window.innerHeight / 2) {
              this.onScreen = Math.max(key, 0);
              throw BreakException;
            }
          });
        } catch (e) {
          if (e !== BreakException) throw e;
        }
      }
    },
    handleScroll: debounce(async function onScroll() {
      this.setOnScreen();

      if (!this.loading && !this.allLoaded && this.onScreen > this.notifications.length - 10) {
        this.loading = true;
        const limit = 20;
        const newNotifs = await this.listNotifications({ offset: this.offset, limit });

        this.offset += limit;
        this.notifications = this.notifications.concat(newNotifs);

        this.loading = false;
        if (newNotifs.length === 0) {
          this.allLoaded = true;
        }
      }
    }, 100),
  },
};
</script>

<style lang="scss" scoped>
.status {
  width: max-content;
  margin: auto;
  color: $clr-text-trietary;

  svg {
    margin-top: 16px;
    width: 32px;
    height: 32px;
  }
}
</style>
