<template>
  <div
    v-if="video"
    class="video-wrap"
  >
    <video
      ref="video"
      :poster="video.preview"
      :preload="video.preview ? 'none' : 'metadata'"
      @click.stop="videoClick"
      @ended="handleVideoEnd"
      @loadedmetadata="setDuration"
      @timeupdate="setRemaining"
    />
    <div
      v-if="remaining !== undefined"
      class="duration"
    >
      {{ remainingDisplay }}
    </div>
    <div
      class="go-fullscreen"
      @click="goFullscreen"
    >
      <ExpandIcon />
    </div>
    <div
      v-if="!playing"
      class="playbtn"
    />
  </div>
</template>

<script>
import ExpandIcon from '@/components/icons/Expand.vue';

export default {
  components: {
    ExpandIcon,
  },
  props: {
    video: {
      type: Object,
      default: undefined,
    },
    pillarboxed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      playing: false,
      duration: this.video?.duration,
      remaining: this.video?.duration,
    };
  },
  computed: {
    remainingDisplay() {
      if (this.remaining !== undefined) {
        if (this.remaining < 3600) {
          return new Date(this.remaining * 1000).toISOString().substr(14, 5);
        }
        return new Date(this.remaining * 1000).toISOString().substr(11, 8);
      }
      return undefined;
    },
  },
  mounted() {
    if (this.video) {
      const sourceElem = document.createElement('source');
      sourceElem.src = this.video.url;
      sourceElem.type = 'video/mp4';
      this.$refs.video.appendChild(sourceElem.cloneNode(true));
    }
  },
  methods: {
    videoClick(e) {
      if (e.target.paused) {
        this.playing = true;
        this.$refs.video.play();
      } else {
        this.playing = false;
        this.$refs.video.pause();
      }
    },
    goFullscreen() {
      this.$refs.video.requestFullscreen();
    },
    setDuration() {
      this.duration = this.$refs.video?.duration;
    },
    setRemaining(event) {
      this.remaining = this.duration - event.target.currentTime;
    },
    handleVideoEnd() {
      this.playing = false;
      this.remaining = this.duration;
    },
  },
};
</script>

<style lang="scss" scoped>
.video-wrap {
  position: relative;
  z-index: 10;

  .background {
    position: absolute;
    left: 0;
    width: 100%;
    max-height: 100%;
    object-fit: cover;
    opacity: .5;
    z-index: -1;
  }

  video, img {
    // backdrop-filter: blur(32px);
    position: relative;
    width: inherit;
    max-width: inherit;
    max-height: inherit;
    cursor: pointer;
    border-radius: inherit;
  }

  .duration, .go-fullscreen {
    position: absolute;
    right: 16px;
  }

  .duration {
    top: 16px;
    background: rgba(0, 0, 0, 0.3);
    color: white;
    font: $font-rn-200;
    padding: 2px 8px;
    border-radius: 16px;
  }

  .go-fullscreen {
    cursor: pointer;
    opacity: 0;
    bottom: 16px;
    transition: opacity .3s;

    svg {
      color: white;
    }
  }

  &:hover {
    .go-fullscreen {
      opacity: 1;
    }
  }

  .playbtn {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: no-repeat center url("~@/assets/icons/play-white.svg"),
      no-repeat center url("~@/assets/icons/transbg.svg");
  }
}
</style>
