<template>
  <div
    v-if="stream"
    class="stream"
  >
    <div class="container">
      <div
        class="player"
        :class="{ vertical: stream.orientation === 'portrait' }"
      >
        <StreamPlayer
          v-if="stream"
          :stream="stream"
        />
      </div>

      <div
        class="stream-info"
        :class="{ vertical: stream.orientation === 'portrait' }"
      >
        <div class="general-info">
          <div class="top">
            <img
              :src="stream.avatar"
              class="avatar supel"
            >
            <div class="title-and-name">
              <h1 class="title">
                {{ stream.name }}
              </h1>
              <router-link
                :to="{ name: 'Profile', params: { id: stream.idt_user } }"
                class="username"
              >
                {{ stream.username }}
              </router-link>
            </div>
            <div class="spacer" />
            <div class="interactions">
              <div
                class="likes"
                @click="like"
              >
                <div
                  v-if="stream.user_likes"
                  class="like liked"
                />
                <HeartIcon
                  v-else
                  class="like"
                />
                {{ stream.like_count }}
              </div>
              <ViewCounter :post="new Round({viewCount: stream.view_count})" />
            </div>
          </div>

          <div class="description">
            {{ stream.description }}
          </div>
        </div>

        <div class="chat">
          <div class="comments">
            <Comment
              v-for="c in comments"
              :key="c.time"
              :comment="c"
            />
          </div>

          <DumbInput
            v-model="comment"
            @send="sendComment"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import HeartIcon from '@/components/icons/Heart.vue';

import DumbInput from '@/components/DumbInput.vue';
import ViewCounter from '@/components/ViewCounter.vue';

import Comment from './Comment.vue';
import handleStreamEventsMixin from './handleStreamEventsMixin';
import StreamPlayer from './StreamPlayer.vue';

export default {
  name: 'Stream',
  components: {
    StreamPlayer,
    Comment,
    DumbInput,
    HeartIcon,
    ViewCounter,
  },
  mixins: [handleStreamEventsMixin],
  data() {
    return {
      stream: undefined,
      comments: [],
      comment: '',
    };
  },
  computed: {
    streamId() {
      return this.$route.params.id;
    },
  },
  async created() {
    this.stream = await this.getStream(this.streamId);
    this.initStreamSocket(this.streamId);
  },
  methods: {
    ...mapActions(['getStream', 'likeStream', 'addStreamComment']),
    async like() {
      this.stream.user_likes = !this.stream.user_likes;
      this.stream.user_likes = await this.likeStream(
        this.stream.idt_stream,
      );
    },
    sendComment() {
      if (this.comment !== '') {
        this.addStreamComment({
          id: this.streamId,
          text: this.comment,
        });
        this.comment = '';
      }
    },
    // These methods are used in the handleStreamEventsMixin
    firstEvent(event) {
      this.stream.comment_count = event.comment_count;
      this.stream.like_count = event.like_count;
      this.stream.viewer_count = event.viewer_count;
      this.stream.share_count = event.share_count;
      this.stream.user_likes = event.user_likes;
      this.stream.user_shared = event.user_shared;
    },
    newLike(event) {
      this.stream.like_count = event.count;
    },
    newComment(event) {
      this.stream.comment_count = event.count;
      this.comments.unshift({
        time: event.time,
        text: event.text,
        author: {
          idt_user: event.idt_user,
          username: event.username,
          name: event.name,
          avatar: event.avatar,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.stream {
  padding-top: 96px;

  .container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    background: white;
    border-radius: 16px;
    margin: auto;
    padding: 32px;
    box-sizing: border-box;
    height: calc(100vh - 112px);
    max-width: $mobile-breakpoint;

    .player {
      width: 100%;
      border-radius: 16px;
      margin-bottom: 16px;

      &.vertical {
        width: 40%;
        margin-right: 32px;
      }
    }

    .stream-info {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: space-between;
      height: 40%;

      &.vertical {
        max-width: 100%;
        min-height: 100%;
        width: calc(60% - 32px);
        flex-direction: column;

        .general-info {
          height: 128px;
        }

        .chat {
          width: 100%;
          flex-grow: 1;
          min-height: 0;
          padding-top: 16px;
        }
      }

      .top {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 16px;

        .avatar {
          width: 64px;
          height: 64px;
          margin-right: 16px;
        }

        .title-and-name {
          display: flex;
          justify-content: space-evenly;
          flex-direction: column;
          margin-right: 16px;
        }

        .spacer {
          flex-grow: 1;
        }

        .interactions {
          display: flex;
          justify-content: center;
          align-items: center;
          width: max-content;

          > * {
            display: inline-flex;
            margin-right: 32px;
          }

          .likes {
            align-items: center;

            svg,
            .like {
              width: 22px;
              height: 22px;
              margin-right: 16px;
            }

            .liked {
              background: $grad-red;
              mask: url("~@/assets/icons/heart-filled.svg") no-repeat center;
            }
          }
        }
      }

      .description {
        overflow-wrap: break-word;
        font: $font-rw-400;
      }

      .chat {
        position: relative;
        width: 50%;
        box-sizing: border-box;

        .comments {
          position: relative;
          display: flex;
          flex-direction: column-reverse;
          bottom: 0;
          overflow-y: scroll;
          height: calc(100% - 64px);
        }
      }
    }
  }
}
</style>
