<template>
  <div
    class="counter"
    @click="like"
  >
    <div
      v-if="proxyPost.liked"
      class="liked"
    />
    <HeartIcon v-else />
    &nbsp;
    {{ $formatNum(proxyPost.likeCount) }}
  </div>
</template>

<script>
import HeartIcon from '@/components/icons/Heart.vue';

import Round from '@/models/Round';

export default {
  emits: ['like', 'unlike'],
  components: {
    HeartIcon,
  },
  props: {
    post: {
      type: Round,
      required: true,
    },
  },
  data() {
    return {
      proxyPost: new Round(this.post),
    };
  },
  methods: {
    like() {
      if (this.proxyPost.liked) {
        this.proxyPost.unlike();
        this.$emit('unlike');
      } else {
        this.proxyPost.like();
        this.$emit('like');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  color: inherit;
}

.counter {
  display: flex;
  align-items: center;
  justify-content: center;

  svg, .liked {
    width: 22px;
    height: 22px;
  }

  .liked {
    background: $grad-red;
    mask: url('~@/assets/icons/heart-filled.svg') no-repeat center;
  }
}
</style>
