<template>
  <Modal
    ref="modal"
  >
    <div
      v-if="!reported"
      class="content"
    >
      {{ $t('report.question') }}

      <div class="buttons">
        <button
          class="btn primary"
          @click="() => {
            report({
              target,
              target_id: id,
            });
            reported = true;
          }"
        >
          {{ $t('confirm') }}
        </button>
        <button
          class="btn secondary"
          @click="close"
        >
          {{ $t('cancel') }}
        </button>
      </div>
    </div>

    <div
      v-if="reported"
      class="content"
    >
      {{ $t('report.thanks', { snName: selectedSn.name, snNameRu: selectedSn.nameRu }) }}
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import Modal from '@/components/Modal.vue';

export default {
  components: {
    Modal,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    target: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      reported: false,
    };
  },
  computed: {
    ...mapState({
      selectedSn: (state) => state.api.sn.selectedSn,
    }),
  },
  methods: {
    ...mapActions(['report']),
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin-top: 32px;
  text-align: center;
  font: $font-rw-400;

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .content {
    padding-top: 48px;
  }
}
</style>
