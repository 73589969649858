import Round from './Round';

export default class VideoRound extends Round {
  video;

  duration;

  size;

  constructor(round) {
    super(round);
    Object.assign(this, round);
  }
}
