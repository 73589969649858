import base from './base';
import onlyData from './onlyData';

export default {
  fetchPopularPosts: ({
    snId, longVideo, offset, limit,
  }) => base.v2().get('/round/explore', {
    params: {
      sn_id: snId,
      long_video: longVideo,
      offset,
      limit,
    },
  }).then(onlyData),

  fetchPersonalPosts: ({ longVideo, offset, limit }) => base.v2().get('/round/feed', {
    params: {
      long_video: longVideo,
      offset,
      limit,
    },
  }).then(onlyData),

  likeRound: ({ id }) => base.v1().post('/user/like', {
    idt_round: id,
  }).then(onlyData),
};
