export default {
  en: {
    lang: 'English',
    and: 'and',
    copy: 'Copy',
    copied: 'Copied',
    refresh: 'Refresh',
    continue: 'Continue',
    back: 'Back',
    posts: 'Posts',
    videos: 'Videos',
    post: 'Post',
    video: 'Video',
    channels: 'Channels',
    streams: 'Streams',
    my_profile: 'My profile',
    collections: 'Collections',
    questions_support: 'Questions and Support',
    exit: 'Exit',
    soon: 'Soon!',
    subscribe: 'Subscribe',
    subscribed: 'Subscribed',
    subscriptions: 'Subscriptions',
    subscribers: 'Subscribers',
    recommended: 'Recommended',
    block: 'Block',
    unblock: 'Unblock',
    qr_code: 'QR-code',
    about_project: 'About',
    all_watched: "That's all for now!",
    comments: 'Comments',
    download_app: 'Get the {snName} app and start making AR content!',
    cancel: 'Cancel',
    close: 'Close',
    confirm: 'Confirm',
    select: 'Select',
    to_home: 'Home',
    save: 'Save',
    name: 'Name',
    description: 'Description',
    archive: 'Archive',
    promocode: 'Promocode',
    login: {
      title: 'Login into {snName}',
      login: 'Login',
      signup: 'Sign up',
      enter_last_five: 'Enter the last five digits',
      enter_code: 'Enter code from SMS',
      were_calling: "We're calling the following number: ",
      were_sending: "We'll send a message to this number ",
      dont_answer: ", don't answer",
      rate_limit: 'Please wait a minute before the next attempt ',
      invalid_phone: 'Invalid phone number',
      invalid_code: 'Invalid code',
      new_code: 'Get new code',
      try_sms: "Can't get code?",
      promocode_not_found: 'Promocode not found',
    },
    profile: {
      edit: 'Edit profile',
      buy_vip: 'Buy VIP status',
      publications: 'publications',
      followers: 'followers',
      following: 'following',
      actions: 'Profile actions',
      blocked: 'Profile blocked',
      blocked_you: 'This user blocked you',
      my_promocode: 'My promocode',
      change_avatar: 'Change avatar',
      new_avatar: 'New avatar',
      name: 'Name',
      first_name: 'First name',
      middle_name: 'Middle name',
      last_name: 'Last name',
      email: 'Email address',
      phone: 'Phone',
      show_phone: 'Show phone',
      username: 'Username',
      site: 'Site',
      status: 'Status',
      sex: 'Sex',
      sex_not_set: 'Not set',
      female: 'Female',
      male: 'Male',
      birthday_date: 'Birthday date',
      location: 'Location',
      website: 'Website',
      success_msg: 'Profile updated',
      error_msg: 'Profile update error, check entered data',
      all_time: 'All time',
      active_promocode: 'Active promocode',
      create_promocode: 'Create promocode',
      promocode_hint: '6 to 10 symbols in length, can contain numbers, underscores, dashes and dots',
    },
    settings: {
      settings: 'Settings',
      blocked_users: 'Blocked users',
      unblock: 'Unblock',
      no_blocked: 'No blocked users',
    },
    search: {
      search: 'Search',
      top_users: 'Top users',
      all: 'All',
      users: 'Users',
      hashtags: 'Hashtags',
      channels: 'Channels',
      nothing_found: 'Nothing found',
    },
    report: {
      report: 'Report',
      question: 'Are you sure want to report this?',
      thanks: 'Thank you for reporting this account. Your actions help us make {snName} better.',
    },
    notif: {
      published_new_post: 'published a new post',
      subscribed: 'is following you',
      liked_your_post: 'liked your post',
    },
    messenger: {
      dms: 'DMs',
      groups: 'Groups',
      channels: 'Channels',
      channel: 'Channel',
      all: 'All',
      jinny: 'Jinny',
      your_personal_assistant: 'Your personal assistant',
      your_message: 'Your message',
      unread: 'Unread',
      no_messages: 'No messages yet',
      not_selected: 'Select a chat to start messaging',
      audio: 'Voice message',
      file: 'File',
      model: 'Model',
      call: 'Call',
      call_out_missed: 'Missed outgoing call',
      call_in_missed: 'Missed incoming call',
      call_out: 'Outgoing call',
      call_in: 'Incoming call',
      geo: 'Geolocation',
      gallery: 'Gallery',
      image: 'Image',
      video: 'Video',
      forward: 'Forward',
      forwarded_from: 'Forwarded from',
      replying_to: 'Replying to',
      reply: 'Reply',
      edit: 'Edit',
      editing: 'Editing',
      delete: 'Delete',
      delete_for_everyone: 'Delete for everyone',
      threed_model: '3D model',
      archive: 'Chat archive',
      to_archive: 'Archive',
      unarchive: 'Unarchive',
      no_sound: 'Without sound',
      with_sound: 'With sound',
      search_result: 'Search result',
      msg_from: 'Message from',
      join_channel: 'Join channel',
      if_you_like_channel: 'If you like the channel',
      join: 'Join',
      leave: 'Leave',
      pin: 'Pin',
      unpin: 'Unpin',
      my_channel: 'My channel',
      archived_chats: 'Archived chats',
    },
    wallet: {
      invited: 'Invited',
      main_wallet: 'Main wallet',
      people: 'people',
      send: 'Send',
      recieve: 'Recieve',
      buy: 'Buy',
      operation_history: 'History of operations',
      cash_out: 'Cash out',
      collections: 'Collections',
      month_income: 'This months income',
      income_history: 'Income history',
      how_to_earn_more: 'How to earn more with {snName}',
      top_ten: 'This months top 10',
    },
    stream: {
      watch: 'Watch stream',
      create: 'Create stream',
      donation_url: 'Donation URL',
      orientation: 'Orientation',
      landscape: 'Landscape',
      portrait: 'Portrait',
      record: 'Record',
      get_info: 'Get info',
      obs_info: 'OBS info',
      server: 'Server',
      stream_key: 'Stream key',
      open_stream: 'Open stream',
    },
    sn: {
      sn: 'Social network',
      net: 'Network',
      register: 'Click here to register your own social network',
      you_are_eligible: 'You are eligible to register your own social network!',
      join_new_sn: 'Join new social network',
      you_will_need_a_promocode: 'You must have a promocode',
      already_member: "You're already a member of {sn} and can swith to it any time from profile",
      promocode_not_found: 'Promocode not found',
      founder_name: 'Founders name',
      founder_email: 'Founders email',
      founder_phone: 'Founders phone',
      username: 'Social network username',
      new_name: 'Social network name in english',
      new_name_ru: 'Social network name in russian',
      description: 'Social network description',
      icon: 'Social network icon',
      create: 'Create social network',
      founder_email_verified_error: 'Founder email must be verified',
      founder_email_verified_error_link: 'Verify email',
      first_step: 'First step: email verification',
      second_step: 'Second step: provide social network details',
      created_successfully: 'Social network created successfully!',
      you_will_be_redirected: 'You will be redirected to {0} in a few seconds',
      send_verification_email: 'Send verification email',
      email_sent: 'Email sent',
      email_rate_limit: 'Email rate limit exceeded, try again in {seconds} seconds',
      email_code: 'Email code',
      send_code: 'Send code',
      code_rate_limit: 'Code rate limit exceeded, try again later',
      code_invalid: 'Code invalid',
    },
    legal: {
      agree: 'By continuing you agree with the',
      tos_agree: 'Terms of Service',
      policy_agree: 'Privacy Policy',
      tos: 'Terms of Service',
      policy: 'Privacy Policy',
    },
    email_verify: {
      login: 'Login, to verify your email',
      error: 'We encountered an error verifying your email, please try again.',
      success: 'Email verified. You will be redirected in a couple of seconds.',
    },
    not_found: {
      not_found: 'Not Found',
    },
    date: {
      interval: {
        year: 'Year',
        quarter: 'Quarter',
        month: 'Month',
        week: 'Week',
        day: 'Day',
        hour: 'Hour',
        minute: 'Minute',
        second: 'Second',
      },
      months: {
        short: {
          jan: 'Jan',
          feb: 'Feb',
          mar: 'Mar',
          apr: 'Apr',
          may: 'May',
          jun: 'Jun',
          jul: 'Jul',
          aug: 'Aug',
          sep: 'Sep',
          oct: 'Oct',
          nov: 'Nov',
          dec: 'Dec',
        },
        short_decl: {
          jan: 'Jan',
          feb: 'Feb',
          mar: 'Mar',
          apr: 'Apr',
          may: 'May',
          jun: 'Jun',
          jul: 'Jul',
          aug: 'Aug',
          sep: 'Sep',
          oct: 'Oct',
          nov: 'Nov',
          dec: 'Dec',
        },
        long: {
          january: 'January',
          february: 'February',
          march: 'March',
          april: 'April',
          may: 'May',
          june: 'June',
          july: 'July',
          august: 'August',
          september: 'September',
          october: 'October',
          november: 'November',
          december: 'December',
        },
      },
    },
  },
  ru: {
    lang: 'Русский',
    and: 'и',
    copy: 'Скопировать',
    copied: 'Скопированно',
    refresh: 'Обновить',
    continue: 'Продолжить',
    back: 'Назад',
    posts: 'Посты',
    videos: 'Видео',
    post: 'Пост',
    video: 'Видео',
    channels: 'Каналы',
    streams: 'Стримы',
    my_profile: 'Мой профиль',
    collections: 'Коллекции',
    questions_support: 'Вопросы и поддержка',
    exit: 'Выход',
    soon: 'Скоро!',
    subscribe: 'Подписаться',
    subscribed: 'Подписан',
    subscriptions: 'Подписки',
    subscribers: 'Подписчики',
    recommended: 'Рекомендуем',
    block: 'Заблокировать',
    unblock: 'Разблокировать',
    qr_code: 'QR-код',
    about_project: 'О проекте',
    all_watched: 'На этом пока всё!',
    comments: 'Комментарии',
    download_app: 'Скачайте приложение {snNameRu} и создавайте AR контент!',
    cancel: 'Отмена',
    close: 'Закрыть',
    confirm: 'Подтвердить',
    select: 'Выбрать',
    to_home: 'На главную',
    save: 'Сохранить',
    name: 'Название',
    description: 'Описание',
    archive: 'Архив',
    promocode: 'Промокод',
    login: {
      title: 'Вход в {snNameRu}',
      login: 'Войти',
      signup: 'Зарегистрироваться',
      enter_last_five: 'Введите последние пять цифр телефонного номера',
      enter_code: 'Введите код из СМС',
      were_calling: 'Сейчас мы позвоним на номер ',
      dont_answer: ', отвечать не нужно',
      were_sending: 'Сейчас мы отправим СМС на номер ',
      rate_limit: 'Повторный код можно получить через ',
      invalid_phone: 'Неверный номер',
      invalid_code: 'Неверный код',
      new_code: 'Получить новый код',
      try_sms: 'Не получается ввести код?',
      promocode_not_found: 'Промокод не найден',
    },
    profile: {
      edit: 'Редактировать профиль',
      buy_vip: 'Купить VIP-статус',
      publications: 'публикации',
      followers: 'подписчики',
      following: 'подписки',
      actions: 'Действия с профилем',
      blocked: 'Пользователь заблокирован',
      blocked_you: 'Вы заблокированы',
      my_promocode: 'Мой промокод',
      change_avatar: 'Изменить аватар',
      new_avatar: 'Новый аватар',
      name: 'Имя',
      first_name: 'Имя',
      middle_name: 'Отчество',
      last_name: 'Фамилия',
      email: 'Адрес электронной почты',
      phone: 'Телефон',
      show_phone: 'Показывать телефон',
      username: 'Username',
      site: 'Сайт',
      status: 'Статус',
      sex: 'Пол',
      sex_not_set: 'Не указан',
      female: 'Женский',
      male: 'Мужской',
      birthday_date: 'День рождения',
      location: 'Местоположение',
      website: 'Вебсайт',
      success_msg: 'Изменения сохранены, профиль обновлен',
      error_msg: 'Ошибка обновления профиля, проверьте корректность введенных данных',
      all_time: 'За все время',
      active_promocode: 'Действующий промокод',
      create_promocode: 'Создать промокод',
      promocode_hint: 'Длина от 6 до 10 символов, может содержать цифры, буквы а-я, символы нижнего подчеркивания, дефисы и точки',
    },
    settings: {
      settings: 'Настройки',
      blocked_users: 'Заблокированные пользователи',
      unblock: 'Разблокировать',
      no_blocked: 'Нет заблокированных пользователей',
    },
    search: {
      search: 'Поиск',
      top_users: 'Топ пользователей',
      all: 'Все',
      users: 'Пользователи',
      hashtags: 'Хештеги',
      channels: 'Каналы',
      nothing_found: 'Ничего не найдено',
    },
    report: {
      report: 'Пожаловаться',
      question: 'Вы уверенны, что хотите сообщить о нарушении?',
      thanks: 'Спасибо, что сообщили о нарушениях этого аккаунта. Ваши отзывы важны для того, чтобы помочь нам сохранить {snNameRu} в безопасности.',
    },
    notif: {
      published_new_post: 'опубликовал(-а) новый пост',
      subscribed: 'подписался на вас',
      liked_your_post: 'нравится ваш пост',
    },
    messenger: {
      dms: 'Чаты',
      groups: 'Группы',
      channels: 'Каналы',
      channel: 'Канал',
      all: 'Все',
      jinny: 'Джинни',
      your_personal_assistant: 'Персональный помощник',
      your_message: 'Ваше сообщение',
      unread: 'Непрочитанные',
      no_messages: 'Пока сообщений нет',
      not_selected: 'Выберите чат, чтобы начать общение',
      audio: 'Голосовое сообщение',
      file: 'Файл',
      model: 'Модель',
      call: 'Звонок',
      call_out_missed: 'Пропущенный исходящий звонок',
      call_in_missed: 'Пропущенный входящий звонок',
      call_out: 'Исходящий звонок',
      call_in: 'Входящий звонок',
      geo: 'Геопозиция',
      gallery: 'Галерея',
      image: 'Изображение',
      video: 'Видео',
      forward: 'Переслать',
      forwarded_from: 'Переслано от',
      replying_to: 'Ответ',
      reply: 'Ответить',
      edit: 'Редактировать',
      editing: 'Редактирование',
      delete: 'Удалить',
      delete_for_everyone: 'Удалить для всех',
      threed_model: '3D модель',
      archive: 'Архив чатов',
      to_archive: 'В архив',
      unarchive: 'Вернуть',
      no_sound: 'Без звука',
      with_sound: 'Со звуком',
      search_result: 'Результат поиска',
      msg_from: 'Сообщение от',
      join_channel: 'Присоединитесь к каналу',
      if_you_like_channel: 'Если вам нравится канал',
      join: 'Присоединиться',
      leave: 'Покинуть',
      pin: 'Закрепить',
      unpin: 'Открепить',
      my_channel: 'Мой канал',
      archived_chats: 'Архивные чаты',
    },
    wallet: {
      invited: 'Приглашенные',
      main_wallet: 'Основной кошелек',
      people: 'человек',
      send: 'Отправить',
      recieve: 'Получить',
      buy: 'Купить',
      operation_history: 'История операций',
      cash_out: 'Вывод средств',
      collections: 'Коллекции',
      month_income: 'Заработок за месяц',
      income_history: 'История заработка',
      how_to_earn_more: 'Как зарабатывать больше с {snNameRu}',
      top_ten: 'Топ-10 этого месяца',
    },
    stream: {
      watch: 'Смотреть стрим',
      create: 'Создать стрим',
      donation_url: 'Donation URL',
      orientation: 'Ориентация',
      landscape: 'Ландшафт',
      portrait: 'Портрет',
      record: 'Записывать',
      get_info: 'Получить данные',
      obs_info: 'Данные для OBS',
      server: 'Сервер',
      stream_key: 'Ключ стрима',
      open_stream: 'Открыть стрим',
    },
    sn: {
      sn: 'Соцсеть',
      net: 'Сеть',
      register: 'Нажми сюда, чтобы зарегистрировать свою собственную соцсеть',
      you_are_eligible: 'Вы допущены к созданию своей соцсети!',
      join_new_sn: 'Войти в новую соцсеть',
      you_will_need_a_promocode: 'У вас должен быть промокод',
      already_member: 'Вы уже вошли в соцсеть {sn} и можете переключиться на нее в любой момент из профиля',
      promocode_not_found: 'Промокод не найден',
      founder_name: 'ФИО основателя',
      founder_email: 'Эл. почта основателя',
      founder_phone: 'Телефон основателя',
      username: 'Username соцсети',
      username_error: 'Username должен быть не короче 3 символов, не длиннее 20 символов и содержать только латинские буквы, цифры, знак подчеркивания и минус',
      new_name: 'Название соцсети на английском',
      new_name_ru: 'Название соцсети на русском',
      description: 'Описание соцсети',
      create: 'Создать соцсеть',
      founder_email_verified_error: 'Эл. почта основателя не подтверждена',
      founder_email_verified_error_link: 'Подтвердить эл. почту',
      first_step: 'Первый шаг: подтверждение эл. почты',
      second_step: 'Второй шаг: заполнение данных о соцсети',
      created_successfully: 'Соцсеть успешно создана!',
      you_will_be_redirected: 'Вы будете перенаправлены на {0} через пару секунд',
      send_verification_email: 'Отправить письмо для подтверждения эл. почты',
      email_sent: 'Письмо отправлено',
      email_rate_limit: 'Слишком часто. Попробуйте позже, через {seconds} секунд.',
      email_code: 'Код подтверждения',
      send_code: 'Отправить код',
      code_rate_limit: 'Слишком часто. Попробуйте позже.',
      code_invalid: 'Неверный код',
    },
    legal: {
      agree: 'Продолжая, вы подтверждаете согласие с',
      tos_agree: 'Условиями использования сервиса',
      policy_agree: 'Политикой конфиденциальности',
      tos: 'Условия использования',
      policy: 'Политика конфиденциальности',
    },
    email_verify: {
      login: 'Войдите, чтобы подтвердить email',
      error: 'Мы столкнулись с ошибкой при подтверждении email, попробуйте еще раз.',
      success: 'Email подтвержден. Вы будете перенаправлены через несколько секунд.',
    },
    not_found: {
      not_found: 'Не найдено',
    },
    date: {
      interval: {
        year: 'Год',
        quarter: 'Квартал',
        month: 'Месяц',
        week: 'Неделя',
        day: 'День',
        hour: 'Час',
        minute: 'Минута',
        second: 'Секунда',
      },
      months: {
        short: {
          jan: 'янв.',
          feb: 'февр.',
          mar: 'март',
          apr: 'апр.',
          may: 'май',
          jun: 'июнь',
          jul: 'июль',
          aug: 'авг.',
          sep: 'сент.',
          oct: 'окт.',
          nov: 'нояб.',
          dec: 'дек.',
        },
        short_decl: {
          jan: 'янв.',
          feb: 'февр.',
          mar: 'марта',
          apr: 'апр.',
          may: 'мая',
          jun: 'июня',
          jul: 'июля',
          aug: 'авг.',
          sep: 'сент.',
          oct: 'окт.',
          nov: 'нояб.',
          dec: 'дек.',
        },
        long: {
          january: 'Январь',
          february: 'Февраль',
          march: 'Март',
          april: 'Апрель',
          may: 'Май',
          june: 'Июнь',
          july: 'Июль',
          august: 'Август',
          september: 'Сентябрь',
          october: 'Октябрь',
          november: 'Ноябрь',
          december: 'Декабрь',
        },
      },
    },
  },
};
