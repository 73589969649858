import api from '@/api';

import initialState from '../initialState';

import snFromBackend from '@/models/dto/snFromBackend';

export default () => ({
  state: initialState.sn,
  mutations: {
    setSelectedSn(state, sn) {
      state.selectedSn = sn;
    },

    setDefaultSn(state, sn) {
      state.defaultSn = sn;
    },
  },
  actions: {
    async setDefaultSn({ commit }, id) {
      const sn = snFromBackend(await api.sn.setDefaultSn({ id }));
      commit('setDefaultSn', sn);
      return sn;
    },
  },
});
