import api from '@/api';

import initialState from '../initialState';

export default (Api) => ({
  state: initialState.feed,
  mutations: {
    appendFeed(state, feed) {
      state.feed = [...state.feed, ...feed];
    },
    appendChannelsFeed(state, feed) {
      state.channelsFeed = [...state.channelsFeed, ...feed];
    },
    replaceRound(state, round) {
      state.feed = state.feed.map((element) => {
        if (element.idt_round === round.idt_round) { return round; }
        return element;
      });
    },
    dropFeed(state) {
      state.feed = [];
    },
    dropChannelsFeed(state) {
      state.channelsFeed = [];
    },
    setChannelsCategories(state, categories) {
      state.channelsCategories = categories;
    },
    switchFeedType(state, personal) {
      if (personal === undefined) {
        state.personalFeedSelected = !state.personalFeedSelected;
      } else {
        state.personalFeedSelected = personal;
      }
    },
    selectChannelsCategory(state, id) {
      state.selectedChannelsCategory = id;
    },
  },
  actions: {
    // Fetch depending on selected feed type
    async fetchRoundFeed({ commit, state, rootState }, offset) {
      let posts;

      if (state.personalFeedSelected) {
        posts = await api.feed.fetchPersonalPosts({
          offset,
          limit: 10,
        });
      } else {
        posts = await api.feed.fetchPopularPosts({
          offset,
          limit: 10,
          snId: rootState.api.sn.selectedSn.id,
        });
      }

      commit('appendFeed', posts);
      return posts;
    },

    async fetchLongVidFeed({ commit, rootState }, offset) {
      const posts = await api.feed.fetchPopularPosts({
        offset,
        longVideo: true,
        snId: rootState.api.sn.selectedSn.id,
      });

      commit('appendFeed', posts);
      return posts;
    },

    fetchStreamsFeed({ state }) {
      if (state.personalFeedSelected) {
        return Api.v2.get('/streams/feed').then((response) => response.data);
      }
      return Api.v2.get('/streams/explore').then((response) => response.data);
    },

    fetchRoundComments({ _ }, { round_id, offset }) {
      return Api.v2
        .get('/round/comment/list', {
          params: {
            offset,
            round_id,
            limit: 1024,
          },
        })
        .then((response) => response.data.comments);
    },

    addComment({ _ }, { round_id, text, reply_to }) {
      return Api.v2
        .post('/round/comment', {
          round_id,
          text,
          reply_to,
        })
        .then((response) => response.data);
    },

    getRound({ _ }, idt_round) {
      return Api.v2
        .get('/user/round', {
          params: {
            idt_round,
          },
        })
        .then((response) => response.data.round);
    },

    likeRound({ commit }, idt_round) {
      return Api.v1.post('/user/like', {
        idt_round,
      }).then((response) => {
        commit('replaceRound', response.data.round);
      });
    },

    viewRound({ _ }, idt_round) {
      return Api.v2.post('/round/viewers', {
        idt_round,
      });
    },

    setDebugMode({ _ }, enabled) {
      return Api.v2.put('/round/explore/debug', {
        enabled,
      });
    },

    getDebugMode({ _ }) {
      return Api.v2.get('/round/explore/debug')
        .then((response) => response.data.enabled);
    },
  },
  getters: {
    videoFeed: (state) => state.feed.filter((round) => round.media),
  },
});
