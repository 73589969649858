<template>
  <div
    v-if="round"
    class="round"
  >
    <component
      :is="mediaComponent"
      :round="round"
      :muted="muted"
      class="media"
    />
    <RoundBottomOverlay
      :round="round"
      :muted="muted"
      cached
      @mute="muted = !muted"
      @openComments="$emit('openComments')"
    />
  </div>
</template>

<script>
import PhotoMedia from './PhotoMedia.vue';
import RoundBottomOverlay from './RoundBottomOverlay.vue';
import VideoMedia from './VideoMedia.vue';

import roundFromBackend from '@/models/dto/roundFromBackend';
import Round from '@/models/Round';
import VideoRound from '@/models/VideoRound';

export default {
  name: 'RoundComponent',
  components: {
    RoundBottomOverlay,
  },
  props: {
    roundRaw: {
      type: Object,
      default: undefined,
    },
    roundInstance: {
      type: Round,
      default: undefined,
    },
  },
  data() {
    const round = this.roundInstance ?? roundFromBackend(this.roundRaw);
    let mediaComponent = null;
    if (round instanceof VideoRound) {
      mediaComponent = VideoMedia;
    } else {
      mediaComponent = PhotoMedia;
    }

    return {
      muted: false,
      round,
      mediaComponent,
    };
  },
};
</script>

<style lang="scss" scoped>
.round {
  position: relative;

  .media {
    height: 100%;
    width: 100%;
  }
}
</style>
