<template>
  <div class="home">
    <div
      v-if="mobile"
      class="mobile-feed"
    >
      <MobileFeed />
    </div>
    <div
      v-else-if="mobile===false"
      class="desktop-feed"
    >
      <transition-group
        tag="div"
        class="sidebar-wrap"
        name="slide"
      >
        <div
          v-if="feedType!=='videos'"
          key="toggle"
          class="feed-slider"
          @click="switchFeed"
        >
          <div
            class="selection"
            :class="{ on: personalFeedSelected }"
          />
          <div
            class="text"
            :class="{ active: !personalFeedSelected }"
          >
            {{ $t("recommended") }}
          </div>
          <div
            class="text"
            :class="{ active: personalFeedSelected }"
          >
            {{ $t("subscriptions") }}
          </div>
        </div>
        <CategorySelector
          v-if="feedType==='channels'"
          key="categories"
          class="categories"
        />
        <Sidebar key="sidebar" />
      </transition-group>
      <DesktopFeed class="feed" />
    </div>
  </div>
</template>

<script>
import {
  mapState, mapMutations, mapGetters, mapActions,
} from 'vuex';

import Sidebar from '@/components/TheSidebar/Index.vue';

import DesktopFeed from './Desktop/Index.vue';
import MobileFeed from './Mobile/Index.vue';
import CategorySelector from './ScrollFeed/CategorySelector.vue';

export default {
  name: 'Home',
  components: {
    MobileFeed,
    DesktopFeed,
    CategorySelector,
    Sidebar,
  },
  props: {
    type: {
      type: String,
      default: 'rounds',
    },
  },
  data() {
    return {
      mobile: undefined,
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    ...mapState({
      personalFeedSelected: (state) => state.api.feed.personalFeedSelected,
    }),
    feedType() {
      return this.$route.params.type;
    },
  },
  mounted() {
    this.mobile = window.innerWidth <= this.$scss.mobileBreakpoint;
  },
  methods: {
    ...mapMutations(['switchFeedType']),
    switchFeed() {
      if (this.isAuthenticated) {
        this.switchFeedType();
      } else this.$emitter.emit('unauthedAction');
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-feed {
  display: none;
}

.desktop-feed {
  .feed-slider {
    position: relative;
    margin-bottom: 40px;

    > * {
      padding: 5px 12px;
      margin: 4px;
      font: $font-sbw-300;
      cursor: pointer;
    }
  }

  .feed-slider {
    border-radius: 16px;
  }

  .feed-slider {
    width: max-content;
    background-color: $clr-20;

    .text {
      position: relative;
      color: $clr-text-secondary;
      display: inline-block;
      z-index: 1;
      width: 125px;
      box-sizing: border-box;
      text-align: center;
    }

    .selection {
      box-sizing: border-box;
      position: absolute;
      background-color: white;
      border-radius: 12px;
      color: transparent;
      left: 0;
      top: 0;
      bottom: 0;
      width: 125px;
      transition: transform .4s;
    }

    .active {
      color: $clr-text-primary;
    }

    .on {
      transform: translateX(calc(125px + 8px));
      -webkit-transform: translateZ(0.1);
      -moz-transform: translateZ(0.1);
      -ms-transform: translateZ(0.1);
      -o-transform: translateZ(0.1);
      transform: translateZ(0.1);
    }
  }

  .feed {
    max-width: 732px;
    margin-left: 40%;
  }

  .sidebar-wrap  {
    > * {
      transition: transform 1s, opacity 1s;
    }

    .slide-enter, .slide-leave-to {
      opacity: 0;
      transform: translateX(-100%);
    }

    .slide-leave-active {
      position: absolute;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .mobile-feed {
    display: block;
  }
}
</style>
