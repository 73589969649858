<template>
  <div class="categories">
    <div
      v-for="category in categories"
      :key="category.id"
      class="category"
      :class="{
        selected: category.id===selected,
        animating: animating===category.id
      }"
      @click="select(category.id)"
    >
      <img :src="category.icon">
      {{ label(category) }}
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  data() {
    return {
      animating: undefined,
    };
  },
  computed: {
    ...mapState({
      selected: 'selectedChannelsCategory',
      categories: 'channelsCategories',
    }),
  },
  methods: {
    ...mapMutations({
      selectChannelsCategory: 'selectChannelsCategory',
    }),
    select(category) {
      this.animating = category;
      if (category === this.selected) {
        this.selectChannelsCategory(null);
      } else {
        this.selectChannelsCategory(category);
      }
    },
    label(category) {
      return category[`label_${this.$root.$i18n.locale}`] || category.label_en;
    },
  },
};
</script>

<style lang="scss" scoped>
.categories {
  position: relative;
  margin-bottom: 40px;

  .category {
    border-radius: 16px;
    background-color: white;
    width: fit-content;
    display: inline-flex;
    align-items: center;
    padding: 5px 12px;
    margin: 4px;
    font: $font-sbw-300;
    cursor: pointer;

    &.animating {
      animation: on-click .5s;
    }

    img {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      //image-rendering: -webkit-optimize-contrast;
      filter: brightness(0);
    }

    &.selected {
      background: $grad-brand;
      color: white;

      img {
        filter: brightness(0) invert(1);
      }
    }
  }
}

@keyframes on-click {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .categories {
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    white-space: nowrap;

    .category {
      background-color: rgba($clr-nb, .3);
      color: rgba(255, 255, 255, .5);

      img {
        filter: brightness(0) invert(.9);
      }

      &.selected {
        background: rgba($clr-nb, .3);
        color: white;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
