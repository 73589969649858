import User from '../User'; // eslint-disable-line import/no-cycle

// Handles:
// https://arround-msg.gora.studio/doc/#api-Datatype-subscriber
export default function subscriberFromBackend(data) {
  const user = new User({
    id: data.user_id,
    name: data.name,
    username: data.username,
    avatar: data.avatar,
    verified: data.verified,
  });

  return user;
}
