<template>
  <div class="record">
    <video
      id="player"
      ref="vid"
      autoplay
    />
  </div>
</template>

<script>
export default {
  mounted() {
    navigator.mediaDevices.getUserMedia({ audio: true, video: true })
      .then((stream) => {
        this.$refs.vid.srcObject = stream;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="scss" scoped>
.record {
  position: relative;

  #player {
    position: fixed;
    top: 0;
    width: 100%;
    height: calc(100% - 64px);
    object-fit: cover;
  }
}
</style>
