var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"secret-button",class:{
    invisible: _vm.invisible
  },on:{"mouseenter":_vm.startCountdown,"mouseleave":_vm.stopCountdown}},[(_vm.debug)?_c('button',{staticClass:"btn primary",on:{"click":() => {
      _vm.setDebugMode(false)
      _vm.debug = !_vm.debug
    }}},[_vm._v(" Disable debug ")]):_c('button',{staticClass:"btn primary",on:{"click":() => {
      _vm.setDebugMode(true)
      _vm.debug = !_vm.debug
    }}},[_vm._v(" Enable debug ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }