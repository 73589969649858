<template>
  <div class="mobile-feed">
    <TopOverlay />
    <transition name="page-slide">
      <SwipeFeed
        v-if="feedType==='rounds'"
        @openComments="openComments"
      />
      <ScrollFeed
        v-else-if="feedType==='videos'"
        @openComments="openComments"
      />
      <ScrollFeed
        v-else-if="feedType==='channels'"
      />
      <SwipeFeed
        v-else-if="feedType==='streams'"
      />
    </transition>

    <Modal ref="comments">
      <Comments :round="commentsRound" />
    </Modal>
  </div>
</template>

<script>
import Comments from '@/components/Comments/Index.vue';
import Modal from '@/components/Modal.vue';

import ScrollFeed from '../ScrollFeed/Index.vue';

import SwipeFeed from './SwipeFeed/Index.vue';
import TopOverlay from './TopOverlay.vue';

import roundFromBackend from '@/models/dto/roundFromBackend';
import Round from '@/models/Round';

export default {
  name: 'MobileFeed',
  components: {
    TopOverlay,
    SwipeFeed,
    ScrollFeed,
    Modal,
    Comments,
  },
  data() {
    return {
      commentsRound: undefined,
    };
  },
  computed: {
    feedType() {
      if (this.$route.params.type) {
        return this.$route.params.type;
      }
      return null;
    },
  },
  methods: {
    openComments(round) {
      this.commentsRound = round instanceof Round ? round : roundFromBackend(round);
      this.$refs.comments.open();
    },
  },
};
</script>

<style lang="scss" scoped>
// .page-slide-enter-active, .page-slide-leave-active {
//   transition: transform .3s ease;
// }
// .page-slide-enter {
//   transform: translateX(100%);
// }

// .page-slide-leave-to {
//   transform: translateX(-100%);
// }

.mobile-feed {
  position: relative;
}
</style>
