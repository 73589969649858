<template>
  <div class="menu">
    <router-link
      :to="{
        name: 'Profile',
        params: {id: Number($store.getters.profile.id)}
      }"
    >
      <ProfileIcon />
      {{ $t("my_profile") }}
    </router-link>
    <span class="disabled">
      <CollectionIcon />
      {{ $t("collections") }}
    </span>
    <router-link
      :to="{
        name: 'Settings'
      }"
    >
      <SettingsIcon />
      {{ $t("settings.settings") }}
    </router-link>
    <span class="disabled">
      <QuestionIcon />
      {{ $t("questions_support") }}
    </span>
    <hr>
    <span @click="logout">
      <LogoutIcon />
      {{ $t("exit") }}
    </span>
  </div>
</template>

<script>
import CollectionIcon from '@/components/icons/Collection.vue';
import LogoutIcon from '@/components/icons/Logout.vue';
import ProfileIcon from '@/components/icons/Profile.vue';
import QuestionIcon from '@/components/icons/QuestionCircle.vue';
import SettingsIcon from '@/components/icons/Settings.vue';

export default {
  components: {
    ProfileIcon,
    SettingsIcon,
    QuestionIcon,
    LogoutIcon,
    CollectionIcon,
  },
  methods: {
    async logout() {
      await this.$store.dispatch('logout');
      this.$router.push({ name: 'Home', params: { type: 'rounds' } });
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  width: 290px;
  z-index: 200;
  position: absolute;
  padding: 5px 0;
  background-color: white;
  box-shadow: $shadow-3;
  border-radius: 16px;
  top: calc(100% + 12px);
  right: 0;
  font: $font-sbw-400;

  span,
  a {
    cursor: pointer;
    padding: 10px 20px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 20px;
      color: inherit;
    }
  }

  a:hover, a:focus,
  span:hover, span:focus {
    color: var(--clr-brand);
  }

  hr {
    background: $clr-border-light;
    border: none;
    height: 1px;
  }
}
</style>
