import User from '../User'; // eslint-disable-line import/no-cycle

// Handles:
// https://arapi.gora.studio/doc/#api-Datatype-user_profile
// https://arapi.gora.studio/doc/#api-Datatype-publisher
// https://arround-msg.gora.studio/doc/#api-Datatype-author
// https://arapi.gora.studio/doc/#api-Datatype-stream_user
export default function userFromBackend(data) {
  const user = new User({
    id: data.id || data.idt_user,
    name: data.name,
    username: data.username,
    avatar: data.avatar,
    status: data.status,
    verified: data.verified,
    isFollowing: data.is_following,
    followersCount: data.count_followers,
    followingCount: data.count_following,
    blacklisted: data.blacklisted,
    locationName: data.location_name,
    email: data.email,
    emailVerified: data.is_email_verified,
    phone: data.phone,
    website: data.site,
    roundCount: data.round_count,
    isInfluencer: data.is_influencer,
  });

  return user;
}
