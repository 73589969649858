<template>
  <div
    v-click-outside="() => {focused = false}"
    class="msg-input"
  >
    <MentionsMenu
      v-if="mentionsOpen && mentions.length"
      v-model="valueModel"
      :mentions="mentions"
      @input="focus"
      @hide="mentionsHidden = true"
    />
    <div>
      <textarea
        ref="textarea"
        v-model="valueModel"
        rows="1"
        class="textarea"
        data-qa="msg-input-textarea"
        @input="handleInput"
        @keydown.enter="handleEnter"
        @focus="focused = true"
      />
    </div>
    <div
      :class="{ disabled: !internalCanSend }"
      class="send"
      data-qa="msg-input-send-button"
      @click="handleSend"
    >
      <ArrowIcon />
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

import ArrowIcon from '@/components/icons/Arrow.vue';

import MentionsMenu from './MentionsMenu.vue';

export default {
  emits: ['send', 'escape', 'input'],
  components: {
    ArrowIcon,
    MentionsMenu,
  },
  directives: {
    ClickOutside,
  },
  props: {
    canSend: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
      default: '',
    },
    mentions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      valueInternal: '',
      focused: false,
      mentionsHidden: false,
    };
  },
  computed: {
    valueModel: {
      get() { return this.value || this.valueInternal; },
      set(value) {
        this.valueInternal = value;
        this.$emit('input', value);
      },
    },
    mentionsOpen() {
      // ends with a word prefixed by space and @
      const mentionStarted = /(\s|^)@[\w.-]{0,20}$/.test(this.valueModel);
      return mentionStarted && this.focused && !this.mentionsHidden;
    },
    notOnlyWhitespace() {
      function onlyWhitespace(str) {
        return !str.replace(/\s/g, '').length;
      }

      return !onlyWhitespace(this.valueModel);
    },
    internalCanSend() {
      return this.canSend ?? this.notOnlyWhitespace;
    },
  },
  watch: {
    valueModel() {
      this.adjustHeight();
    },
  },
  methods: {
    handleSend(event) {
      if (!event.shiftKey && this.internalCanSend) {
        event.preventDefault();
        this.$emit('send', this.valueModel);
        this.valueModel = '';

        this.$nextTick(this.adjustHeight);
      }
    },
    handleEscape() {
      this.$emit('escape');
    },
    handlePaste(event) {
      event.preventDefault();
      // Strip styling and images
      const text = (event.originalEvent || event).clipboardData.getData('text/plain');
      document.execCommand('insertText', true, text);
    },
    handleEnter(event) {
      if (!event.shiftKey) {
        event.preventDefault();
      }

      if (!this.mentionsOpen) {
        this.handleSend(event);
      }
    },
    handleInput(event) {
      this.focused = true;
      if (event.data === ' ') {
        this.mentionsHidden = false;
      }
    },
    adjustHeight() {
      const { textarea } = this.$refs;
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    },
    focus() {
      this.$refs.textarea.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.msg-input {
  position: relative;
  width: 100%;

  .textarea {
    position: relative;
    top: 0;
    bottom: 32px;
    font: $font-rw-400;
    width: 100%;
    max-width: 100%;
    min-height: 42px;
    max-height: 350px;
    border-radius: 18px;
    border: none;
    resize: none;
    padding: 6px 48px 6px 15px;
    box-sizing: border-box;
    background-color: $clr-10;
    overflow: auto;
    overflow-wrap: break-word;

    &:focus {
      outline: none;
    }
  }

  .send {
    position: absolute;
    cursor: pointer;
    right: 5px;
    bottom: 21px;
    background: var(--clr-brand);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotateZ(180deg) scale(1) translateY(-50%);
    transition: background 0.3s;

    &.disabled {
      background: $clr-40;
    }

    svg {
      color: white;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .msg-input {
    .textarea {
      font: $font-rw-500;
    }

    .send {
      width: 35px;
      height: 35px;
    }
  }
}
</style>
