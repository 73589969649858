<template>
  <BottomOverlay
    :round="round"
    :muted="muted"
    :cached="cached"
    class="overlay"
  >
    <div class="like">
      <span class="number">
        {{ formattedLikes }}
      </span>
      <div
        v-if="round && round.liked"
        class="icon liked"
        @click="like"
      />
      <HeartIcon
        v-else
        class="icon"
        @click.native="like"
      />
    </div>

    <div
      class="mute"
      @click="$emit('mute')"
    >
      <MuteIcon
        v-if="muted"
        class="icon"
      />
      <SpeakerIcon
        v-else
        class="icon"
      />
    </div>

    <div class="chat">
      <div class="comments">
        <Comment
          v-for="c in comments"
          :key="c.time"
          class="comment"
          :comment="c"
        />
      </div>

      <DumbInput
        v-model="comment"
        class="input"
        @send="sendComment"
      />
    </div>
  </BottomOverlay>
</template>

<script>
import numeral from 'numeral';
import { mapActions } from 'vuex';

import Comment from '@/views/Stream/Comment.vue';
import handleStreamEventsMixin from '@/views/Stream/handleStreamEventsMixin';

import HeartIcon from '@/components/icons/Heart.vue';
import MuteIcon from '@/components/icons/Mute.vue';
import SpeakerIcon from '@/components/icons/Speaker.vue';

import DumbInput from '@/components/DumbInput.vue';

import BottomOverlay from './BottomOverlay.vue';

import Stream from '@/models/Stream';

export default {
  components: {
    BottomOverlay,
    Comment,
    DumbInput,
    SpeakerIcon,
    MuteIcon,
    HeartIcon,
  },
  mixins: [handleStreamEventsMixin],
  props: {
    round: {
      type: Stream,
      required: true,
    },
    muted: Boolean,
    cached: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      comments: [],
      comment: '',
    };
  },
  computed: {
    formattedLikes() {
      return numeral(this.round.likeCount).format('0,0a');
    },
  },
  created() {
    this.initStreamSocket(this.round.id);
  },
  methods: {
    ...mapActions(['likeStream', 'addStreamComment']),
    async like() {
      this.round.liked = !this.round.liked;
      this.round.liked = await this.likeStream(
        this.round.id,
      );
    },
    sendComment() {
      if (this.comment !== '') {
        this.addStreamComment({
          id: this.round.id,
          text: this.comment,
        });
        this.comment = '';
      }
    },
    // These methods are used in the handleStreamEventsMixin
    firstEvent(event) {
      this.round.comment_count = event.comment_count;
      this.round.likeCount = event.like_count;
      this.round.viewer_count = event.viewer_count;
      this.round.share_count = event.share_count;
      this.round.liked = event.user_likes;
      this.round.user_shared = event.user_shared;
    },
    newLike(event) {
      this.round.likeCount = event.count;
    },
    newComment(event) {
      this.round.comment_count = event.count;
      this.comments.unshift({
        time: event.time,
        text: event.text,
        author: {
          idt_user: event.idt_user,
          username: event.username,
          name: event.name,
          avatar: event.avatar,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  top: 50px;
}

.icon {
  width: 22px;
  height: 22px;
  color: white;

  &.liked {
    background: $grad-red;
    mask: url('~@/assets/icons/heart-filled.svg') no-repeat center;
  }
}

.like, .mute {
  position: absolute;
  right: 20px;
}

.like {
  top: 20px;
  display: flex;
  align-items: center;

  .number {
    color: white;
    margin-right: 8px;
  }
}

.mute {
  top: 64px;
}

.chat {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50%;
  box-sizing: border-box;
  padding: 0 10px;

  .comments {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    bottom: 0;
    overflow-y: hidden;
    height: calc(100% - 50px);

    .comment {
      color: white;
    }
  }

  .input {
    opacity: .5;
  }
}
</style>
