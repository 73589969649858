import axios from 'axios';

import tokenManager from './tokenManager';

const API_URL = process.env.VUE_APP_API_URL;
const MSG_URL = process.env.VUE_APP_MSG_URL;

export default {
  v1: () => {
    const instance = axios.create({
      baseURL: `${API_URL}/v1`,
    });
    if (tokenManager.getToken()) {
      instance.defaults.headers.common['x-access-token'] = tokenManager.getToken();
    }
    return instance;
  },

  v2: () => {
    const instance = axios.create({
      baseURL: `${API_URL}/v2`,
    });
    if (tokenManager.getToken()) {
      instance.defaults.headers.common['x-access-token'] = tokenManager.getToken();
    }
    return instance;
  },

  msg: () => {
    const instance = axios.create({
      baseURL: `${MSG_URL}/v3`,
    });
    if (tokenManager.getToken()) {
      instance.defaults.headers.common['x-access-token'] = tokenManager.getToken();
    }
    return instance;
  },
};
