<template>
  <div class="feed">
    <RoundFeed
      v-if="feedType==='rounds'"
      @openComments="openComments"
    />
    <ScrollFeed
      v-else-if="feedType==='videos'"
      @openComments="openComments"
    />
    <ScrollFeed v-else-if="feedType==='channels'" />
    <StreamsFeed v-else />

    <Modal
      ref="comments"
    >
      <Comments :round="commentsRound" />
    </Modal>
  </div>
</template>

<script>
import Comments from '@/components/Comments/Index.vue';
import Modal from '@/components/Modal.vue';

import ScrollFeed from '../ScrollFeed/Index.vue';

import RoundFeed from './RoundFeed/Index.vue';
import StreamsFeed from './StreamsFeed/Index.vue';

import roundFromBackend from '@/models/dto/roundFromBackend';
import Round from '@/models/Round';

export default {
  name: 'DesktopFeed',
  components: {
    RoundFeed,
    ScrollFeed,
    StreamsFeed,
    Modal,
    Comments,
  },
  data() {
    return {
      commentsRound: undefined,
    };
  },
  computed: {
    feedType() {
      if (this.$route.params.type) {
        return this.$route.params.type;
      }
      return null;
    },
  },
  methods: {
    openComments(round) {
      this.commentsRound = round instanceof Round ? round : roundFromBackend(round);
      this.$refs.comments.open();
    },
  },
};
</script>

<style lang="scss" scoped>
.all-watched, .spinner {
  padding: 85px 0;
  text-align: center;
}

.spinner {
  svg {
    color: $clr-text-trietary;
    width: 39px;
    height: 39px;
  }
}
</style>
