export default class Stream {
  id;

  title;

  description;

  liked;

  likeCount;

  lat;

  lng;

  viewCount;

  createdAt;

  author;

  constructor(stream) {
    if (stream) {
      Object.keys(this).forEach((property) => {
        if (stream[property]) { this[property] = stream[property]; }
      });
    }
  }
}
