<template>
  <div>
    <div
      v-if="!canPlay"
      class="spinner"
    >
      <SpinnerIcon />
    </div>
    <div
      v-else-if="!playing"
      class="playbtn"
    />

    <component
      :is="overlayComponent"
      :round="round"
      :muted="muted"
      @mute="$emit('muteToggle')"
      @openComments="$emit('openComments')"
    />
  </div>
</template>

<script>
import SpinnerIcon from '@/components/icons/Spinner.vue';

import RoundBottomOverlay from '@/components/Post/RoundBottomOverlay.vue';
import StreamBottomOverlay from '@/components/Post/StreamBottomOverlay.vue';

import roundFromBackend from '@/models/dto/roundFromBackend';
import Stream from '@/models/Stream';

export default {
  emits: ['muteToggle'],
  components: {
    SpinnerIcon,
  },
  props: {
    roundRaw: {
      type: Object,
      required: true,
    },
    muted: {
      type: Boolean,
    },
    playing: {
      type: Boolean,
    },
    canPlay: {
      type: Boolean,
    },
  },
  data() {
    const round = roundFromBackend(this.roundRaw);
    let overlayComponent = null;
    if (round instanceof Stream) {
      overlayComponent = StreamBottomOverlay;
    } else {
      overlayComponent = RoundBottomOverlay;
    }

    return {
      round,
      overlayComponent,
    };
  },
};
</script>

<style lang="scss" scoped>
.spinner,
.playbtn {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}

.playbtn {
  background: no-repeat center url("~@/assets/icons/play-white.svg"),
    no-repeat center url("~@/assets/icons/transbg.svg");
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(64, 68, 128, 0.08);
  color: black;
  backdrop-filter: blur(16px);
}
</style>
