export default {
  name: 'linkToChatMixin',
  methods: {
    getLongId(chat) {
      if (chat.type === 'jinny') {
        return 'jinny';
      }

      if (chat.type === 'support') {
        return chat.id;
      }

      if (chat.type === 'dm') {
        const userId = this.$store.getters.profile.id;

        if (chat.id > userId) {
          return `${chat.type}_${userId}_${chat.id}`;
        }
        return `${chat.type}_${chat.id}_${userId}`;
      }
      return `${chat.type}_${chat.id}`;
    },

    getLinkToChat(chat) {
      return {
        name: 'Messenger',
        params: {
          chatId: this.getLongId(chat),
          chatStringified: JSON.stringify(chat),
        },
      };
    },
  },
};
