<template>
  <div
    class="lang"
    @click="changeLang"
  >
    <GlobeIcon
      :class="{changed: $root.$i18n.locale === 'en'}"
    />
    {{ $t("lang") }}
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

import GlobeIcon from '@/components/icons/Globe.vue';

export default {
  components: {
    GlobeIcon,
  },
  methods: {
    ...mapMutations(['setLocale']),
    changeLang() {
      if (this.$root.$i18n.locale === 'en') {
        this.setLocale({ locale: 'ru', vm: this });
      } else {
        this.setLocale({ locale: 'en', vm: this });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.lang {
  display: flex;
  font: inherit;
  color: inherit;
  cursor: pointer;

  svg {
    margin-right: 10px;
    height: 16.25px;
    width: 16.25px;
    transition: transform .4s;
    color: $clr-text-secondary;
  }

  .changed {
    transform: rotateY(180deg)
  }
}
</style>
