import base from './base';
import onlyData from './onlyData';

export default {
  fetchProfile: ({ id, username }) => base.v1().get('/user/profile', {
    params: {
      idt_user: id,
      username,
    },
  }).then(onlyData)
    .catch((error) => error.response?.data),

  fetchFollowers: ({ id }) => base.v2().get('/user/followers', {
    params: {
      idt_user: id,
    },
  }).then(onlyData),

  fetchFollowing: ({ id }) => base.v2().get('/user/following', {
    params: {
      idt_user: id,
    },
  }).then(onlyData),

  fetchPosts: ({
    userId, arpId, snId, longVideo, limit, offset,
  }) => base.v2().get('/user/roundList', {
    params: {
      idt_user: userId,
      idt_arp: arpId,
      sn_id: snId,
      long_video: longVideo,
      limit,
      offset,
    },
  }).then(onlyData),

  fetchChannels: ({
    userId, admin, limit, snId,
  }) => base.msg().get('/channel/subscription/list', {
    params: {
      user_id: userId,
      admin,
      limit,
      sn_id: snId,
    },
  }).then(onlyData),

  checkInviteCode: ({ value }) => base.v2().get('/user/influencers/codes/check', {
    params: {
      value,
    },
  }).then(onlyData),

  createInviteCode: ({ value, snId }) => base.v2().post('/user/influencers/codes/create', {
    value,
    sn_id: snId,
  }).then(onlyData)
    .catch((error) => error.response?.data),

  archiveInviteCode: ({ id }) => base.v2().put('/user/influencers/codes/archive', {
    idt_invite_code: id,
  }).then(onlyData),

  fetchInviteCodes: () => base.v2().get('/user/influencers/codes/mine').then(onlyData),

  emailVerify: ({ code }) => base.v2().post('/user/email/verify', {
    code,
  }).then(onlyData)
    .catch((error) => error.response?.data),

  sendEmailCodeSn: ({ email }) => base.v2().post('/sn/owner/email/code', {
    email,
  }).then(onlyData)
    .catch((error) => error.response?.data),

  emailVerifySn: ({ code, email }) => base.v2().post('/sn/owner/email/verify', {
    code,
    email,
  }).then(onlyData)
    .catch((error) => error.response?.data),
};
