<template>
  <div class="comment-wrap">
    <div class="comment">
      <router-link
        :to="{ name: 'Profile', params: { id: comment.author.idt_user } }"
      >
        <img
          :src="comment.author.avatar"
          class="supel avatar"
        >
      </router-link>
      <div class="right">
        <router-link
          class="author"
          :to="{ name: 'Profile', params: { id: comment.author.idt_user } }"
        >
          {{ comment.author.username }}
        </router-link>
        <router-link
          v-if="repliesTo.username"
          class="author replies-to"
          :to="{ name: 'Profile', params: { id: repliesTo.idt_user } }"
        >
          <ArrowIcon /> {{ repliesTo.username }}
        </router-link>
        <div class="text">
          <span
            v-html="highlightedText"
          />
          <span class="posted-at">{{ postedAt }}</span>
        </div>
      </div>
    </div>
    <div
      v-if="comment.replies"
      class="replies"
    >
      <Comment
        v-for="reply in comment.replies"
        :key="reply.id"
        :comment="reply"
        :replies-to="comment.author"
      />
    </div>
  </div>
</template>

<script>
import { autoLink } from 'vue-highlights';

import formatDateTimeMixin from '@/mixins/formatDateTimeMixin';

import ArrowIcon from '@/components/icons/Arrow.vue';

import highlightOptions from '@/components/highlightOptions';

export default {
  name: 'Comment',
  components: {
    ArrowIcon,
  },
  mixins: [formatDateTimeMixin],
  props: {
    comment: {
      type: Object,
      required: true,
    },
    repliesTo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    highlightedText() {
      return autoLink(this.comment.text, highlightOptions);
    },

    postedAt() {
      return this.timeSince(this.comment.created_at);
    },
  },
};
</script>

<style lang="scss" scoped>
.comment-wrap {
  margin-bottom: 16px;

  .comment {
    display: flex;
    margin-bottom: 16px;

    .avatar {
      width: 30px;
      height: 30px;
      margin-right: 16px;
    }

    .right {
      margin-top: 4px;

      .author {
        font: $font-sbw-300;

        &.replies-to {
          color: $clr-40;

          svg {
            color: inherit;
            transform: rotate(180deg);
            height: .5rem;
            width: .5rem;
          }
        }
      }

      .text {
        display: inline;
        font: $font-rn-300;

        .posted-at {
          margin-left: 8px;
          color: $clr-40;
        }
      }
    }
  }

  .replies {
    // margin-left: 32px;
  }
}
</style>
