<template>
  <div class="email-verify">
    <div v-if="!isAuthenticated">
      <h1>
        {{ $t('email_verify.login') }}
      </h1>
      <Login />
    </div>

    <div
      v-if="verified"
      class="message"
    >
      {{ $t('email_verify.success') }}
      <router-link
        to="/"
        class="btn primary"
      >
        {{ $t('to_home') }}
      </router-link>
    </div>
    <div
      v-if="errored"
      class="message"
    >
      {{ $t('email_verify.error') }}
      <router-link
        to="/"
        class="btn primary"
      >
        {{ $t('to_home') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import api from '@/api';

import Login from '@/components/TheLogin/Index.vue';

export default {
  components: {
    Login,
  },
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      verified: false,
      errored: false,
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
  watch: {
    isAuthenticated: {
      async handler(authenticated) {
        if (authenticated) {
          const result = await api.user.emailVerify({ code: this.code });
          if (result) {
            this.verified = true;
            setTimeout(() => {
              this.$router.push('/');
            }, 5 * 1000);
          } else {
            this.errored = true;
          }
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.email-verify {
  max-width: 400px;
  margin: auto;
  text-align: center;

  .message {
    position: relative;

    .btn {
      margin-top: 32px;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .email-verify {
    max-width: unset;
    width: 100%;
  }
}
</style>
