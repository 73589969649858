<template>
  <BottomOverlay
    :round="round"
    :muted="muted"
    :cached="cached"
  >
    <div class="side">
      <div
        v-if="!cached"
        class="like"
      >
        <div
          v-if="round && round.liked"
          class="icon liked"
          @click="like"
        />
        <HeartIcon
          v-else
          class="icon"
          @click.native="like"
        />
        <span class="number">
          {{ $formatNum(round.likeCount) }}
        </span>
      </div>
      <div
        class="comment"
        @click="openComments"
      >
        <CommentIcon
          class="icon"
        />
        <span class="number">
          {{ $formatNum(round.commentsCount) }}
        </span>
      </div>
      <div
        v-if="round instanceof VideoRound"
        class="mute"
        @click="$emit('mute')"
      >
        <MuteIcon
          v-if="muted"
          class="icon"
        />
        <SpeakerIcon
          v-else
          class="icon"
        />
      </div>

      <div class="spacer" />
    </div>
  </BottomOverlay>
</template>

<script>
import { mapGetters } from 'vuex';

import CommentIcon from '@/components/icons/Comment.vue';
import HeartIcon from '@/components/icons/Heart.vue';
import MuteIcon from '@/components/icons/Mute.vue';
import SpeakerIcon from '@/components/icons/Speaker.vue';

import BottomOverlay from './BottomOverlay.vue';

import Round from '@/models/Round';
import VideoRound from '@/models/VideoRound';

export default {
  components: {
    BottomOverlay,
    HeartIcon,
    CommentIcon,
    SpeakerIcon,
    MuteIcon,
  },
  props: {
    round: {
      type: Round,
      required: true,
    },
    muted: Boolean,
    cached: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
  beforeCreate() {
    this.VideoRound = VideoRound;
  },
  methods: {
    like() {
      if (this.isAuthenticated) {
        this.$store.dispatch('likeRound', this.round.id);
        if (this.round.liked) {
          this.round.likeCount -= 1;
        } else {
          this.round.likeCount += 1;
        }
        this.round.liked = !this.round.liked;
      } else this.$emitter.emit('unauthedAction');
    },
    openComments() {
      if (this.isAuthenticated) {
        this.$emit('openComments');
      } else {
        this.$emitter.emit('unauthedAction');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.side {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: min-content;
  position: absolute;
  right: 16px;
  bottom: 0;
  top: 0;

  > * {
    margin-bottom: 12px;
    margin-top: 12px;
  }

  .spacer {
    height: 96px;
  }

  .icon {
    width: 22px;
    height: 22px;
    color: white;

    &.liked {
      background: $grad-red;
      mask: url('~@/assets/icons/heart-filled.svg') no-repeat center;
    }
  }

  .like,
  .comment {
    display: flex;
    flex-direction: column;
    align-items: center;

    .number {
      margin-top: 4px;
      color: white;
    }
  }
}
</style>
