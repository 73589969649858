<template>
  <div class="mentions-menu">
    <div
      v-for="(user, index) in mentionOptions"
      :key="user.id"
      class="user-option"
      :class="{
        'selected': selected === index,
      }"
      @click="mentionByIndex(index)"
    >
      <MiniEntity
        :entity="user"
        class="mini-entity"
        :white-text="selected === index"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import MiniEntity from '@/components/MiniEntity.vue';

export default {
  emits: ['input', 'hide'],
  components: {
    MiniEntity,
  },
  props: {
    mentions: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selected: 0,
    };
  },
  computed: {
    ...mapGetters(['profile']),
    mentionOptions() {
      const partialMention = this.value.slice(this.value.lastIndexOf('@') + 1, this.value.length);

      return this.mentions
        .filter((user) => user.id !== this.profile.id)
        .filter((user) => user.username.toLowerCase().includes(partialMention.toLowerCase()));
    },
  },
  watch: {
    mentionOptions() {
      this.selected = 0;
    },
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeydown);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeydown);
  },
  methods: {
    handleKeydown(event) {
      if (event.key === 'ArrowDown') {
        event.preventDefault();
        this.selected += 1;
        if (this.selected >= this.mentionOptions.length) {
          this.selected = 0;
        }
      }

      if (event.key === 'ArrowUp') {
        event.preventDefault();
        this.selected -= 1;
        if (this.selected < 0) {
          this.selected = this.mentionOptions.length - 1;
        }
      }

      if (event.key === 'Enter') {
        this.mentionByIndex(this.selected);
      }

      if (event.key === 'Escape') {
        this.$emit('hide');
      }
    },
    mentionByIndex(index) {
      const replaced = this.value.replace(/@[\w.-]{0,20}$/, `@${this.mentionOptions[index].username} `);
      this.$emit('input', replaced);
    },
  },
};
</script>

<style lang="scss" scoped>
.mentions-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  border-radius: 6px 6px 0 0;
  overflow: hidden;
  box-sizing: border-box;
  top: 0;
  transform: translateY(calc(-100% + 1em));
  background: white;
  border: 1px solid $clr-border-light;
  padding-bottom: 1em;

  .user-option {
    cursor: pointer;
    border-bottom: 1px solid $clr-border-light;

    &.selected {
      background: var(--clr-brand);
    }
  }

  .mini-entity {
    padding: 2px 8px;
  }
}
</style>
