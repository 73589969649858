<template>
  <Item
    :image="image"
    :video="video"
    :text="post.text"
    :post="post"
    @click.native="postClick"
  />
</template>

<script>
import deepmerge from 'deepmerge';

import Item from './Item.vue';

import File from '@/models/File';
import Message from '@/models/Message';
import VideoFile from '@/models/VideoFile';

export default {
  components: {
    Item,
  },
  props: {
    post: {
      type: Message,
      required: true,
    },
  },
  computed: {
    video() {
      return this.post.gallery.find((file) => file instanceof VideoFile);
    },
    image() {
      return this.post.gallery.find((file) => file instanceof File);
    },
  },
  methods: {
    postClick() {
      this.$router.push(deepmerge(this.post.link, {
        params: {
          chatStringified: JSON.stringify(this.post.author),
        },
      }));
    },
  },
};
</script>
