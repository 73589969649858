<template>
  <div class="settings without-sidebar">
    <Back
      class="back"
      color="black"
    />

    <router-link
      :to="{ name: 'SwitchSocialNetwork' }"
      class="setting"
    >
      <img
        :src="selectedSn.avatar"
        class="icon supel"
      >
      {{ $t("sn.net") }}
      <b class="bold">{{ selectedSn.name }}</b>
      <ChevronIcon class="chevron" />
    </router-link>

    <router-link
      :to="{ name: 'BlockedUsers' }"
      class="setting"
    >
      <BlockedUserIcon class="icon branded supel" />
      {{ $t("settings.blocked_users") }}
    </router-link>

    <router-link
      :to="{ name: 'Legal', params: { document: 'terms-of-service' } }"
      target="_blank"
      class="setting"
    >
      <FileIcon class="icon branded supel" />
      {{ $t("legal.tos") }}
    </router-link>

    <router-link
      :to="{ name: 'Legal', params: { document: 'privacy-policy' } }"
      target="_blank"
      class="setting"
    >
      <FileIcon class="icon branded supel" />
      {{ $t("legal.policy") }}
    </router-link>

    <DebugButton />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import BlockedUserIcon from '@/components/icons/BlockedUser.vue';
import ChevronIcon from '@/components/icons/Chevron.vue';
import FileIcon from '@/components/icons/File.vue';

import Back from '@/components/Back.vue';

import DebugButton from './DebugButton.vue';

export default {
  name: 'Settings',
  components: {
    Back,
    BlockedUserIcon,
    ChevronIcon,
    FileIcon,
    DebugButton,
  },
  computed: {
    ...mapState({
      selectedSn: (state) => state.api.sn.selectedSn,
    }),
  },
};
</script>

<style lang="scss" scoped>
.settings {
  .back {
    width: max-content;
    margin-bottom: 64px;
  }

  .setting {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    gap: 8px;

    .icon {
      width: 42px;
      height: 42px;
      box-sizing: border-box;
      object-fit: cover;

      &.branded {
        padding: 12px;
        background: $grad-brand;
        color: white;
      }
    }

    .bold {
      font: $font-sbw-400;
    }

    .chevron {
      transform: rotate(-90deg);
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .settings {
    padding: 0 8px;
    box-sizing: border-box;

    .back {
      position: absolute;
      top: 32px;
      left: 32px;
    }
  }
}
</style>
