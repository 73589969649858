import api from '@/api';

import userFromBackend from './dto/userFromBackend'; // eslint-disable-line import/no-cycle

export default class User {
  id;

  name;

  username;

  avatar;

  status;

  verified;

  isFollowing;

  followersCount;

  followingCount;

  blacklisted;

  locationName;

  website;

  email;

  emailVerified;

  phone;

  roundCount;

  isInfluencer;

  constructor(user) {
    Object.assign(this, user);

    this.link = {
      name: 'Profile',
      params: {
        id: user.id,
      },
    };

    this.deepLink = `https://${process.env.VUE_APP_BASE_URL}?${new URLSearchParams({
      type: 'prof',
      id: this.id,
    })}`;
  }

  async fetchFollowers() {
    return (await api.user.fetchFollowers({ id: this.id })).list_user.map(userFromBackend);
  }

  async fetchFollowing() {
    return (await api.user.fetchFollowing({ id: this.id })).publishers.map(userFromBackend);
  }
}
