<template>
  <div class="post">
    <Poster
      :poster="post.author"
      :created-at="post.createdAt"
      data-theme="light"
      class="poster"
    />

    <div
      v-if="hasMedia"
      class="media"
    >
      <EmbeddedVideo
        v-if="video"
        class="video"
        :video="video"
        pillarboxed
      />

      <img
        v-if="image"
        :src="image.url"
      >
    </div>

    <div class="text">
      {{ textPreview }}
    </div>

    <Footer
      :post="post"
      @openComments="openComments"
    />
  </div>
</template>

<script>
import EmbeddedVideo from '@/components/EmbeddedVideo.vue';
import Poster from '@/components/Poster.vue';

import Footer from './Footer.vue';

import Message from '@/models/Message';
import Round from '@/models/Round';

export default {
  components: {
    Poster,
    Footer,
    EmbeddedVideo,
  },
  props: {
    post: {
      type: [Round, Message],
      required: true,
    },
    image: {
      type: [Object, null],
      default: null,
    },
    video: {
      type: [Object, null],
      default: null,
    },
    text: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      videoElement: {
        playing: false,
        promise: undefined,
      },
    };
  },
  computed: {
    textPreview() {
      if (this.text && this.text.length > 200) {
        return `${this.text.substring(0, 200)}...`;
      }
      return this.text;
    },
    hasMedia() {
      return this.image || this.video;
    },
  },
  methods: {
    playPause() {
      if (this.videoElement.playing) {
        this.videoElement.promise.then(() => {
          this.$refs.video.pause();
          this.videoElement.playing = !this.$refs.video.paused;
        });
      } else {
        this.videoElement.promise = this.$refs.video.play();
        this.videoElement.playing = !this.$refs.video.paused;
      }
    },
    openComments() {
      this.$emit('openComments');
    },
  },
};
</script>

<style lang="scss" scoped>
.post {
  background: white;
  box-shadow: $shadow-3;
  border-radius: 16px;
  margin-bottom: 16px;
  padding: 32px;

  .poster {
    margin-bottom: 24px;
  }

  .media {
    position: relative;
    width: 100%;

    .video, img {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      max-height: 500px;
      object-fit: cover;
      border-radius: 25px 25px 0px 0px;
    }
  }

  .text {
    word-break: break-word;
    margin: 24px 0;
    font: $font-rw-400;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .post {
    padding: 16px;
    // background: rgba($clr-gray, .1);
    // color: white;
    border-radius: 0;

    * {
      color: inherit;
    }

    .media {
      .video {
        max-height: 600px;
      }
    }
  }
}
</style>
