<template>
  <form @submit.prevent>
    <h2 class="centered">
      {{
        transport === "sms"
          ? $t("login.enter_code")
          : $t("login.enter_last_five")
      }}
    </h2>
    <PincodeInput
      v-model="code"
      :length="5"
    />
    <div
      v-if="invalidCode"
      class="error-msg centered"
    >
      {{ $t("login.invalid_code") }}
    </div>
    <p class="centered">
      <img
        v-if="transport === 'call'"
        src="@/assets/phone.png"
        class="phone-tip"
      >
      <span v-if="transport === 'sms'">
        {{ $t("login.were_sending") + phone }}
      </span>
      <span v-else>
        {{ $t("login.were_calling") + phone + $t("login.dont_answer") }}
      </span>
    </p>

    <button
      v-if="codeRequestStatus.possible"
      @click="sendCode"
    >
      {{ $t("login.new_code") }}
    </button>
    <div
      v-else
      class="centered small"
    >
      {{ $t("login.rate_limit") }}
      {{ codeRequestStatus.until }}
      {{ $t("date.interval.second").toLowerCase().charAt(0) }}
    </div>
  </form>
</template>

<script>
import PincodeInput from 'vue-pincode-input';
import { mapActions } from 'vuex';

import api from '@/api';

import toE164 from './toE164phone';

export default {
  emits: ['requestCode', 'loggedIn', 'newUser'],
  components: {
    PincodeInput,
  },
  props: {
    codeRequestStatus: {
      type: Object,
      default: () => ({
        possible: true,
        until: 0,
      }),
    },
    transport: {
      type: String,
      required: true,
    },
    phone: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      code: '',
      invalidCode: false,
    };
  },
  watch: {
    code() {
      if (this.code.length === 5) this.attemptLogin();
    },
  },
  methods: {
    ...mapActions(['login']),
    async attemptLogin() {
      const loginResponse = await api.auth.phoneLogin({
        phone: toE164(this.phone),
        code: this.code,
      });

      const errorCode = loginResponse?.code;
      if (errorCode) {
        this.invalidCode = true;
        this.code = '';
        return;
      }

      if (loginResponse.access_token) { // Existing user -- we log in
        this.login(loginResponse.access_token);
        this.$emit('loggedIn');
      } else { // New user -- we ask for promocode and register
        this.$emit('newUser', loginResponse.phone_token);
      }
    },
    sendCode() {
      this.$emit('requestCode');
    },
  },
};
</script>

<style lang="scss">
.vue-pincode-input-wrapper {
  justify-content: center;
}

input.vue-pincode-input {
  background: $clr-10;
  box-shadow: none;
  padding: 12px 0;
  width: 40px;
  font: $font-rw-400;

  --mask: url("~@/assets/superellipse.svg") 0 0 / 100% 100% no-repeat;
  -webkit-mask: var(--mask);
  mask: var(--mask);
  display: flex;
  justify-content: center;
  align-items: center;
}

input.vue-pincode-input:focus {
  box-shadow: none;
}
</style>

<style lang="scss" scoped>
form {
  display: flex;
  flex-direction: column;

  > * {
    margin: 12px 0;
    border-radius: 12px;
    border: none;
  }

  .small {
    font: $font-rn-200;
    color: $clr-text-trietary;
  }

  .code {
    padding: 8px 12px;
    font: $font-rw-400;
    background: $clr-10;
    border: solid 1px transparent;
  }

  .error {
    border: solid 1px $clr-error;
  }

  .error-msg {
    margin-top: 0;
    color: $clr-error;
    font: $font-rn-200;
  }

  .centered {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;

    .phone-tip {
      width: 100%;
      margin-bottom: 32px;
    }
  }

  button {
    font: $font-sbw-400;
    padding: 12px 12px;
    background: $grad-brand;
    color: white;
    cursor: pointer;

    span {
      color: white;
    }
  }

  button:disabled {
    cursor: default;
    background: $clr-text-trietary;
  }
}
</style>
