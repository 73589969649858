<template>
  <div class="notif">
    <router-link
      v-if="notif.user_from"
      :to="{ name: 'Profile', params: { id: notif.user_from.idt_user } }"
    >
      <img
        :src="notif.user_from.avatar"
        class="user-avatar supel"
      >
    </router-link>

    <PublishNotif
      v-if="notif.idc_notify_type === 5"
      class="text"
      :notif="notifWithTime"
    />
    <FollowerNotif
      v-else-if="notif.idc_notify_type === 4"
      class="text"
      :notif="notifWithTime"
    />
    <RoundLikeNotif
      v-else-if="notif.idc_notify_type === 1"
      class="text"
      :notif="notifWithTime"
    />

    <router-link
      v-if="notif.round"
      :to="{ name: 'Round', params: { id: notif.round.idt_round } }"
      class="round-preview-wrapper"
    >
      <video
        v-if="!mobile && notif.round.media"
        :src="notif.round.media.url"
        :poster="notif.round.url_preview"
        preload="none"
        muted
        class="round-preview"
        @mouseenter="play"
        @mouseleave="pause"
      />
      <img
        v-else
        :src="notif.round.url_preview"
        class="round-preview"
      >
      <div class="title">
        {{ notif.round.message }}
      </div>
    </router-link>
  </div>
</template>

<script>
import formatDateTime from '@/mixins/formatDateTimeMixin';

import FollowerNotif from './FollowerNotif.vue';
import PublishNotif from './PublishNotif.vue';
import RoundLikeNotif from './RoundLikeNotif.vue';

export default {
  components: {
    PublishNotif,
    FollowerNotif,
    RoundLikeNotif,
  },
  mixins: [formatDateTime],
  props: {
    notif: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mobile: true,
      resetVideo: undefined,
    };
  },
  computed: {
    notifWithTime() {
      return {
        ...this.notif,
        time: this.formatDateTime(new Date(this.notif.a_time * 1000)),
      };
    },
  },
  mounted() {
    this.mobile = window.innerWidth <= this.$scss.mobileBreakpoint;
  },
  methods: {
    play(event) {
      const vid = event.target;
      vid.play();

      this.resetVideo = setInterval(() => {
        vid.currentTime = 0;
      }, 2000);
    },
    pause(event) {
      const vid = event.target;
      vid.pause();
      vid.currentTime = 0;
      clearInterval(this.resetVideo);
    },
  },
};
</script>

<style lang="scss" scoped>
.notif {
  margin-bottom: 16px;
  padding-bottom: 16px;
  display: flex;
  position: relative;
  border-bottom: 1px solid $clr-border-light;

  .user-avatar, .round-preview {
    display: inline-flex;
    object-fit: cover;
  }

  .user-avatar {
    margin-right: 8px;
    width: 48px;
    height: 48px;
  }

  .round-preview-wrapper {
    position: relative;
    margin-left: 8px;
    width: 30%;
    min-width: 30%;

    .round-preview {
      width: 100%;
      border-radius: 4px;
      aspect-ratio: 1 / 1;
    }

    .title {
      position: absolute;
      width: 100%;
      box-sizing: border-box;
      bottom: 0;
      color: white;
      padding: 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .text {
    flex-grow: 1;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .notif {
    border: none;
    padding-bottom: 0;

    .round-preview-wrapper {
      width: 32px;
      min-width: 32px;

      .title {
        display: none;
      }
    }

    .user-avatar, .round-preview {
      width: 32px;
      height: 32px;
    }
  }
}
</style>
