<template>
  <div
    v-if="show"
    id="nav"
  >
    <div
      class="nav-btn"
      :class="{'supel': $route.name === 'Home'}"
      @click="homeClick"
    >
      <HomeIcon :class="{active: $route.name === 'Home'}" />
    </div>
    <component
      :is="isAuthenticated ? 'router-link' : 'div'"
      :to="{ name: 'Search' }"
      :class="{ supel: $route.name==='Search' }"
      class="nav-btn"
      @click="!isAuthenticated && $emitter.emit('unauthedAction')"
    >
      <SearchIcon :class="{active: $route.name === 'Search'}" />
    </component>
    <div
      class="plus nav-btn"
      @click="$emitter.emit('getApp')"
    >
      <PlusIcon />
    </div>
    <div class="nav-btn">
      <component
        :is="isAuthenticated ? 'router-link' : 'div'"
        :to="{ name: 'Messenger' }"
        :class="{ supel: $route.name==='Messenger' }"
        @click="!isAuthenticated && $emitter.emit('unauthedAction')"
      >
        <MessengerIcon :class="{active: $route.name === 'Messenger'}" />
      </component>
      <UnreadBubble />
    </div>
    <component
      :is="isAuthenticated ? 'router-link' : 'div'"
      :to="{ name: 'Profile', params: {id: myId} }"
      :class="{ supel: $route.name==='Profile' }"
      class="nav-btn"
      @click="!isAuthenticated && $emitter.emit('unauthedAction')"
    >
      <ProfileIcon :class="{active: $route.name === 'Profile'}" />
    </component>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import HomeIcon from '@/components/icons/Home.vue';
import MessengerIcon from '@/components/icons/Messenger.vue';
import PlusIcon from '@/components/icons/Plus.vue';
import ProfileIcon from '@/components/icons/Profile.vue';
import SearchIcon from '@/components/icons/Search.vue';

import UnreadBubble from '@/components/UnreadBubble.vue';

export default {
  components: {
    UnreadBubble,
    HomeIcon,
    SearchIcon,
    PlusIcon,
    ProfileIcon,
    MessengerIcon,
  },
  data() {
    return {
      hideNav: false,
    };
  },
  computed: {
    ...mapGetters([
      'isAuthenticated',
      'profile',
    ]),
    myId() {
      return this.profile ? this.profile.id : '';
    },
    show() {
      const notMyProfile = () => this.$route.params.id
        && Number(this.$route.params.id) !== this.myId;

      if (this.$route.name === 'Profile' && notMyProfile()) {
        return false;
      }

      const appRoutes = ['Legal', 'GetApp'];

      if (appRoutes.includes(this.$route.name) && this.$route.query.app) {
        return false;
      }
      return !this.hideNav;
    },
  },
  mounted() {
    this.$emitter.on('hideNav', () => {
      this.hideNav = true;
    });
    this.$emitter.on('showNav', () => {
      this.hideNav = false;
    });
  },
  methods: {
    homeClick() {
      if (this.$route.name === 'Home') {
        this.$store.commit('dropFeed');
        this.$store.dispatch('fetchRoundFeed', 0);
      } else {
        this.$router.push({ name: 'Home', params: { type: 'rounds' } });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#nav {
  background-color: $clr-nb;
  z-index: 100;
  position: fixed;
  bottom: 0;
  height: 100px;
  width: 100%;
  box-sizing: border-box;
  left: 0;
  right: 0;
  display: none;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 10px;

  .supel {
    background-color: white;
  }

  .avatar {
    width: 40px;
    height: 40px;
  }

  .plus {
    background: $grad-brand;
    width: auto !important;
    height: auto !important;
    padding: 9px 15px;
    border-radius: 12px;
  }

  a, .nav-btn {
    position: relative;
    cursor: pointer;
    font-weight: bold;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      color: white;
    }

    .active {
      color: $clr-nb;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  #nav {
    display: flex;
  }
}
</style>
