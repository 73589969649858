<template>
  <Modal
    ref="modal"
    :callback="reset"
  >
    <h1>{{ $t('stream.obs_info') }}</h1>

    <div
      v-if="data"
      ref="info"
    >
      <div>
        <h2>{{ $t("stream.server") }}:</h2>
        <a
          class="server"
          @click="copy('server')"
        >
          {{ server }}
          <ClipboardIcon />
        </a>
        <transition name="fade">
          <div v-if="copied['server']">
            {{ $t("copied") }}
          </div>
        </transition>
      </div>
      <div>
        <h2>{{ $t("stream.stream_key") }}:</h2>
        <a
          class="stream-key"
          @click="copy('streamKey')"
        >
          {{ streamKey }}
          <ClipboardIcon />
        </a>
        <transition name="fade">
          <div v-if="copied['streamKey']">
            {{ $t("copied") }}
          </div>
        </transition>
      </div>

      <div class="flex buttons">
        <button
          class="btn secondary"
          @click="close"
        >
          {{ $t("close") }}
        </button>

        <router-link
          class="btn primary"
          :to="{ name: 'Stream', params: { id: data.idt_stream } }"
        >
          {{ $t("stream.open_stream") }}
        </router-link>
      </div>
    </div>
  </Modal>
</template>

<script>
import ClipboardIcon from '@/components/icons/Clipboard.vue';

import Modal from '@/components/Modal.vue';

export default {
  components: {
    Modal,
    ClipboardIcon,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      copied: {
        server: false,
        streamKey: false,
      },
    };
  },
  computed: {
    server() {
      if (this.data.rtmp_url) {
        return this.data.rtmp_url.slice(
          0,
          this.data.rtmp_url.lastIndexOf('/') + 1,
        );
      }
      return undefined;
    },
    streamKey() {
      return `${this.data.idt_stream}?token=${this.data.publish_token}`;
    },
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    copy(ref) {
      const el = this.$refs.info;
      this.$copyText(this[ref], el);
      this.copied[ref] = true;
    },
    reset() {
      this.copied = {
        server: false,
        streamKey: false,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  margin-bottom: 16px;
}

h2 {
  margin: 8px 0;
}

.server, .stream-key {
  word-break: break-all;
  color: var(--clr-brand);
  text-decoration: underline;
  cursor: pointer;

  svg {
    color: inherit;
    height: 1rem;
    width: auto;
    vertical-align: middle;
  }
}

.buttons {
  margin-top: 16px;
  justify-content: space-between;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

</style>
