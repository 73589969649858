<template>
  <div class="comment">
    <span class="author">
      <img
        class="supel"
        :src="comment.author.avatar"
      >
      {{ comment.author.username }}
    </span>
    <span class="text">
      {{ comment.text }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.comment {
  color: inherit;
  font: $font-rn-300;
  margin-bottom: 8px;
  word-break: break-all;

  > * {
    color: inherit;
  }

  .author {
    font: $font-sbn-300;
    margin-right: 8px;

    img {
      width: 20px;
      height: 20px;
      margin-right: 4px;
      vertical-align: middle;
    }

    > * {
      display: inline-block;
    }
  }
}
</style>
