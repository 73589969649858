<template>
  <span class="text">
    <div>
      <router-link
        :to="{ name: 'Profile', params: { id: notif.user_from.idt_user } }"
        class="username-highlight"
      >
        @{{ notif.user_from.username }}
      </router-link>
      {{ $t('notif.subscribed') }}
      <div class="time">
        {{ notif.time }}
      </div>
    </div>

    <FollowButton
      :id="notif.user_from.idt_user"
      class="follow-btn"
      :is-following="notif.user_from.is_following"
      @update="updateButton"
    />
  </span>
</template>

<script>
import FollowButton from '@/components/FollowButton.vue';

export default {
  components: {
    FollowButton,
  },
  props: {
    notif: {
      type: Object,
      required: true,
    },
  },
  methods: {
    updateButton(isFollowing) {
      this.notif.user_from.is_following = isFollowing;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './style.scss';

.text {
  display: flex;
  justify-content: space-between;

  .follow-btn {
    display: inline-flex;
  }
}
</style>
