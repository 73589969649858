<template>
  <div class="comments">
    <Poster
      :poster="round.author"
      :created-at="round.createdAt"
    />
    <Description
      :round="round"
    />

    <div class="comments-title">
      <h1>{{ $t('comments') }}</h1> {{ round.commentCount }}
    </div>
    <div class="comment-list">
      <Comment
        v-for="comment in comments"
        :key="comment.id"
        :comment="comment"
      />
    </div>
    <MsgInput
      v-model="newComment"
      :show-arrow="!!hasText"
      @send="send"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import MsgInput from '@/components/MsgInput/Index.vue';
import Poster from '@/components/Poster.vue';

import Comment from './Comment.vue';
import Description from './Description.vue';

import Round from '@/models/Round';

export default {
  emit: ['commentAdded'],
  name: 'Comments',
  components: {
    Poster,
    Description,
    Comment,
    MsgInput,
  },
  props: {
    round: {
      type: Round,
      required: true,
    },
  },
  data() {
    return {
      comments: [],
      newComment: '',
    };
  },
  computed: {
    hasText() {
      function onlyWhitespace(str) {
        return !str.replace(/\s/g, '').length;
      }
      return !onlyWhitespace(this.newComment);
    },
  },
  async mounted() {
    this.comments = await this.fetchRoundComments({
      round_id: this.round.id,
      offset: 0,
    });
  },
  methods: {
    ...mapActions(['fetchRoundComments', 'addComment']),
    async send() {
      const addedComment = await this.addComment({
        round_id: this.round.id,
        text: this.newComment,
      });
      this.newComment = '';
      this.round.commentCount += 1;
      this.$emit('commentAdded');
      this.comments.push(addedComment);
    },
  },
};
</script>

<style lang="scss" scoped>
.comments {
  .author {
    display: flex;
    align-items: center;

    .avatar {
      min-width: 50px;
      height: 50px;
      margin-right: 16px;
    }

    .top {
      .name {
        font: $font-sbw-300;
      }
      .time {
        font: $font-rw-300;
        color: $clr-gray;
      }
    }

    margin-bottom: 16px;
  }

  .comments-title {
    font: $font-rw-400;
    margin-bottom: 16px;

    h1 {
      font: $font-sbw-500;
      margin-right: 8px;
    }

    > * {
      display: inline;
    }
  }

  .comment-list {
    overflow-y: scroll;
    max-height: 500px;
  }
}
</style>
