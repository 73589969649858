export default class Sn {
  id;

  name;

  nameRu;

  username;

  avatar;

  description;

  isDefault;

  inviteeCount;

  link;

  constructor(sn) {
    Object.assign(this, sn);

    this.link = `https://${this.username}.${process.env.VUE_APP_BASE_URL}`;
    this.linkWithoutProtocol = `${this.username}.${process.env.VUE_APP_BASE_URL}`;
  }
}
