<template>
  <div class="whole-page">
    <Sidebar class="sidebar-wrap" />
    <div class="with-sidebar">
      <Search
        class="search"
        @searchStatus="(status) => searching = status"
      />
      <UsersTop v-if="!searching" />
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/TheSidebar/Index.vue';

import Search from './Search/Index.vue';
import UsersTop from './UsersTop.vue';

export default {
  name: 'SearchPage',
  components: {
    Sidebar,
    Search,
    UsersTop,
  },
  props: {
    query: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      searching: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.search {
  margin-bottom: 64px;
}
</style>
