<template>
  <div
    id="nav"
    @mouseleave="showMenu = false"
  >
    <router-link
      to="/"
      class="home-btn"
    >
      <HorizontalLogo />
    </router-link>

    <div class="sub-nav">
      <router-link
        :to="{ name: 'Home', params: { type: 'rounds' } }"
        exact-active-class="supel"
        class="nav-btn"
      >
        <HomeIcon
          class="icon"
        />
      </router-link>
      <component
        :is="isAuthenticated ? 'router-link' : 'div'"
        to="/search"
        active-class="supel"
        class="nav-btn"
        @click="!isAuthenticated && $emitter.emit('unauthedAction')"
      >
        <SearchIcon
          class="icon"
        />
      </component>
      <div
        exact-active-class="supel"
        class="nav-btn disabled"
        @click="!isAuthenticated && $emitter.emit('unauthedAction')"
      >
        <WalletIcon
          class="icon"
        />
      </div>
      <div class="btn-wrapper">
        <component
          :is="isAuthenticated ? 'router-link' : 'div'"
          to="/messenger"
          active-class="supel"
          class="nav-btn"
          @click="!isAuthenticated && $emitter.emit('unauthedAction')"
        >
          <MessengerIcon
            class="icon"
          />
        </component>
        <UnreadBubble class="bubble" />
      </div>
      <component
        :is="isAuthenticated ? 'router-link' : 'div'"
        to="/notifications"
        active-class="supel"
        class="nav-btn"
        @click="!isAuthenticated && $emitter.emit('unauthedAction')"
      >
        <BellIcon
          class="icon"
        />
      </component>
      <Dropdown
        v-if="showMenu"
        @mouseleave.native="showMenu = false"
      />
      <router-link
        v-if="isAuthenticated"
        :to="profile.link"
        class="nav-btn"
        exact-active-class="supel"
        @mouseover.native="showMenu = true"
      >
        <img
          :src="profile.avatar"
          class="avatar supel"
        >
      </router-link>
      <div
        v-else
        class="login"
        @click="$emitter.emit('unauthedAction')"
      >
        {{ $t("login.login") }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import BellIcon from '@/components/icons/Bell.vue';
import HomeIcon from '@/components/icons/Home.vue';
import MessengerIcon from '@/components/icons/Messenger.vue';
import SearchIcon from '@/components/icons/Search.vue';
import WalletIcon from '@/components/icons/Wallet.vue';

import HorizontalLogo from '@/components/HorizontalLogo.vue';
import UnreadBubble from '@/components/UnreadBubble.vue';

import Dropdown from './Dropdown.vue';

export default {
  components: {
    HomeIcon,
    WalletIcon,
    MessengerIcon,
    BellIcon,
    SearchIcon,
    Dropdown,
    UnreadBubble,
    HorizontalLogo,
  },
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'profile']),
  },
};
</script>

<style lang="scss" scoped>
#nav {
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  padding: 16px 220px;
  height: 64px;
  position: fixed;
  background-color: white;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px $clr-border-light;

  .home-btn {
    position: relative;
    height: 40px;

    img {
      height: 100%;
    }
  }

  .sub-nav {
    position: relative;
    display: flex;

    .supel {
      background-color: $clr-10;

      .icon {
        color: var(--clr-brand);
      }
    }

    .icon {
      color: $clr-text-secondary;
    }

    .login {
      padding: 5px 16px;
      background: $grad-brand;
      height: auto;
      border-radius: 16px;
      color: white;
      font: $font-sbw-300;
    }

    .btn-wrapper {
      position: relative;
    }

    .avatar,
    .nav-btn {
      width: 40px;
      height: 40px;
    }

    .avatar {
      object-fit: cover;
      object-position: center;
    }

    .nav-btn,
    .login {
      margin: 0 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .disabled {
      svg {
        color: $clr-text-trietary;
      }
    }

    .bubble {
      transform: translate(calc(50% - 15px), -50%);
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  #nav {
    display: none;
  }
}
</style>
