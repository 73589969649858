<template>
  <div class="whole-page">
    <Sidebar class="sidebar-wrap" />

    <div class="create-stream with-sidebar">
      <h1>{{ $t("stream.create") }}</h1>

      <form @submit.prevent>
        <div class="field">
          <label>
            {{ $t("name") }}
          </label>
          <input
            v-model="stream.name"
            type="text"
            class="classic-input"
          >
        </div>

        <div class="field">
          <label>
            {{ $t("description") }}
          </label>
          <textarea
            v-model="stream.description"
          />
        </div>

        <div class="field">
          <label>
            {{ $t("stream.donation_url") }}
          </label>
          <input
            v-model="stream.donation_url"
            type="text"
            class="classic-input"
          >
        </div>

        <div class="field">
          <label>
            {{ $t("stream.orientation") }}
          </label>
          <select
            v-model="portrait"
          >
            <option
              :value="0"
            >
              {{ $t('stream.landscape') }}
            </option>
            <option :value="1">
              {{ $t('stream.portrait') }}
            </option>
          </select>
        </div>

        <div class="field">
          <label>
            {{ $t("stream.record") }}
          </label>
          <label class="switch">
            <input
              v-model="stream.enable_recording"
              type="checkbox"
            >
            <span class="slider" />
          </label>
        </div>

        <button
          class="btn primary submit"
          @click="submit"
        >
          {{ $t('stream.get_info') }}
        </button>
      </form>
    </div>

    <StreamInfo
      ref="infoModal"
      :data="info"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import Sidebar from '@/components/TheSidebar/Index.vue';

import StreamInfo from './StreamInfo.vue';

export default {
  name: 'CreateStream',
  components: {
    Sidebar,
    StreamInfo,
  },
  data() {
    return {
      portrait: 0,
      stream: {
        name: '',
        description: '',
        lat: 0,
        lng: 0,
        loc: '',
        donation_url: '',
        enable_recording: false,
      },
      info: undefined,
    };
  },
  computed: {
    orientationStr() {
      return this.portrait ? 'portrait' : 'landscape';
    },
  },
  methods: {
    ...mapActions(['createStream']),
    async submit() {
      this.info = await this.createStream({ ...this.stream, orientation: this.orientationStr });
      this.$refs.infoModal.open();
    },
  },
};
</script>

<style lang="scss" scoped>
.create-stream {
  h1 {
    font: $font-h-400;
    margin-bottom: 64px;
  }
}

.field {
  margin: 16px 0;

  label {
    display: block;
    font: $font-rn-400;
    margin-bottom: 8px;
  }

  input, select, textarea {
    width: 300px;
  }
}
</style>
