import base from './base';
import onlyData from './onlyData';

export default {
  listSn: () => base.v2().get('/sn/list').then(onlyData),

  joinSn: ({ code }) => base.v2().put('/auth/invite-code/join', {
    invite_code: code,
  }).then(onlyData)
    .catch((error) => error.response?.data),

  setDefaultSn: ({ id }) => base.v2().put('/sn/default', {
    sn_id: id,
  }).then(onlyData),

  fetchDefaultSn: () => base.v2().get('/sn/default').then(onlyData),

  fetchSn: ({ username, id }) => base.v2().get('/sn/sn', {
    params: {
      username,
      sn_id: id,
    },
  }).then(onlyData)
    .catch((error) => error.response?.data),

  registerSn: ({
    name, nameRu, username, avatar, description,
  }) => {
    const formData = new FormData();

    formData.append('name', name);
    formData.append('name_ru', nameRu);
    formData.append('username', username);
    formData.append('avatar', avatar);
    formData.append('description', description);

    return base.v2().post('/sn/sn', formData)
      .then(onlyData)
      .catch((error) => error.response?.data);
  },
};
