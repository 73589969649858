<template>
  <div class="footer">
    <div class="left">
      <div class="date">
        {{ sentDate }}
      </div>
      <ViewCounter :post="post" />
    </div>
    <div class="right">
      <div class="reposts" />
      <div
        v-if="post.commentCount"
        class="comments"
      >
        <CommentIcon @click.native="openComments" />
        {{ $formatNum(post.commentCount) }}
      </div>
      <div class="likes">
        <HeartIcon
          v-if="!localLiked"
          @click.stop.native="like()"
        />
        <div
          v-else
          class="liked"
          @click.stop="unlike()"
        />
        {{ $formatNum(localLikes) }}
      </div>
    </div>
  </div>
</template>

<script>
import formatDateTimeMixin from '@/mixins/formatDateTimeMixin';

import CommentIcon from '@/components/icons/Comment.vue';
import HeartIcon from '@/components/icons/Heart.vue';

import ViewCounter from '@/components/ViewCounter.vue';

export default {
  components: {
    HeartIcon,
    ViewCounter,
    CommentIcon,
  },
  mixins: [formatDateTimeMixin],
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localLikes: this.post.likeCount ?? 0,
      localLiked: this.post.liked,
    };
  },
  computed: {
    sentDate() {
      if (this.post.createdAt) {
        return this.formatDateShort(new Date(this.post.createdAt));
      }
      return '';
    },
  },
  methods: {
    async like() {
      await this.post.like();
      this.localLiked = true;
      this.localLikes += 1;
    },
    async unlike() {
      await this.post.unlike();
      this.localLiked = false;
      this.localLikes -= 1;
    },
    openComments() {
      this.$emit('openComments');
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $clr-text-trietary;
}

.footer {
  svg, .liked {
    margin-right: 16px;
    width: 22px;
    height: 22px;
    cursor: pointer;
  }

  .date {
    margin-right: 24px;
  }

  .right {
    > * {
      margin-left: 32px;
    }

    svg {
      cursor: pointer;
    }

    .likes {
      .liked {
        background: $grad-red;
        mask: url('~@/assets/icons/heart-filled.svg') no-repeat center;
      }
    }
  }
}
</style>
