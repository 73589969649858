<template>
  <div class="not-found">
    <div
      v-staging
      class="mascot"
    >
      <div class="oi">
        ОЙ
      </div>
      <img src="@/assets/mascot.png">
    </div>
    <div class="message">
      <h1>Потерялись?</h1>
      <div>
        Воспользуйтесь поиском:
      </div>
      <Search />
      <div>
        Или начните с
        <router-link
          to="/"
          class="link"
        >
          Главной
        </router-link>
      </div>
      <div>
        А если долго смотреть на нашего Лемура – то и идти никуда не придется – ответ придет сам.
      </div>
    </div>
  </div>
</template>

<script>
import Search from '@/views/Search/Search/Index.vue';

export default {
  components: {
    Search,
  },
};
</script>

<style lang="scss" scoped>
.not-found {
  max-width: $mobile-breakpoint;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  .mascot {
    position: relative;
    max-width: 400px;
    width: 400px;

    .oi {
      position: absolute;
      background: $clr-30;
      padding: 32px;
      font: $font-d-400;
      border-radius: 10px;
      right: 0;
      bottom: 0;
      transform: translate(10px, -100px) rotate(-10deg);
    }

    img {
      position: relative;
      width: 400px;
    }
  }

  .message {
    padding: 64px;

    > * {
      margin-bottom: 16px;
    }

    h1 {
      font: $font-d-400;
    }

    .btn {
      display: inline-block;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .mascot {
    display: none;
  }

  .message {
    padding: 16px;
  }
}
</style>
