var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modal',{ref:"modal"},[(!_vm.reported)?_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.$t('report.question'))+" "),_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"btn primary",on:{"click":() => {
          _vm.report({
            target: _vm.target,
            target_id: _vm.id,
          });
          _vm.reported = true;
        }}},[_vm._v(" "+_vm._s(_vm.$t('confirm'))+" ")]),_c('button',{staticClass:"btn secondary",on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])])]):_vm._e(),(_vm.reported)?_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.$t('report.thanks', { snName: _vm.selectedSn.name, snNameRu: _vm.selectedSn.nameRu }))+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }