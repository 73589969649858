<template>
  <div
    class="overlay"
    :class="{
      'channels-mode': feedType === 'channels',
      'dark-text': feedType === 'channels' || feedType === 'videos',
    }"
  >
    <div class="background" />
    <div class="top-row">
      <div
        v-if="feedType!=='videos'"
        class="switch"
        @click="switchFeed"
      >
        <div class="icon">
          <FireIcon class="fire bg" />
        </div>
        <div class="icon">
          <ProfileIcon class="profile bg" />
        </div>
        <div
          class="ball icon"
          :class="{ on: $store.state.api.feed.personalFeedSelected }"
        >
          <ProfileIcon v-if="$store.state.api.feed.personalFeedSelected" />
          <FireIcon v-else />
        </div>
      </div>
      <div class="tabs">
        <component
          :is="isAuthenticated ? 'router-link' : 'div'"
          class="tab"
          :class="{ selected: feedType === 'channels' }"
          :to="{ name: 'Home', params: { type: 'channels' } }"
          @click="!isAuthenticated && $emitter.emit('unauthedAction')"
        >
          {{ $t("channels") }}
        </component>

        <component
          :is="isAuthenticated ? 'router-link' : 'div'"
          class="tab"
          :class="{ selected: feedType === 'videos' }"
          :to="{ name: 'Home', params: { type: 'videos' } }"
          @click="!isAuthenticated && $emitter.emit('unauthedAction')"
        >
          {{ $t("videos") }}
        </component>

        <!-- <component
          :is="isAuthenticated ? 'router-link' : 'div'"
          class="tab"
          :class="{ selected: feedType === 'streams' }"
          :to="{ name: 'Home', params: { type: 'streams' } }"
          @click="!isAuthenticated && $emitter.emit('unauthedAction')"
        >
          {{ $t("streams") }}
        </component> -->

        <router-link
          class="tab"
          :class="{ selected: feedType === 'rounds' }"
          :to="{ name: 'Home', params: { type: 'rounds' } }"
        >
          {{ $t("posts") }}
        </router-link>
      </div>
    </div>

    <CategorySelector
      v-if="feedType === 'channels'"
      class="categories"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import FireIcon from '@/components/icons/Fire.vue';
import ProfileIcon from '@/components/icons/Profile.vue';

import CategorySelector from '../ScrollFeed/CategorySelector.vue';

export default {
  components: {
    CategorySelector,
    FireIcon,
    ProfileIcon,
  },
  data() {
    return {
      personalFeed: false,
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    feedType() {
      if (this.$route.params.type) {
        return this.$route.params.type;
      }
      return null;
    },
  },
  methods: {
    switchFeed() {
      if (this.isAuthenticated) {
        this.$store.commit('switchFeedType');
      } else {
        this.$emitter.emit('unauthedAction');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: calc(21px + env(safe-area-inset-top));
  z-index: 100;
  font: $font-sbw-400;
  filter: $visibility-shadow;
  width: 100%;
  color: white;

  &.dark-text {
    filter: none;
    color: $clr-nb;

    .background {
      position: fixed;
      top: 0;
      width: 100%;
      height: calc(64px + env(safe-area-inset-top));
      background: white;
    }

    .top-row .tabs .tab {
      color: $clr-nb;
    }
  }

  &.channels-mode {
    .background {
      height: calc(114px + env(safe-area-inset-top));
    }
  }

  .top-row {
    position: relative;
    display: flex;
    width: 100%;
    padding: 0 22px;
    box-sizing: border-box;
    gap: 20px;

    .tabs {
      text-align: center;
      flex-grow: 1;
      display: flex;
      gap: 16px;
      justify-content: space-evenly;
      // -webkit-mask-image: linear-gradient(
      //   to right,
      //   rgba(0, 0, 0, 0),
      //   rgba(0, 0, 0, 1) 20px
      // );
      // mask-image: linear-gradient(
      //   to right,
      //   rgba(0, 0, 0, 0),
      //   rgba(0, 0, 0, 1) 20px
      // );

      .tab {
        font: inherit;
        color: white;
        opacity: 0.65;
        display: inline-block;

        &.selected {
          opacity: 1;
        }
      }
    }

    .switch {
      position: relative;
      background-color: rgba(0, 0, 0, 0.3);
      min-width: 50px;
      width: 50px;
      height: 26px;
      border-radius: 26px;

      .icon {
        position: absolute;
        top: 2px;
        left: 2px;
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        box-sizing: border-box;
        opacity: 0.5;

        .profile {
          transform: translateX(24px);
        }

        .bg {
          color: white;
        }
      }

      .ball {
        position: absolute;
        border-radius: 50%;
        background-color: white;
        transition: transform 0.3s;
        opacity: 1;

        &.on {
          transform: translateX(24px);
        }
      }
    }
  }

  .categories {
    top: 16px;
    width: 100%;
  }
}
</style>
