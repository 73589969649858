<template>
  <div class="video-feed">
    <VideoItem
      v-for="(round, i) in mappedFeed"
      :key="round.id"
      ref="items"
      :round="round"
      :loaded="Math.abs(i - onScreen) < 2"
      :first="i === 0"
      :muted="muted"
      @click.native="handleVideoClick(i)"
      @mute="muted = !muted"
      @openComments="openComments(round)"
    />
    <div
      v-if="allWatched"
      class="all-watched"
    >
      <CheckCircleIcon />
      {{ $t('all_watched') }}
    </div>
  </div>
</template>

<script>
import { debounce } from 'debounce';
import {
  mapState, mapGetters, mapActions, mapMutations,
} from 'vuex';

import CheckCircleIcon from '@/components/icons/CheckCircle.vue';

import VideoItem from './VideoItem.vue';

import roundFromBackend from '@/models/dto/roundFromBackend';

export default {
  name: 'DesktopRoundFeed',
  components: {
    VideoItem,
    CheckCircleIcon,
  },
  data() {
    return {
      onScreen: 0,
      lastScrollTop: 0,
      allWatched: false,
      muted: true,
    };
  },
  computed: {
    ...mapGetters(['videoFeed', 'isAuthenticated']),
    ...mapState({
      feed: (state) => state.api.feed.feed,
      personalFeedSelected: (state) => state.api.feed.personalFeedSelected,
    }),
    mappedFeed() {
      return this.videoFeed.map(roundFromBackend);
    },
  },
  watch: {
    videoFeed(newVal, oldVal) {
      if (newVal.length === oldVal.length) {
        this.allWatched = true;
      } else {
        this.allWatched = false;
      }
    },
    personalFeedSelected() {
      this.refetchFeed();
    },
    isAuthenticated() {
      this.refetchFeed();
    },
  },
  mounted() {
    this.setOnScreen();
  },
  created() {
    this.refetchFeed();
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions({
      fetchFeed: 'fetchRoundFeed',
    }),
    ...mapMutations({
      dropFeed: 'dropFeed',
    }),
    handleVideoClick(i) {
      this.onScreen = i;
    },
    refetchFeed() {
      this.loading = true;
      this.dropFeed();
      this.fetchFeed().then(() => {
        this.loading = false;
      });
    },
    setOnScreen() {
      if (this.$refs.items) {
        const BreakException = {};
        try {
          Object.keys(this.$refs.items).forEach((key) => {
            if (this.$refs.items[key].$el.getBoundingClientRect().top >= window.innerHeight / 2) {
              this.onScreen = Math.max(key - 1, 0);
              throw BreakException;
            }
          });
        } catch (e) {
          if (e !== BreakException) throw e;
        }
      }
    },
    handleScroll: debounce(function onScroll() {
      this.setOnScreen();

      const st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > this.lastScrollTop) {
        if (this.onScreen !== 0) this.$refs.items[this.onScreen - 1].pause();
      } else {
        this.$refs.items[Number(this.onScreen) + 1].pause();
      }
      this.lastScrollTop = st <= 0 ? 0 : st;

      this.$refs.items[this.onScreen].play();

      if (!this.loading && !this.allWatched && this.onScreen > this.videoFeed.length - 5) {
        this.loading = true;
        this.fetchFeed(this.feed.length)
          .then(() => {
            this.loading = false;
          });
      }
    }, 1000),
    openComments(round) {
      this.$emit('openComments', round);
    },
  },
};
</script>

<style lang="scss" scoped>
.all-watched {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 85px 0;

  svg {
    color: var(--clr-brand);
  }
}
</style>
