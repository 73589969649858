<template>
  <div
    class="secret-button"
    :class="{
      invisible
    }"
    @mouseenter="startCountdown"
    @mouseleave="stopCountdown"
  >
    <button
      v-if="debug"
      class="btn primary"
      @click="() => {
        setDebugMode(false)
        debug = !debug
      }"
    >
      Disable debug
    </button>
    <button
      v-else
      class="btn primary"
      @click="() => {
        setDebugMode(true)
        debug = !debug
      }"
    >
      Enable debug
    </button>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      countdown: null,
      invisible: true,
      debug: null,
    };
  },
  async mounted() {
    this.debug = await this.getDebugMode();
  },
  methods: {
    ...mapActions([
      'getDebugMode',
      'setDebugMode',
    ]),
    startCountdown() {
      this.countdown = setTimeout(() => {
        this.invisible = false;
      }, 2000);
    },
    stopCountdown() {
      clearTimeout(this.countdown);
    },
  },
};
</script>

<style lang="scss" scoped>
.secret-button {
  transition: opacity 1s;

  &.invisible {
    opacity: 0;
  }
}
</style>
