import api from '@/api';

import channelFromBackend from './dto/channelFromBackend';
import roundFromBackend from './dto/roundFromBackend';
import userFromBackend from './dto/userFromBackend';
import File from './File';
import VideoFile from './VideoFile';

export default class Message {
  author;

  chatId;

  id;

  text;

  createdAt;

  liked;

  likeCount;

  viewCount;

  round;

  model;

  geo;

  file;

  gallery;

  read;

  replied;

  forwarded;

  edited;

  pinned;

  unconfirmed;

  link;

  constructor(message) {
    Object.assign(this, message);

    this.link = {
      name: 'Messenger',
      params: {
        chatId: this.chatId,
      },
      query: {
        mid: this.id,
      },
    };
  }

  like() {
    return api.messenger.likeMessage({
      chatId: this.chatId,
      messageId: this.id,
      like: true,
    });
  }

  unlike() {
    return api.messenger.likeMessage({
      chatId: this.chatId,
      messageId: this.id,
      like: false,
    });
  }
}
