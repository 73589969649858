<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      defaultSn: (state) => state.api.sn.defaultSn ?? state.api.sn.selectedSn,
    }),
    brand() {
      return {
        contactEmail: 'support@zhara.social',
      };
    },
  },
};
</script>

<!-- eslint-disable -->
<template>
  <div class="container">
    <h1>Политика конфиденциальности</h1>
    <div>{{ defaultSn.name }} INC</div>
    <div>Версия 2.2 | Создано: 20.07.2021</div>
    <p>
      Добро пожаловать в {{ defaultSn.name }} (далее “Платформа”)! Платформа
      предоставляется и контролируется {{ defaultSn.name }} Inc. (далее “
      {{ defaultSn.name }}” или “мы”). Мы стремимся защищать и уважать вашу частную
      жизнь. В {{ defaultSn.name }} наша миссия заключается в том, чтобы дать людям
      возможность выразить себя, жить в данный момент, познать мир и весело
      проводить время вместе. Чтобы предоставлять наши услуги и делать их лучше,
      мы узнаем кое-что о вас, когда вы используете {{ defaultSn.name }}. Например,
      если мы знаем, что у вас день рождения, мы можем отправить вам подарок,
      например, уникальную 3D-модель! Или, если мы увидим, что вы проводите день
      на пляже, мы можем позаботиться о том, чтобы ваша Лента соответствовала
      вашему занятию. Неплохо, правда?
    </p>
    <p>
      Текст Политики конфиденциальности является публичной офертой, создавая
      аккаунт в {{ defaultSn.name }}, вы подтверждаете, что ознакомились с текущей
      версией Политики конфиденциальности и безоговорочно принимаете настоящие
      условия (производите акцепт оферты), а Политика конфиденциальности
      приобретает юридическую силу в отношениях между вами и Платформой
      {{ defaultSn.name }}.
    </p>
    <h2>1. Типы информации, которую мы собираем</h2>
    <p>
      Во-первых, мы узнаем о вас, когда вы пользуетесь нашим сервисом. Так что,
      даже если вы нигде не указываете, что вы любитель футбола, если вы всегда
      смотрите хайлайты футбольных матчей в Ленте, подписаны на Реал Мадрид и
      размещаете Посты с футбольными мячами, это несложная догадка.
    </p>
    <p>
      Во-вторых, мы видим информацию, которую вы сами нам предоставляете.
      Например, когда вы создаете аккаунт {{ defaultSn.name }}, мы узнаем ваш день
      рождения, адрес электронной почты и уникальное имя, которым вы бы хотели
      называться в {{ defaultSn.name }}.
    </p>
    <p>
      В-третьих, мы иногда узнаем о вас от других людей. Например, если друг
      загружает свой список контактов, мы можем увидеть ваш номер телефона.
    </p>
    <p>Мы можем собирать и использовать следующую информацию о вас:</p>
    <ul>
      <li>
        Информация, которую вы нам предоставляете. Вы предоставляете нам
        информацию о себе при регистрации и/или использовании Платформы, включая
        ваше имя, возраст, пол, адрес, адрес электронной почты, номер телефона,
        вашу фотографию и языковые настройки. Кроме того, эта категория включает
        в себя профиль пользователя, комментарии, которые вы оставляете на нашей
        Платформе (включая любые Виртуальные Предметы, которые вы добавляете в
        любой пользовательский контент) и платежные данные, включая, помимо
        прочего, учетные записи Apple, Google, данные о сторонних способах
        оплаты, необходимых для оплаты или приобретения товаров на платформе.
        Она также включает пользовательский контент, фотографии и видео-контент,
        который вы выбираете для показа на нашей Платформе.
      </li>
      <br />
      <li>
        Техническая информация, которую мы собираем о вас. Мы автоматически
        собираем определенную информацию о вас, когда вы используете Платформу,
        включая информацию о вашем местоположении (как описано ниже) или другие
        уникальные идентификаторы устройства, историю браузера (в том числе
        контент, который вы просматривали в Платформе), Файлы Cookies (как
        определено ниже), информацию о вашем операторе мобильной связи, часовом
        поясе, информацию о вашем мобильном телефоне, включая модель вашего
        устройства, разрешение экрана, операционную систему и платформу, а также
        информацию о вашем использовании Платформы.
      </li>
      <br />
      <li>
        Поведенческая информация, которую мы собираем о вас. Мы также собираем
        информацию об использовании вами Сервисов, например, ваши комментарии на
        нашей Платформе или любой другой пользовательский контент и
        видео-контент, который вы создаете и транслируете на нашей Платформе.
        Кроме того, мы связываем вашу контактную информацию или информацию о
        вас, как о подписчике, с вашей деятельностью на нашей Платформе на всех
        ваших устройствах. Мы собираем информацию о вашей активности (лайки,
        комментарии, повторные просмотры) и на ее основании рекомендуем вам
        пользователей. Наконец, мы собираем информацию о настройках подписки и
        настройках общения.
      </li>
      <br />
      <li>
        Данные о местоположении. Когда вы используете Платформу на мобильном
        устройстве, мы можем собирать информацию о вашем местоположении. С
        вашего согласия мы также будем собирать данные глобальной системы
        позиционирования (GPS) и информацию о местоположении мобильного
        устройства.
      </li>
      <br />
      <li>
        Телефон и контакты. Вы можете выбрать, как найти других пользователей
        Платформы через ваши телефонные контакты. Если вы решите найти других
        пользователей через свои телефонные контакты, мы получим доступ к вашим
        телефонным контактам, включая имена, номера телефонов, адреса и любую
        иную информацию, которую вы храните на своем телефоне о ваших контактах,
        чтобы определить, используют ли они Платформу, сопоставляя их с
        существующими пользователями Платформы.
      </li>
      <br />
      <li>
        Сообщения. Мы собираем и обрабатываем (что включает в себя сканирование
        и анализ) информацию, которую вы предоставляете, включая любую личную
        информацию, в контексте составления, отправки или получения сообщений
        (что означает содержание, а также информацию об адресатах сообщений, о
        получении и/или прочтении, а также об участниках общения) через сервис
        обмена сообщениями нашей Платформы. Обратите внимание, что сообщения,
        отправленные другим пользователям нашего Сервиса, будут доступны другим
        пользователям, и что мы не несем ответственности за то, как эти
        пользователи используют или раскрывают сообщения.
      </li>
      <br />
      <li>
        Информацию в чате с искусственным интеллектом (Джинни). На нашей
        Платформе есть ИИ чат-бот, который имеет те же функции, что и обычный
        разговор с реальным человеком. Для того, чтобы помочь нам сделать диалог
        с Джинни более реалистичным, вам нужно предоставить некоторую информацию
        о себе, однако мы собираем только ту информацию, которую вы намеренно
        предоставляете через ваш разговор. В связи с психологической целью
        чата-бота мы не делимся вашими разговорами с Джинни ни с какой другой
        компанией или службой. Мы не продаем ваши личные данные или историю
        разговоров. Мы сознательно не собираем и не храним медицинскую
        информацию, как и любую информацию о состоянии здоровья, предоставлении
        медицинской помощи или оплате медицинского обслуживания.
      </li>
      <br />
      <li>
        Информация о монетизации. На нашей Платформе функционирует система
        вознаграждений за определенные действия на платформе. Мы не собираем
        информацию о ваших банковских данных, но мы оставляем за собой право
        публичного использования информации о заработках пользователей
        {{ defaultSn.name }}, в том числе используя и упоминая данные пользователя,
        указанные им при регистрации, такие как Фамилия, Имя, Отчество, никнейм.
        Указанные данные обрабатываются и используются в целях составления
        рейтингов доходов пользователей на платформе и использования в рекламе
        {{ defaultSn.name }}. Пользователь предоставляет свое согласие на обработку
        указанных персональных данных, принимая настоящую политику
        конфиденциальности, а также предоставляет свое согласие на
        распространение этих данных в вышеперечисленных целях.
      </li>
    </ul>
    <h2>2. Cookies</h2>
    <p>
      Мы используем файлы Cookies и другие подобные технологии (например,
      веб-маяки, flash-файлы cookies и т. д.) («Файлы Cookies») для улучшения
      ваших впечатлений от использования Платформы, улучшения наших Сервисов и
      предоставления вам целевой рекламы. Файлы Cookies — это небольшие файлы,
      которые при размещении на вашем устройстве позволяют нам предоставлять
      определенные функции и возможности. Никакие Файлы Cookies или информация,
      собранная с использованием Файлов Cookies, не распространяются каким-либо
      третьим лицам с целью получения какой-либо выгоды. Файлы Cookies
      сохраняются только на вашем устройстве, пока вы их не удалите.
    </p>
    <p>Мы используем следующие Файлы Cookies:</p>
    <ul>
      <li>
        Обязательные Файлы Cookies. Это Файлы Cookies, которые необходимы для
        работы Платформы. Они включают, например, Файлы Cookies, которые
        позволяют вам получать доступ в защищенные области Платформы.
      </li>
      <br />
      <li>
        Функциональные Файлы Cookies. Эти Файлы Cookies используются для того,
        чтобы идентифицировать вас при возвращении на Платформу. Это позволяет
        нам персонализировать содержание сайта для вас, приветствовать вас по
        имени и запоминать ваши предпочтения (например, выбранный язык или
        регион). Эти Файлы Cookies поддерживают функцию входа на Платформу в
        течение 90 дней.
      </li>
      <br />
      <li>
        Целевые Файлы Cookies. Эти Файлы Cookies фиксируют ваше посещение
        Платформы, открытые вами страницы и ссылки, по которым вы перешли,
        включая ваше использование других вебсайтов или приложений. Мы будем
        использовать эту информацию, чтобы сделать Платформу и размещенную на
        ней рекламу более актуальной для вас.
      </li>
      <br />
      <li>
        Аналитические Файлы Cookies. Аналитические Файлы Cookies - это
        статистические системы измерения аудитории, которые мы используем в
        отношении наших Сервисов, чтобы определить, какие веб-страницы вы
        посещаете и как вы используете Платформу.
      </li>
    </ul>
    <p>
      Если вы в дальнейшем по какой-либо причине не собираетесь пользоваться
      преимуществами, которые предоставляют Файлы Cookies, вы можете отключить
      Файлы Cookies путем изменения настроек вашего браузера. Однако, если вы
      так сделаете, то это может повлиять на ваше использование Платформы и мы
      более не сможем предоставлять вам персонализированный контент. До тех пор,
      пока вы не откажетесь от использования Файлов Cookies, мы будем полагать,
      что вы дали свое согласие на использование Файлов Cookies.
    </p>
    <p>
      Сигналы "Не отслеживать" являются настройками пользователя, которые он
      делает в своем браузере для ограничения отслеживания его активности на
      сторонних вебсайтах и платформах. Платформа не реагирует на сигналы "Не
      отслеживать" в вашем браузере.
    </p>
    <h2>3. Как мы используем вашу информацию</h2>
    <p>Мы будем использовать собранную о вас информацию для следующих целей:</p>
    <ul>
      <li>
        администрирования Платформы (т. е. для предоставления вам наших
        Сервисов) и для внутренних процессов, включая устранение неполадок,
        анализ данных, тестирование, исследования, сбор статистики и проведение
        опросов (т. е. для того, чтобы гарантировать стабильность и безопасность
        Платформы), а также для получения ваших отзывов;
      </li>
      <br />
      <li>
        предоставления вам интерактивных функций Платформы, если вы решите
        воспользоваться ими;
      </li>
      <br />
      <li>
        предоставления вам персонализированного контента, который будет вам
        интересен;
      </li>
      <br />
      <li>улучшения и развития Платформы, а также других наших продуктов;</li>
      <br />
      <li>
        оценки эффективности рекламы, которую мы показываем вам и другим
        пользователям;
      </li>
      <br />
      <li>
        предоставления вам сервисов с учетом выбранных вами настроек страны,
        например, рекламы и другого контента, связанного с выбором той или иной
        страны;
      </li>
      <br />
      <li>
        предложения и рекомендаций вам и другим пользователям Платформы
        потенциально интересных товаров и услуг;
      </li>
      <br />
      <li>
        идентификации вас через функцию "Найти других друзей" в качестве
        пользователя Сервиса, а также предоставления вам возможности найти
        других пользователей и связаться с ними на Платформе и поддержки функции
        нашего Сервиса, помогающей пользователям общаться друг с другом;
      </li>
      <br />
      <li>
        мы используем информацию отслеживания лица (TrueDepth API), чтобы
        прикрепить к вашему лицу некоторый 3D-контент. Мы не храним какие-либо
        данные пользователей или устройств, которые мы не раскрываем и не
        предоставляем третьим лицам;
      </li>
      <br />
      <li>
        предоставления вам информации о вашем профиле для отправки такой
        информации другим лицам по вашему выбору и взаимодействия с другими
        пользователями;
      </li>
      <br />
      <li>
        показа вам контента, который похож на ранее понравившийся вам контент,
        или на контент, с которым вы ранее взаимодействовали (путем
        комментирования и / или просмотра), контента из вашего региона, а также
        контента от пользователей, на которых вы подписаны;
      </li>
      <br />
      <li>показа вам релевантной рекламы;</li>
      <br />
      <li>
        продвижения Сервиса и использования информации, которую вы
        предоставляете нам, такой как Пользовательский контент и видео-контент,
        который вы можете транслировать на нашей Платформе, в рамках наших
        рекламных и маркетинговых кампаний по продвижению Платформы;
      </li>
      <br />
      <li>
        обеспечения функционирования нашей службы обмена сообщениями (хотя
        данная информация обрабатывается только в том случае, если вы решите
        использовать данную функцию), при этом вы можете удалить любую
        информацию по вашему усмотрению с помощью функции очистки кэша в
        настройках Платформы;
      </li>
      <br />
      <li>
        обнаружения злоупотреблений, мошенничества и незаконной деятельности на
        Платформе;
      </li>
      <br />
      <li>
        проверки факта достижения вами возраста, необходимого для пользования
        нашими Сервисами (в соответствии с требованиями законодательства);
      </li>
      <br />
      <li>уведомления вас об изменениях в наших Сервисах;</li>
      <br />
      <li>связи с вами;</li>
      <br />
      <li>предоставления вам технической поддержки;</li>
      <br />
      <li>обеспечения соблюдения наших условий и политик;</li>
      <br />
      <li>получения от вас и / или осуществления платежей.</li>
    </ul>
    <h2>4. Как мы делимся вашей информацией</h2>
    <p>Мы передаем деперсонализированную информацию следующим третьим лицам:</p>
    <ul>
      <li>
        поставщикам услуг облачных хранилищ для хранения предоставленной вами
        информации и услуг аварийного восстановления, а также для выполнения
        любого договора, который мы заключаем с вами;
      </li>
      <br />
      <li>поставщикам ИТ-услуг;</li>
      <br />
      <li>
        нашему центру обработки данных и хостинг-провайдерам наших серверов.
      </li>
    </ul>
    <p>
      Мы будем передавать вашу информацию по запросу правоохранительным органам,
      государственным органам или другим организациям, если это требуется по
      закону, или если такая передача необходима чтобы:
    </p>
    <ul>
      <li>выполнять обязанности, процедуры или запросы;</li>
      <br />
      <li>
        обеспечить соблюдение нашего Пользовательского соглашения и других
        соглашений, политик и стандартов, включая процедуру расследования любых
        потенциальных нарушений данных документов;
      </li>
      <br />
      <li>
        выявлять, предотвращать или иным образом реагировать на угрозы
        безопасности, мошенничество или технические вопросы;
      </li>
      <br />
      <li>
        защищать наши права, собственность или безопасность, а также права,
        собственность или безопасность наших пользователей, третьих лиц или
        общества в целом в соответствии с требованиями законодательства (включая
        обмен информацией с другими компаниями и организациями в целях защиты от
        мошенничества и снижения кредитного риска).
      </li>
    </ul>
    <h2>5. Ваши права</h2>
    <p>
      Если вы зарегистрировали учетную запись, вы можете получить доступ и
      обновить определенную личную информацию, которую вы предоставили нам,
      войдя в свою учетную запись и используя доступные там функции и
      возможности.
    </p>
    <p>
      Вы можете отключить функцию отслеживания вашего местоположения на
      мобильном устройстве, если не хотите делиться с нами данными GPS или
      информацией о местонахождении вашего мобильного устройства.
    </p>
    <p>
      Вы можете в любой момент отозвать свое согласие на обработку персональных
      данных, направив нам уведомление посредством электронной почты на
      электронный адрес
      <a :href="`mailto:${brand.contactEmail}`">{{ brand.contactEmail }}</a> с
      пометкой «Отзыв согласия на обработку персональных данных».
    </p>
    <h2>6. Безопасность вашей информации</h2>
    <p>
      Мы принимаем все необходимые и достаточные меры для обеспечения
      безопасного обращения с вашей информацией в соответствии с настоящей
      Политикой, в том числе меры по защите от неправомерного доступа,
      изменения, раскрытия или уничтожения. К сожалению, передача информации
      через Интернет не является полностью безопасной. Несмотря на то, что мы
      делаем все возможное, чтобы защитить вашу информацию, например, с помощью
      шифрования, мы не можем гарантировать безопасность вашей информации,
      передаваемой через Платформу; любая передача информации осуществляется на
      ваш страх и риск.
    </p>
    <p>
      У нас есть соответствующие технические и организационные средства для
      обеспечения достаточного уровня безопасности в зависимости от вероятности
      наступления какого-либо риска и степени его угрозы для ваших прав и
      свобод, а также прав и свобод других пользователей. Мы поддерживаем эти
      технические и организационные средства и будем периодически вносить в них
      изменения для повышения общей безопасности наших систем.
    </p>
    <p>
      Мы периодически включаем ссылки на вебсайты наших партнерских сетей,
      рекламодателей и аффилированных лиц в наши рассылки. Если вы переходите по
      ссылке на любой из этих вебсайтов, обратите внимание, что эти вебсайты
      имеют свои собственные политики конфиденциальности и что мы не несем
      ответственности за эти политики. Пожалуйста, ознакомьтесь с этими
      политиками, прежде чем передавать какую-либо информацию этим веб-сайтам.
    </p>
    <p>
      Обратите внимание, что если вы загружаете видео или фото на Платформу, вы
      совершаете это действие с публичного аккаунта, что означает, что если вы
      разместите видео или фото, его сможет просмотреть каждый пользователь
      Платформы, независимо от того подписан ли он на вас или нет.
    </p>
    <h2>7. Как долго мы храним вашу информацию</h2>
    <p>
      Срок обработки персональных данных является неограниченным. Мы используем
      следующие критерии для определения периода, в течение которого мы будем
      хранить вашу информацию:
    </p>
    <ul>
      <li>
        наши договорные обязательства и права в отношении соответствующей
        информации;
      </li>
      <br />
      <li>
        наши обязанности в соответствии с применимыми законами и подзаконными
        актами по хранению данных в течение определенного периода времени;
      </li>
      <br />
      <li>
        срок исковой давности в соответствии с действующим законодательством;
      </li>
      <br />
      <li>наши законные деловые цели;</li>
      <br />
      <li>существующие или потенциальные споры.</li>
    </ul>
    <p>
      После того, как вы прекратили пользоваться нашими Сервисами, мы можем
      хранить вашу информацию в обобщенном и обезличенном формате. Несмотря на
      вышесказанное, мы также можем хранить любую личную информацию, которая
      разумно необходима для выполнения наших юридических обязанностей,
      разрешения споров, а также для обеспечения соблюдения наших соглашений. В
      соответствии с действующим законодательством на территории страны
      использования сервиса, мы вправе продолжить обработку персональных данных
      без вашего согласия при наличии оснований, указанных в действующих на
      территории страны использования сервиса законах и иных нормативно-правовых
      актах.
    </p>
    <h2>8. Информация о детях</h2>
    <p>
      Платформа не предназначена для детей младше 12 лет. Мы не регистрируем
      пользователей младше 12 лет. Если нам станет известно, что персональные
      данные были получены от лиц младше 12 лет, мы удалим эту информацию и
      закроем аккаунт.
    </p>
    <h2>9. Изменения</h2>
    <p>
      Как правило, мы уведомляем всех пользователей о любых существенных
      изменениях в этой Политике посредством размещения уведомления на нашей
      Платформе. Тем не менее, вам следует регулярно просматривать настоящую
      Политику, чтобы проверять наличие изменений. Мы также обновляем дату
      “последнего обновления” в верхней части настоящей Политики, которая
      отражает дату вступления в силу соответствующей обновленной Политики. Ваш
      дальнейший доступ к Сервисам или их использование после даты обновления
      означает ваше согласие с обновленной Политикой. Если вы не согласны с
      обновленной Политикой, вы должны прекратить доступ или использование
      Сервисов.
    </p>
    <h2>10. Контактная информация</h2>
    <p>
      Вопросы, комментарии и пожелания относительно данной Политики следует
      направлять по адресу:
    </p>
    <p>Boumpoulinas, 1, 3rd floor, Flat/Office 31, 1060, Nicosia, Cyprus</p>
    <p>
      <a :href="`mailto:${brand.contactEmail}`">{{ brand.contactEmail }}</a>
    </p>
    <br />
    <p>
      <b>
        Если вы используете наши Сервисы на всей территории мира, используя наш
        вебсайт, сервисы, приложения, продукты и контент (которые включают в
        себя, помимо прочего, Платформу), вы даете свое согласие (в соответствии
        с действующими на территории страны использования сервиса законами и
        иными нормативно-правовыми актами) на обработку персональных данных, в
        том числе с использованием средств автоматизации и без использования
        автоматизации любыми способами, включая: сбор, запись, систематизацию,
        накопление, хранение, уточнение (обновление, изменение), извлечение,
        использование, передачу (предоставление, доступ), обезличивание,
        блокирование, удаление, уничтожение, в соответствии с настоящей
        Политикой конфиденциальности.
      </b>
    </p>
  </div>
</template>
