<template>
  <div class="scroll-feed">
    <component
      :is="feedType === 'videos' ? 'PostItem' : 'MessageItem'"
      v-for="post in feed"
      :key="post.id"
      ref="items"
      :post="post"
      @openComments="openComments(post)"
    />
    <div
      v-if="loading"
      class="spinner"
    >
      <SpinnerIcon />
    </div>
    <div
      v-else
      class="all-watched"
    >
      <CheckCircleIcon />
      {{ $t("all_watched") }}
    </div>
  </div>
</template>

<script>
import { debounce } from 'debounce';
import {
  mapState, mapActions, mapMutations, mapGetters,
} from 'vuex';

import CheckCircleIcon from '@/components/icons/CheckCircle.vue';
import SpinnerIcon from '@/components/icons/Spinner.vue';

import MessageItem from './MessageItem.vue';
import PostItem from './PostItem.vue';

import messageFromBackend from '@/models/dto/messageFromBackend';
import roundFromBackend from '@/models/dto/roundFromBackend';

export default {
  name: 'ScrollFeed',
  components: {
    PostItem,
    MessageItem,
    SpinnerIcon,
    CheckCircleIcon,
  },
  data() {
    return {
      onScreen: 0,
      loading: false,
      allLoaded: false,
    };
  },
  computed: {
    ...mapState({
      channelsFeed: (state) => state.api.feed.channelsFeed,
      selectedCategory: (state) => state.api.feed.selectedChannelsCategory,
      personalFeedSelected: (state) => state.api.feed.personalFeedSelected,
    }),
    ...mapGetters({
      videoFeed: 'videoFeed',
      isAuthenticated: 'isAuthenticated',
    }),
    feedType() {
      return this.$route.params.type;
    },
    feed() {
      if (this.feedType === 'videos') {
        return this.videoFeed.map((round) => roundFromBackend(round));
      }
      return this.channelsFeed.map((msg) => messageFromBackend(msg));
    },
  },
  watch: {
    selectedCategory() {
      this.refetchFeed();
    },
    feedType() {
      this.refetchFeed();
    },
    personalFeedSelected() {
      this.refetchFeed();
    },
    isAuthenticated() {
      this.refetchFeed();
    },
  },
  created() {
    this.refetchFeed();
    this.fetchCategories();
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions({
      fetchChannelsFeed: 'fetchChannelsFeed',
      fetchVideosFeed: 'fetchLongVidFeed',
      fetchCategories: 'fetchChannelsCategories',
    }),
    ...mapMutations({
      dropChannelsFeed: 'dropChannelsFeed',
      dropVideosFeed: 'dropFeed',
    }),
    openComments(post) {
      this.$emit('openComments', post);
    },
    fetchFeed(offset) {
      if (this.feedType === 'videos') {
        return this.fetchVideosFeed(offset);
      }
      return this.fetchChannelsFeed(offset);
    },
    dropFeed() {
      if (this.feedType === 'videos') {
        return this.dropVideosFeed();
      }
      return this.dropChannelsFeed();
    },
    refetchFeed() {
      this.allLoaded = false;
      this.loading = true;
      this.dropFeed();
      this.fetchFeed().then(() => {
        this.loading = false;
      });
    },
    setOnScreen() {
      if (this.$refs.items) {
        const BreakException = {};
        try {
          Object.keys(this.$refs.items).forEach((key) => {
            if (this.$refs.items[key].$el.getBoundingClientRect().top >= window.innerHeight / 2) {
              this.onScreen = Math.max(key, 0);
              throw BreakException;
            }
          });
        } catch (e) {
          if (e !== BreakException) throw e;
        }
      }
    },
    handleScroll: debounce(function onScroll() {
      this.setOnScreen();

      const st = window.pageYOffset || document.documentElement.scrollTop;
      this.lastScrollTop = st <= 0 ? 0 : st;

      if (!this.loading && !this.allLoaded && this.onScreen > this.feed.length - 10) {
        this.loading = true;
        const oldLength = this.feed.length;
        this.fetchFeed(this.feed.length)
          .then(() => {
            this.loading = false;
            if (oldLength === this.feed.length) {
              this.allLoaded = true;
            }
          });
      }
    }, 100),
  },
};
</script>

<style lang="scss" scoped>
.all-watched, .spinner {
  padding: 85px 0;
  text-align: center;
}

.all-watched {
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    color: var(--clr-brand);
  }
}

.spinner {
  svg {
    color: $clr-text-trietary;
    width: 39px;
    height: 39px;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .scroll-feed {
    padding-top: calc(16px + env(safe-area-inset-top));
    padding-bottom: 96px;
    // background-color: $clr-nb;
  }
}
</style>
