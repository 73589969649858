import { render, staticRenderFns } from "./GetApp.vue?vue&type=template&id=419b49aa&scoped=true&"
import script from "./GetApp.vue?vue&type=script&lang=js&"
export * from "./GetApp.vue?vue&type=script&lang=js&"
import style0 from "./GetApp.vue?vue&type=style&index=0&id=419b49aa&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "419b49aa",
  null
  
)

export default component.exports