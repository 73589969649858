<template>
  <div
    class="stream-player"
    :class="{
      vertical: stream && stream.orientation === 'portrait'
    }"
  >
    <StreamPlayerComponent
      :stream="stream"
      :muted="muted"
      @canplay="canPlay = true"
    />
    <div
      class="mute"
      @click="muted = !muted"
    >
      <MuteIcon
        v-if="muted"
        class="icon"
      />
      <SpeakerIcon
        v-else
        class="icon"
      />
    </div>
    <div
      v-if="!canPlay"
      class="spinner"
    >
      <SpinnerIcon />
    </div>
  </div>
</template>

<script>
import MuteIcon from '@/components/icons/Mute.vue';
import SpeakerIcon from '@/components/icons/Speaker.vue';
import SpinnerIcon from '@/components/icons/Spinner.vue';

import StreamPlayerComponent from '@/components/StreamPlayer/Index.vue';

export default {
  components: {
    StreamPlayerComponent,
    SpinnerIcon,
    SpeakerIcon,
    MuteIcon,
  },
  props: {
    stream: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      canPlay: false,
      muted: true,
    };
  },
};
</script>

<style lang="scss" scoped>
.stream-player {
  height: 0;
  overflow: hidden;
  padding-top: math.div(591.44px, 1127.34px) * 100%;
  background: white;
  position: relative;
  border-radius: inherit;

  &.vertical {
    padding-top: math.div(1127.34px, 591.44px) * 100%;
  }
}

video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}

.spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $clr-40;
  background: $clr-20;
  border-radius: inherit;
  box-sizing: border-box;

  svg {
    width: 64px;
    height: 64px;
  }
}

.mute {
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 22px;
  cursor: pointer;

  .icon {
    width: 22px;
    height: 22px;
    color: white;
  }
}

</style>
