import api from '@/api';

export default (Api) => ({
  actions: {
    fetchModelsTop({ _ }, { device }) {
      return Api.v2
        .get('/model/top', {
          params: {
            device,
          },
        }).then((response) => response.data);
    },

    fetchUsersTop({ _ }) {
      return Api.v2
        .get('/user/top')
        .then((response) => response.data);
    },

    searchRoundByHashtag({ _ }, hashtag) {
      if (hashtag.charAt(0) !== '#') {
        hashtag = `#${hashtag}`;
      }
      return Api.v2
        .get('/round/searchByHashtag', {
          params: {
            hashtag,
          },
        })
        .then((response) => response.data);
    },

    searchUsers({ _ }, query) {
      return Api.v2
        .get('/user/search', {
          params: {
            query,
          },
        })
        .then((response) => response.data);
    },

    searchChannels({ rootState }, query) {
      return api.messenger.searchChannels({
        query,
        snId: rootState.api.sn.selectedSn.id,
      });
    },
  },
});
