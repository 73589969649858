<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      defaultSn: (state) => state.api.sn.defaultSn ?? state.api.sn.selectedSn,
    }),
    brand() {
      return {
        contactEmail: 'support@zhara.social',
      };
    },
  },
};
</script>

<!-- eslint-disable -->
<template>
  <div class="container">
    <h1>Privacy Policy</h1>
    <div>{{ defaultSn.name }} INC</div>
    <div>Version 2.2 | Created: 20.07.2021</div>
    <p>
      Welcome to {{ defaultSn.name }} (the “Platform”). The Platform is provided and
      controlled by {{ defaultSn.name }} Inc. (“{{ defaultSn.name }}”, “we” or “us”). We
      are committed to protecting and respecting your privacy.
    </p>
    <p>
      At {{ defaultSn.name }}, our mission is to empower people to express
      themselves, live in the moment, learn about the world, and have fun
      together.
    </p>
    <p>
      To provide our services and make them better, we learn some things about
      you when you use {{ defaultSn.name }}. For example, if we know it’s your
      birthday, we can send you and your friends a unique 3D model to help
      celebrate! Or, if we see that you’re spending a day at the beach, we can
      make sure your Feed is dressed for the occasion. Nice, right?
    </p>
    <p>
      The text of the Privacy Policy is a public offer, by creating an account
      in {{ defaultSn.name }}, you certify that you have read the current version of
      the Privacy Policy and unconditionally accept these terms (make the
      acceptance of the offer), and the Privacy Policy becomes legal between you
      and the {{ defaultSn.name }} Platform.
    </p>
    <h2>1. The types of personal data we use</h2>
    <p>
      First, we learn about you when you use our service. So, while you may not
      tell us you’re a sports fan, if you’re always watching basketball
      highlights on your Feed and you are posting Rounds with soccer balls, it’s
      a safe guess.
    </p>
    <p>
      Second, we learn from whatever information you choose to give us. For
      example, when you set up an {{ defaultSn.name }} account, we learn your
      birthday, email address, and the unique name you’d like to go by.
    </p>
    <p>
      Third, we sometimes learn about you from other people and services. For
      example, if a friend uploads their contact list, we might see your phone
      number.
    </p>
    <p>We collect and use the following information about you:</p>
    <ul>
      <li>
        <b>Information you provide to us.</b> You provide us with information
        about yourself when you register and/or use the Platform, including your
        name, age, gender, address, email address, phone number, your photo and
        language settings. In addition, this category includes your user
        profile, the comments you leave on our Platform (including any Virtual
        Items you add to any user content) and payment information, including
        but not limited to Apple, Google accounts, third-party payment method
        information required to pay or purchase items on the Platform. It also
        includes user-generated content, photos and video content that you
        choose to display on our Platform.
      </li>
      <br />
      <li>
        <b>Technical Information we collect about you.</b> We automatically
        collect certain information from you when you use the Platform including
        when you are using the App without an account. Such information includes
        your IP address, browsing history (i.e. the content you have viewed on
        the Platform), mobile carrier, time zone settings, identifier for
        advertising purposes and the version of the app you are using. We will
        also collect information regarding the device you are using to access
        the Platform such as the model of your device, the device system,
        network type, and operating system. Where you log-in from multiple
        devices, we will be able to use your profile information to identify
        your activity across devices.
      </li>
      <br />
      <li>
        <b>User Content and Behavioural Information.</b> We process the content
        you generate on the Platform, including preferences you set (such as
        choice of language), photographs and videos you upload and comments you
        make. We also collect information regarding your use of the Platform,
        e.g., how you engage with the Platform, including how you interact with
        content we show to you, videos you watch and problems encountered, the
        content you like, and the users you follow. We also infer your
        preferences, including your interests, gender and age for the purpose of
        personalising content. We process information about your followers, the
        likes you receive and responses to content you upload, for the purposes
        of promoting your content to other users.
      </li>
      <br />
      <li>
        <b>Location.</b> We use the 'Region' you select in Settings to customise
        your {{ defaultSn.name }} experience except where we provide location-based
        services, in which case we collect GPS (where we have your consent).
      </li>
      <br />
      <li>
        <b>Phone and contacts.</b> You can choose how to find other users on the
        Platform through your phone contacts. If you choose to find other users
        through your phone contacts, we will access your phone contacts,
        including names, phone numbers, addresses, and any other information you
        store on your phone about your contacts to determine whether they use
        the Platform by matching them to existing users of the Platform.
      </li>
      <br />
      <li>
        <b>Messages.</b> We collect and process (which includes scanning and
        analyzing) information that you provide, including any personal
        information, in the context of composing, sending or receiving messages
        (which means content as well as information about message recipients,
        receipt and/or readings, and participants in communications) through our
        Platform's messaging service. Please note that messages sent to other
        users of our Service will be available to other users, and that we are
        not responsible for how those users use or disclose messages.
      </li>
      <br />
      <li>
        <b>AI (Geenny) conversation history.</b> On our Platform we have an AI
        chat-bot that has the same functions as a regular conversation with a
        real person.Therefore to help us deliver your experience with Geenny you
        need to provide her some information about yourself however we collect
        only information you intentionally provide through your conversation
        with Geenny. Due to the psychological aim of the chat-bot we do not
        share conversations with your Geenny with any other company or service.
        We will never sell your personal data or conversation history. We do not
        knowingly collect or store medical information, as any information about
        health status, provision of health care, or payment for health care that
        can be linked to a specific individual. We discourage you from
        communicating this information to Geenny through text chat so that this
        information doesn’t become part of your chat history.
      </li>
      <br />
      <li>
        <b>Information about monetization.</b> Our Platform operates a system of
        rewards for certain actions on the platform. We do not collect
        information about your banking information, but we reserve the right to
        publicly use information about the earnings of {{ defaultSn.name }} users,
        including using and mentioning user data specified during registration,
        such as last name, first name, middle name, nickname. These data are
        processed and used for the purpose of ranking user earnings on the
        platform and for use in {{ defaultSn.name }} advertising. The user gives his
        consent to the processing of these personal data, accepting this privacy
        policy, and also gives his consent to the dissemination of these data
        for the above-mentioned purposes.
      </li>
    </ul>
    <h2>2. Cookies</h2>
    <p>
      We and our vendors and service providers use cookies and other similar
      technologies (e.g., web beacons, flash cookies, etc.) (“Cookies”) to
      automatically collect information, measure and analyze which web pages you
      click on and how you use the Platform, enhance your experience using the
      Platform, improve our services, and provide you with targeted advertising
      on the Platform and elsewhere across your different devices. Cookies are
      small files which, when placed on your device, enable the Platform to
      provide certain features and functionality. Web beacons are very small
      images or small pieces of data embedded in images, also known as “pixel
      tags” or “clear GIFs,” that can recognize Cookies, the time and date a
      page is viewed, a description of the page where the pixel tag is placed,
      and similar information from your computer or device. By using the
      Platform, you consent to our use of Cookies.
    </p>
    <p>
      Additionally, we allow our business partners, advertising networks, and
      other advertising vendors and service providers (including analytics
      vendors and service providers) to collect information about your online
      activities through Cookies. We link your contact or subscriber information
      with your activity on our Platform across all your devices, using your
      email or other log-in or device information. These third parties may use
      this information to display advertisements on our Platform and elsewhere
      online tailored to your interests, preferences, and characteristics. We
      are not responsible for the privacy practices of these third parties, and
      the information practices of these third parties are not covered by this
      Privacy Policy.
    </p>
    <p>
      You may be able to refuse or disable Cookies by adjusting your browser
      settings. Because each browser is different, please consult the
      instructions provided by your browser. Please note that you may need to
      take additional steps to refuse or disable certain types of Cookies. For
      example, due to differences in how browsers and mobile apps function, you
      may need to take different steps to opt out of Cookies used for targeted
      advertising in a browser and to opt out of targeted advertising for a
      mobile application, which you may control through your device settings or
      mobile app permissions. In addition, your opt-out selection is specific to
      the particular browser or device that you are using when you opt out, so
      you may need to opt-out separately for each browser or device. If you
      choose to refuse, disable, or delete Cookies, some of the functionality of
      the Platform may no longer be available to you.
    </p>
    <h2>3. How we use your personal data</h2>
    <p>
      We will use the information we collect about you in the following ways:
    </p>
    <ul>
      <li>
        To administer the Platform (i.e., to provide you with our Services) and
        for internal processes, including troubleshooting, data analysis,
        testing, research, gathering statistics and surveys (i.e., to ensure the
        stability and security of the Platform), and to receive your feedback;
      </li>
      <br />
      <li>
        To provide you with interactive features of the Platform if you choose
        to use them;
      </li>
      <br />
      <li>
        To provide you with personalized content that will be of interest to
        you;
      </li>
      <br />
      <li>
        To improve and develop the Platform as well as our other products;
      </li>
      <br />
      <li>
        To evaluate the effectiveness of the advertising we display to you and
        other users;
      </li>
      <br />
      <li>
        To provide you with services tailored to the country settings you
        choose, such as advertising and other content related to your country of
        choice;
      </li>
      <br />
      <li>
        To suggest and recommend potentially interesting products and services
        to you and other users of the Platform;
      </li>
      <br />
      <li>
        To identify you through the "Find Other Friends" feature as a user of
        the Service, and allowing you to find and connect with other users on
        the Platform and supporting our Service feature to help users
        communicate with each other;
      </li>
      <br />
      <li>
        We are using face tracking information (TrueDepth API) to attach some 3D
        content to the your face. We do not store any user or device data which
        we do not disclose or give any access to third parties;
      </li>
      <br />
      <li>
        To provide you with information about your profile so that you can send
        such information to others of your choosing and interact with other
        users;
      </li>
      <br />
      <li>
        To show you content that is similar to content you have previously liked
        or previously interacted with (by commenting and/or browsing), content
        from your region, and content from users you have subscribed to;
      </li>
      <br />
      <li>To show you relevant advertising;</li>
      <br />
      <li>
        To promote the Service and using the information you provide to us, such
        as User Content and video content that you may broadcast on our
        Platform, as part of our advertising and marketing campaigns to promote
        the Platform;
      </li>
      <br />
      <li>
        To enable our messaging service (although this information is only
        processed if you choose to use this feature), and you may delete any
        information at your discretion using the cache cleanup feature in the
        Platform settings;
      </li>
      <br />
      <li>To detect abuse, fraud and illegal activities on the Platform;</li>
      <br />
      <li>
        To verify that you are of legal age to use our Services (as required by
        law).
      </li>
      <br />
      <li>To notify you about changes to our Services;</li>
      <br />
      <li>To communicate with you;</li>
      <br />
      <li>To provide you with technical support;</li>
      <br />
      <li>To ensure compliance with our terms and policies;</li>
      <br />
      <li>To receive payments from you and/or making payments.</li>
    </ul>
    <h2>4. How we share your personal data</h2>
    <p>
      We transfer depersonalized information to the following third parties:
    </p>
    <ul>
      <li>
        Cloud storage providers to store the information you provide and
        disaster recovery services, and to fulfill any contract we enter into
        with you;
      </li>
      <br />
      <li>IT service providers;</li>
      <br />
      <li>Our data center and our server hosting providers.</li>
    </ul>
    <p>
      We will transfer your information upon request to law enforcement
      agencies, government agencies, or other entities if required by law or if
      such transfer is necessary to:
    </p>
    <ul>
      <li>To comply with duties, procedures, or requests;</li>
      <br />
      <li>
        To ensure compliance with our User Agreement and other agreements,
        policies and standards, including the procedure for investigating any
        potential violations of these documents;
      </li>
      <br />
      <li>
        To detect, prevent or otherwise respond to security threats, fraud or
        technical issues; or protect our rights, property or safety and the
        rights, property or safety of our users, third parties or the public at
        large as required by law (including sharing information with other
        companies and organizations for fraud protection and credit risk
        reduction purposes).
      </li>
    </ul>
    <h2>5. Your Choices</h2>
    <p>
      If you have registered an account, you can access and update certain
      personal information that you have provided to us by logging into your
      account and using the features and functionality available there.
    </p>
    <p>
      You can turn off your mobile location tracking if you do not want to share
      your mobile device's GPS data or location information with us.
    </p>
    <p>
      You may withdraw your consent to the processing of personal data at any
      time by sending us a notice by email to
      <a :href="`mailto:${brand.contactEmail}`">{{ brand.contactEmail }}</a>
      with the subject line "Withdrawal of consent to the processing of personal
      data".
    </p>
    <h2>6. The security of your personal data</h2>
    <p>
      We take steps to ensure that your information is treated securely and in
      accordance with this policy. Unfortunately, the transmission of
      information via the internet is not completely secure. Although we will
      protect your personal data, for example, by encryption, we cannot
      guarantee the security of your information transmitted via the Platform;
      any transmission is at your own risk.
    </p>
    <p>
      We have appropriate technical and organizational measures to ensure a
      level of security appropriate to the risk of varying likelihood and
      severity for the rights and freedoms of you and other users. We maintain
      these technical and organizational measures and will amend them from time
      to time to improve the overall security of our systems.
    </p>
    <p>
      We will, from time to time, include links to and from the websites of our
      partner networks, advertisers and affiliates. If you follow a link to any
      of these websites, please note that these websites have their own privacy
      policies and that we do not accept any responsibility or liability for
      these policies. Please check these policies before you submit any
      information to these websites.
    </p>
    <p>
      Please note that if you upload a video or photo to the Platform, you are
      doing so from a public account, which means that if you post a video or
      photo, it can be viewed by everyone on the Platform, whether they are
      subscribed to you or not.
    </p>
    <h2>7. How long we keep your personal data</h2>
    <p>
      The time period for processing personal information is unlimited. We use
      the following criteria to determine the period for which we will retain
      your information:
    </p>
    <ul>
      <li>
        Our contractual obligations and rights with respect to the relevant
        information;
      </li>
      <br />
      <li>
        Our obligations under applicable laws and regulations to retain data for
        a specified period of time;
      </li>
      <br />
      <li>The statute of limitations under applicable law;</li>
      <br />
      <li>Our legitimate business purposes;</li>
      <br />
      <li>Existing or potential disputes.</li>
    </ul>
    <p>
      After you stop using our Services, we may retain your information in an
      aggregated and anonymized format. Notwithstanding the foregoing, we may
      also retain any personal information that is reasonably necessary to
      fulfill our legal obligations, resolve disputes, and enforce our
      agreements. In accordance with applicable laws in the country where the
      service is used, we may continue to process your personal information
      without your consent for the reasons set forth in the laws and regulations
      in force in the country where the service is used.
    </p>
    <h2>8. Information relating to children</h2>
    <p>
      {{ defaultSn.name }} is not directed at children under the age of 12. If you
      believe that we have personal data about or collected from a child under
      the relevant age, contact us.
    </p>
    <h2>9. Changes</h2>
    <p>
      We will generally notify all users of any material changes to this policy,
      through a notice provided via the Platform. However, you should look at
      this policy regularly to check for any changes. We will also update the
      “Last Updated” date at the top of this policy, which reflects the
      effective date of such policy. Your continued access to or use of the
      Platform after the date of the updated policy constitutes your acceptance
      of the updated policy. If you do not agree to the updated policy, you must
      stop accessing or using the Platform.
    </p>
    <h2>10. Contact</h2>
    <p>
      Questions, comments and requests regarding this Policy should be addressed
      to:
    </p>
    <p>Boumpoulinas, 1, 3rd floor, Flat/Office 31, 1060, Nicosia, Cyprus</p>
    <p>
      <a :href="`mailto:${brand.contactEmail}`">{{ brand.contactEmail }}</a>
    </p>
    <br />
    <p>
      <b>
        If you use our Services throughout the world, by using our website,
        services, applications, products and content (which include but are not
        limited to the Platform), you consent (subject to applicable laws and
        other regulations in the country of use of the service) to the
        processing of personal data, including with or without the use of
        automation by any means, including: collection, recording,
        systematization, accumulation, storage, clarification (update, change),
        extraction, use, transfer (provision, access), depersonalization,
        blocking, deletion, destruction, in accordance with this Privacy Policy.
      </b>
    </p>
  </div>
</template>
