<template>
  <div
    v-if="round"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
      throttle: 1000,
    }"
    class="item"
  >
    <div class="user">
      <Poster
        :poster="round.author"
        :created-at="round.createdAt"
      />

      <div v-if="!$store.state.api.feed.personalFeedSelected">
        <FollowButton
          :id="proxyRound.author.id"
          :is-following="proxyRound.author.isFollowing"
          @update="status => $set(proxyRound.author, 'isFollowing', status)"
        />
      </div>
    </div>
    <!-- eslint-disable -->
    <!-- Вроде как vue-highlights защищен от xss -->
    <div
      class="message"
      v-html="highlightedMessage"
    />
    <!-- eslint-enable -->
    <div class="video">
      <div
        class="report"
        @click="report"
      >
        <FlagIcon />
      </div>
      <div
        class="mute"
        @click="mute"
      >
        <MuteIcon v-if="muted" />
        <SpeakerIcon v-else />
      </div>
      <video
        v-if="loaded"
        ref="video"
        class="video"
        loop
        :autoplay="first"
        :muted="muted"
        :src="round.video"
        preload="auto"
        :poster="round.preview"
        @click="handleVideoClick"
      />
      <img
        v-else
        class="video"
        :src="round.preview"
      >
      <div class="interact">
        <div
          class="btn"
          @click="like"
        >
          <div
            v-if="round.liked"
            :class="{
              clickable: isAuthenticated,
            }"
            class="like liked"
          />
          <HeartIcon
            v-else
            :class="{
              clickable: isAuthenticated,
            }"
            class="like"
          />
          <span class="number">
            {{ formattedLikes }}
          </span>
        </div>
        <div
          class="btn"
          @click="openComments"
        >
          <CommentIcon
            class="clickable"
          />
          <span class="number">
            {{ formattedComments }}
          </span>
        </div>
      </div>
    </div>

    <ReportModal
      :id="round.id"
      ref="report"
      target="round"
    />
  </div>
</template>

<script>
import { autoLink } from 'vue-highlights';
import { mapGetters, mapActions } from 'vuex';

import CommentIcon from '@/components/icons/Comment.vue';
import FlagIcon from '@/components/icons/Flag.vue';
import HeartIcon from '@/components/icons/Heart.vue';
import MuteIcon from '@/components/icons/Mute.vue';
import SpeakerIcon from '@/components/icons/Speaker.vue';

import FollowButton from '@/components/FollowButton.vue';
import highlightOptions from '@/components/highlightOptions';
import Poster from '@/components/Poster.vue';
import ReportModal from '@/components/ReportModal.vue';

import VideoRound from '@/models/VideoRound';

export default {
  components: {
    ReportModal,
    FollowButton,
    Poster,
    HeartIcon,
    CommentIcon,
    SpeakerIcon,
    MuteIcon,
    FlagIcon,
  },
  props: {
    round: {
      type: VideoRound,
      required: true,
    },
    loaded: {
      type: Boolean,
    },
    first: Boolean,
    muted: Boolean,
  },
  data() {
    return {
      proxyRound: { ...this.round },
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    formattedLikes() {
      return this.$formatNum(this.round.likeCount);
    },
    formattedComments() {
      return this.$formatNum(this.round.commentCount);
    },
    highlightedMessage() {
      return autoLink(this.round.description, highlightOptions);
    },
  },
  methods: {
    ...mapActions(['viewRound']),
    handleVideoClick(event) {
      if (event.target.paused) event.target.play();
      else event.target.pause();
    },
    play() {
      this.$refs.video.play();
    },
    pause() {
      this.$refs.video.pause();
    },
    mute() {
      this.$emit('mute');
    },
    async report() {
      this.$refs.report.open();
    },
    like() {
      if (this.isAuthenticated) {
        this.$store.dispatch('likeRound', this.round.id);
      } else {
        this.$emitter.emit('unauthedAction');
      }
    },
    openComments() {
      if (this.isAuthenticated) {
        this.$emit('openComments');
      } else {
        this.$emitter.emit('unauthedAction');
      }
    },
    visibilityChanged(isVisible) {
      if (this.isAuthenticated && isVisible) {
        this.viewRound(this.round.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  background-color: white;
  box-shadow: $shadow-3;
  border-radius: 16px;
  margin-bottom: 16px;
  padding: 32px;

  .user {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .message {
    margin-bottom: 24px;
    word-break: break-word;
    font: $font-rw-400;
  }

  .video {
    display: flex;
    align-items: flex-end;
    position: relative;

    .report, .mute {
      cursor: pointer;
      position: absolute;
      z-index: 10;
      left: calc(60% - 50px);

      svg {
        color: white;
      }
    }

    .report {
      top: 25px;
    }

    .mute {
      bottom: 25px;
    }

    .video {
      border-radius: 16px;
      width: 60%;
      margin-right: 24px;
      cursor: pointer;
    }

    .interact {
      display: inline-flex;
      flex-direction: column;

      .btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: $clr-10;
        border-radius: 24px;
        padding: 10px 16px;
        margin-top: 16px;
        width: fit-content;

        .clickable {
          cursor: pointer;
        }

        .like {
          width: 22px;
          height: 22px;
        }

        .liked {
          background: $grad-red;
          mask: url('~@/assets/icons/heart-filled.svg') no-repeat center;
        }

        .number {
          margin-left: 10px;
          font: $font-sbw-400;
        }
      }
    }
  }
}
</style>
