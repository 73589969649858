<template>
  <div>
    <div class="input-wrapper">
      <input
        v-show="category === 'all'"
        id="all-search"
        v-model="query"
        type="text"
        class="classic-input"
        :placeholder="$t('search.search')"
      >
      <input
        v-show="category === 'hashtags'"
        id="hashtag-search"
        ref="hashtagInput"
        v-model="query"
        v-maska="hashtagMask"
        type="text"
        class="classic-input"
        :placeholder="$t('search.search')"
      >
      <input
        v-show="['users', 'channels'].includes(category)"
        id="user-search"
        v-model="query"
        v-maska="userMask"
        type="text"
        class="classic-input"
        :placeholder="$t('search.search')"
      >
      <SearchIcon />
    </div>

    <Categories
      :selected="category"
      @select="selectCategory"
    />

    <div
      v-if="query.length > 0"
      class="search-results"
    >
      <SearchResult
        v-for="result in searchResults"
        :key="result.id"
        :search-result="result"
      />
      <div
        v-if="loading"
        class="status"
      >
        <SpinnerIcon />
      </div>
      <div
        v-else-if="searchResults.length === 0"
        class="status"
      >
        {{ $t('search.nothing_found') }}
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'debounce';
import { mapActions } from 'vuex';

import SearchIcon from '@/components/icons/Search.vue';
import SpinnerIcon from '@/components/icons/Spinner.vue';

import Categories from './Categories.vue';
import SearchResult from './SearchResult.vue';

export default {
  name: 'Search',
  emits: ['searchStatus'],
  components: {
    SearchIcon,
    SpinnerIcon,
    Categories,
    SearchResult,
  },
  data() {
    return {
      query: this.$route.query.q ?? '',
      category: this.$route.query.c ?? 'all',
      searchResults: [],
      loading: false,
      userMask: { mask: 'U*', tokens: { U: { pattern: /[0-9a-zA-Zа-яА-Я_.-]/ } } },
      hashtagMask: { mask: '!#H*', tokens: { H: { pattern: /[0-9a-zA-Zа-яА-Я_]/ } } },
    };
  },
  watch: {
    query: debounce(function searchOnQueryChange() {
      if (this.query.length > 0) {
        this.$router.replace({ name: 'Search', query: { q: this.query, c: this.category } });
        this.performSearch();
      } else {
        this.$router.replace({ name: 'Search', query: { c: this.category } });
        this.$emit('searchStatus', false);
      }
    }, 500),
    category() {
      if (this.category.length > 0) {
        this.$router.replace({ name: 'Search', query: { q: this.query, c: this.category } });
        this.performSearch();
      }
    },
  },
  mounted() {
    this.performSearch();

    if (this.category === 'hashtags') {
      // ❗️🩼❗️
      setTimeout(() => {
        this.$refs.hashtagInput.dispatchEvent(new InputEvent('input'));
      });
    }
  },
  methods: {
    ...mapActions([
      'searchRoundByHashtag',
      'searchUsers',
      'searchChannels',
    ]),
    selectCategory(category) {
      this.category = category;
      // this.performSearch();
    },
    async performSearch() {
      function injectCategory(array, category) {
        return array.map((result) => ({ ...result, category }));
      }

      if (this.query.length > 0) {
        this.loading = true;
        this.$emit('searchStatus', true);

        switch (this.category) {
          case 'all':
          default:
            this.searchResults = [
              ...injectCategory(await this.searchRoundByHashtag(this.query), 'hashtags'),
              ...injectCategory(await this.searchUsers(this.query), 'users'),
              ...injectCategory(await this.searchChannels(this.query), 'channels'),
            ];
            break;

          case 'users':
            this.searchResults = injectCategory(await this.searchUsers(this.query), 'users');
            break;

          case 'hashtags':
            this.searchResults = injectCategory(await this.searchRoundByHashtag(this.query), 'hashtags');
            break;

          case 'channels':
            this.searchResults = injectCategory(await this.searchChannels(this.query), 'channels');
            break;
        }

        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-wrapper {
  position: relative;

  input {
    width: 100%;
    box-sizing: border-box;
    padding-right: 48px;
  }

  svg {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.search-results {
  margin-top: 32px;

  .status {
    width: max-content;
    margin: auto;
    color: $clr-text-trietary;

    svg {
      margin-top: 16px;
      width: 32px;
      height: 32px;
    }
  }
}
</style>
