<template>
  <div>
    <h1 class="title">
      {{ $t('search.top_users') }}
    </h1>

    <SearchResult
      v-for="user in users"
      :key="user.idt_user"
      :search-result="user"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import SearchResult from './Search/SearchResult.vue';

export default {
  name: 'UsersTop',
  components: {
    SearchResult,
  },
  data() {
    return {
      users: [],
    };
  },
  async mounted() {
    this.users = await this.fetchUsersTop();
  },
  methods: {
    ...mapActions(['fetchUsersTop']),
  },
};
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 16px;
}
</style>
