<template>
  <div
    class="all-watched"
  >
    <CheckCircleIcon />
    <div>
      {{ $t("all_watched") }}
    </div>
    <div
      class="btn"
      @click="$emit('refetch')"
    >
      {{ $t("refresh") }}
    </div>
  </div>
</template>

<script>
import CheckCircleIcon from '@/components/icons/CheckCircle.vue';

export default {
  emits: ['refetch'],
  components: {
    CheckCircleIcon,
  },
};
</script>

<style lang="scss" scoped>
.all-watched {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font: $font-rn-400;
  z-index: 30;

  > * {
    margin-bottom: 8px;
  }

  .btn {
    color: var(--clr-brand);
  }
}
</style>
