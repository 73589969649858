export default function (to, from, next) {
  const { query } = to;
  const entity = query.type;

  const targetRoutes = {
    post: { name: 'Round', params: { id: query.id } },
    // prof: { name: 'Profile', params: { id: query.id } },
    prof: {
      name: 'GetApp',
      params: {
        desktopRedirect: `/profile/${query.id}`,
      },
    },
    channel: { name: 'Messenger', params: { chatId: query.id } },
    'email-verify': { name: 'EmailVerify', params: { code: query.code } },
  };

  const notFound = { name: 'NotFound' };

  next(targetRoutes[entity] ?? notFound);
}
