<template>
  <span>
    <router-link
      :to="{ name: 'Profile', params: { id: notif.user_from.idt_user } }"
      class="username-highlight"
    >
      @{{ notif.user_from.username }}
    </router-link>
    {{ $t('notif.liked_your_post') }}
    <div class="time">
      {{ notif.time }}
    </div>
  </span>
</template>

<script>
export default {
  props: {
    notif: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
