import Sn from '../Sn';

// Handles:
// https://arapi.gora.studio/doc/#api-Datatype-min_sn
export default function subscriberFromBackend(data) {
  const sn = new Sn({
    id: data.id,
    name: data.name,
    nameRu: data.name_ru,
    username: data.username,
    avatar: data.avatar,
    description: data.description,
    isDefault: data.is_default,
    inviteeCount: data.invitee_count,
  });

  return sn;
}
