import axios from 'axios';
import Vue from 'vue';

import api from '@/api';
import chatNumFromId from '@/views/Messenger/chatNumFromId';
import chatTypeFromId from '@/views/Messenger/chatTypeFromId';

import tokenManager from '../../api/tokenManager';
import initialState from '../initialState';
import jinnyChat from '../jinnyChat';

import chatFromBackend from '@/models/dto/chatFromBackend';
import subscriberFromBackend from '@/models/dto/subscriberFromBackend';

function countUnread(chats) {
  return chats.reduce((total, value) => total + value.unread_count, 0);
}

export default () => ({
  state: initialState.messenger,
  getters: {
    unreadDm: (state) => countUnread(state.chats.filter((chat) => chat.type === 'dm')),
    unreadGroup: (state) => countUnread(state.chats.filter((chat) => chat.type === 'group')),
    unreadChannel: (state) => countUnread(state.chats.filter((chat) => chat.type === 'channel')),
    unreadTotal: (state) => countUnread(state.chats),
    allChats: (state) => state.chats,
    jinnyHistory: (state) => state.jinnyHistory.map((message, index) => ({
      ...message, id: index,
    })),
  },
  mutations: {
    setChats(state, chats) {
      state.chats = chats;
    },
    addChat(state, chat) {
      state.chats = [...state.chats, chat];
    },
    removeChat(state, chatId) {
      state.chats = state.chats.filter((chat) => chat.id !== chatId);
    },
    floatChat(state, chat) {
      if (chat.type !== 'support') {
        const currentPosition = state.chats.findIndex((e) => e.id === chat.id);
        state.chats.splice(1, 0, state.chats.splice(currentPosition, 1)[0]);
      }
    },
    setArchivedChats(state, chats) {
      state.archivedChats = chats;
    },
    addArchivedChat(state, chat) {
      state.archivedChats = [...state.archivedChats, chat];
    },
    removeArchivedChat(state, chatId) {
      state.archivedChats = state.archivedChats.filter((chat) => chat.id !== chatId);
    },
    selectMessage(state, id) {
      state.selectedMessageId = id;
    },
    updateChat(state, chat) {
      state.chats.forEach((element, index) => {
        if (element.id === chat.id) {
          Vue.set(state.chats, index, chat);
        }
      });
    },
    setJinnyHistory(state, history) {
      state.jinnyHistory = history;
    },
    appendJinnyHistory(state, message) {
      state.jinnyHistory.unshift(message);
    },
  },
  actions: {
    async openMsgSocket({ commit }) {
      return new Promise((resolve) => {
        const websocketURL = process.env.VUE_APP_MSG_URL.replace('https', 'wss');
        const socket = new WebSocket(
          `${websocketURL}/v3/stream?token=${tokenManager.getToken()}`,
        );
        commit('setMessageSocket', socket);
        resolve();
      });
    },

    async fetchChannelsCategories({ commit }) {
      const categories = await api.messenger.fetchChannelCategories();
      commit('setChannelsCategories', categories);
      return categories;
    },

    async fetchChannelsFeed({ commit, rootState }, offset) {
      let feed;
      const { personalFeedSelected, selectedChannelsCategory } = rootState.api.feed;

      if (personalFeedSelected) {
        feed = await api.messenger.fetchPersonalChannelsFeed({
          categoryId: selectedChannelsCategory,
          offset,
        });
      } else if (selectedChannelsCategory !== null) {
        feed = await api.messenger.fetchCategoryChannelsFeed({
          categoryId: selectedChannelsCategory,
          offset,
        });
      } else {
        feed = await api.messenger.fetchPopularChannelsFeed({
          snId: rootState.api.sn.selectedSn.id,
          offset,
          limit: 50,
        });
      }

      commit('appendChannelsFeed', feed);
      return feed;
    },

    async getChats({ commit }, { archived }) {
      const chats = await api.messenger.fetchChats({
        archived,
        limit: 200,
      });

      if (archived) {
        commit('setArchivedChats', chats.map(chatFromBackend));
      } else {
        commit('setChats', chats);
      }
      return chats;
    },

    async fetchMessages({ rootState }, {
      messageId, fwdLimit, backLimit, radiusLimit,
    }) {
      if (!fwdLimit && !backLimit && !radiusLimit) {
        backLimit = 40;
      }

      const response = await api.messenger
        .fetchMessages({
          chatId: rootState.route.params.chatId,
          backLimit,
          radiusLimit,
          fwdLimit,
          messageId,
        });

      return response;
    },

    readMessages({ rootState, getters }, { message, firstUnreadId }) {
      if (firstUnreadId
        && message.author_id !== getters.profile.id
        && message.id >= firstUnreadId) {
        api.messenger.readMessages({
          chatId: rootState.route.params.chatId,
          messageId: message.id,
        });
      }
    },

    sendMessage({ rootState, dispatch }, message) {
      message.text = message.text?.trim();

      if (rootState.route.params.chatId === 'jinny') {
        return dispatch('jinnySend', message.text);
      }
      return api.messenger.sendMessage(message);
    },

    likeMessage({ _ }, {
      chatId, id, like,
    }) {
      return api.messenger.likeMessage({
        chatId,
        messageId: id,
        like,
      });
    },

    editMessage({ _ }, { chatId, messageId, text }) {
      return api.messenger.editMessage({
        chatId,
        messageId,
        text,
      });
    },

    pinMessage({ _ }, { chatId, id, pinned }) {
      return api.messenger.pinMessage({
        chatId,
        messageId: id,
        pinned,
      });
    },

    getPinnedMessage({ _ }, chatId) {
      return api.messenger.fetchPinnedMessage({ chatId });
    },

    deleteMessage({ _ }, { chatId, messageId, forAll }) {
      return api.messenger.deleteMessage({
        chatId,
        messageId,
        forAll,
      });
    },

    async uploadFile({ _ }, { file, onProgress, vm }) {
      const formData = new FormData();
      formData.append('file', file);

      const { cancel, result } = await api.messenger.uploadFile({
        formData,
        onProgress,
      });

      if (vm) {
        vm.$emitter.on('cancelAttachmentUpload', () => {
          cancel();
        });
      }

      return result.then((response) => response.data);
    },

    searchInChats({ _ }, query) {
      return api.messenger.searchMessages({ query });
    },

    jinnySend({ state, getters, commit }, message_text) {
      // Instantly add sent message to local message history
      const userMessage = {
        text: message_text,
        author_id: getters.profile.id,
      };
      commit('appendJinnyHistory', userMessage);

      // Convert local message history to a simple array
      // Slice + reverse to not mutate original array
      const guttedHistory = state.jinnyHistory.slice().reverse().map((message) => message.text);
      return axios.post('https://geenny.arround.world/geennybot/api/send_message', {
        message_text,
        user_id: getters.profile.id,
        message_history: guttedHistory,
      })
        .then((response) => {
          // Add responding message when recieved
          const responseMessage = {
            text: response.data.text,
            author_id: 'jinny',
          };
          commit('appendJinnyHistory', responseMessage);
          // commit('setJinnyHistory', response.data.history);
          return response.data;
        });
    },

    async archiveChat({ rootState, commit }, archived) {
      const chat = await api.messenger.archiveChat({
        chatId: rootState.route.params.chatId,
        archived,
      });

      commit('updateChat', chat);
      if (archived) {
        commit('removeChat', chat.id);
        commit('addArchivedChat', chatFromBackend(chat));
      } else {
        commit('addChat', chat);
        commit('removeArchivedChat', chat.partner_id ?? chat.id);
      }
      return chat;
    },

    async muteChat({ rootState, commit }, muted) {
      const chat = await api.messenger.muteChat({
        chatId: rootState.route.params.chatId,
        muted,
      });
      commit('updateChat', chat);
      return chat;
    },

    getChat({ _ }, {
      longId, chatId, partnerId, groupId, channelId,
    }) {
      if (longId) {
        return api.messenger.fetchChat({
          ...{
            chatId: chatTypeFromId(longId) === 'dm' ? longId : undefined,
            groupId: chatTypeFromId(longId) === 'group' ? chatNumFromId(longId) : undefined,
            channelId: chatTypeFromId(longId) === 'channel' ? chatNumFromId(longId) : undefined,
            support: chatTypeFromId(longId) === 'support',
          },
        });
      }
      return api.messenger.fetchChat({
        chatId,
        partnerId,
        groupId,
        channelId,
      });
    },

    getGroup({ _ }, { id }) {
      return api.messenger.fetchGroup({ id });
    },

    getChannel({ _ }, { id }) {
      return api.messenger.fetchChannel({ id });
    },

    async getGroupSubs({ _ }, { id }) {
      const users = await api.messenger.fetchGroupSubs({ id });
      return users.map(subscriberFromBackend);
    },

    async getChannelSubs({ _ }, { id }) {
      const users = api.messenger.fetchChannelSubs({ id });
      return users.map(subscriberFromBackend);
    },

    updateGroup({ _ }, {
      id, name, username, description,
    }) {
      return api.messenger.updateGroup({
        id,
        name,
        username,
        description,
      });
    },

    updateChannel({ _ }, {
      id, name, username, description,
    }) {
      return api.messenger.updateChannel({
        id,
        name,
        username,
        description,
      });
    },

    async joinChannel({ commit }, { id, code }) {
      const chat = await api.messenger.joinChannel({ id, code });
      commit('addChat', chat);
      return chat;
    },

    deleteChat({ commit }, longId) {
      api.messenger.deleteChat({ longId });
      commit('removeChat', longId);
    },
  },
});
