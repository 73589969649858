import axios from 'axios';

import base from './base';
import onlyData from './onlyData';

export default {
  fetchChannelCategories: () => base.msg()
    .get(('/explore/categories'))
    .then(onlyData),

  fetchPersonalChannelsFeed: ({ categoryId, offset }) => base.msg().get('/channel/feed', {
    params: {
      category_id: categoryId ?? undefined,
      message_id: offset,
    },
  }).then(onlyData),

  fetchCategoryChannelsFeed: ({ categoryId, offset }) => base.msg().get('/explore/category', {
    params: {
      category_id: categoryId,
      offset,
    },
  }).then(onlyData),

  fetchPopularChannelsFeed: ({ snId, offset, limit }) => base.msg().get('/explore/popular', {
    params: {
      sn_id: snId,
      offset,
      limit,
    },
  }).then(onlyData),

  fetchChats: ({ archived, limit }) => base.msg().get('/chat/list', {
    params: {
      archived,
      limit,
    },
  }).then(onlyData),

  fetchMessages: ({
    chatId,
    backLimit,
    radiusLimit,
    fwdLimit,
    messageId,
  }) => base.msg().get('/message/list', {
    params: {
      chat_id: chatId,
      back_limit: backLimit,
      radius_limit: radiusLimit,
      fwd_limit: fwdLimit,
      message_id: messageId,
    },
  }).then(onlyData),

  readMessages: ({ chatId, messageId }) => base.msg().put('/chat/read', {
    chat_id: chatId,
    message_id: messageId,
  }).then(onlyData),

  sendMessage: (message) => base.msg().post('/message/message', message)
    .then(onlyData)
    .catch((error) => error.response?.data),

  likeMessage: ({ chatId, messageId, like }) => base.msg().put('/message/like', {
    chat_id: chatId,
    message_id: messageId,
    like,
  }).then(onlyData),

  editMessage: ({ chatId, messageId, text }) => base.msg().put('/message/message', {
    chat_id: chatId,
    message_id: messageId,
    text,
  }).then(onlyData),

  pinMessage: ({ chatId, messageId, pinned }) => base.msg().put('/message/pin', {
    chat_id: chatId,
    message_id: messageId,
    pinned,
  }).then(onlyData),

  fetchPinnedMessage: ({ chatId }) => base.msg().get('/message/pin', {
    params: {
      chat_id: chatId,
    },
  }).then(onlyData)
    .catch((error) => error.response?.data),

  deleteMessage: ({ chatId, messageId, forAll }) => base.msg().delete('/message/message', {
    params: {
      chat_id: chatId,
      message_id: messageId,
      for_all: forAll,
    },
  }).then(onlyData),

  uploadFile: ({ formData, onProgress }) => new Promise((resolve) => {
    const { CancelToken } = axios;
    let cancel;

    resolve({
      result: base.msg().post('/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: onProgress,
        cancelToken: new CancelToken((c) => cancel = c), // eslint-disable-line
      }),
      cancel,
    });
  }),

  searchMessages: ({ query }) => base.msg().get('/message/search', {
    params: {
      query,
    },
  }).then(onlyData),

  searchChannels: ({ query, snId, limit }) => base.msg().get('/channel/public/search', {
    params: {
      query,
      sn_id: snId,
      limit,
    },
  }).then(onlyData),

  fetchDmId: ({ partnerId }) => base.msg().get('/dm/chat/id', {
    params: {
      partner_id: partnerId,
    },
  }).then(onlyData),

  archiveChat: ({ chatId, archived }) => base.msg().put('/chat/archive', {
    chat_id: chatId,
    archived,
  }).then(onlyData),

  muteChat: ({ chatId, muted }) => base.msg().put('/chat/mute', {
    chat_id: chatId,
    muted,
  }).then(onlyData),

  fetchChat: ({
    chatId, partnerId, groupId, channelId, support,
  }) => base.msg().get('/chat/chat', {
    params: {
      chat_id: chatId,
      partner_id: partnerId,
      group_id: groupId,
      channel_id: channelId,
      support,
    },
  }).then(onlyData),

  fetchGroup: ({ id }) => base.msg().get('/group/group', {
    params: {
      group_id: id,
    },
  }).then(onlyData),

  fetchChannel: ({ id }) => base.msg().get('/channel/channel', {
    params: {
      channel_id: id,
    },
  }).then(onlyData),

  fetchGroupSubs: ({ id }) => base.msg().get('/group/subscriber/list', {
    params: {
      group_id: id,
    },
  }).then(onlyData),

  fetchChannelSubs: ({ id }) => base.msg().get('/channel/subscriber/list', {
    params: {
      channel_id: id,
    },
  }).then(onlyData),

  updateGroup: ({
    id, name, description, username,
  }) => base.msg().put('/group/group', {
    group_id: id,
    name,
    description,
    username,
  }).then(onlyData),

  updateChannel: ({
    id, name, description, username,
  }) => base.msg().put('/channel/channel', {
    channel_id: id,
    name,
    description,
    username,
  }).then(onlyData),

  joinChannel: ({ id, code }) => base.msg().post('/channel/join', {
    channel_id: id,
    invite_code: code,
  }).then(onlyData),

  deleteChat: ({ longId }) => base.msg().delete('/chat/chat', {
    params: {
      chat_id: longId,
    },
  }).then(onlyData),
};
