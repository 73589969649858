<template>
  <form @submit.prevent>
    <input
      v-model="phone"
      v-maska="'+# (###) ###-##-##'"
      type="tel"
      class="phone"
    >
    <div
      v-if="invalidPhone"
      class="error-msg"
    >
      {{ $t("login.invalid_phone") }}
    </div>
    <div
      v-else-if="!codeRequestStatus.possible"
      class="error-msg"
    >
      {{ $t("login.rate_limit") }}
      {{ codeRequestStatus.until }}
      {{ $t("date.interval.second").toLowerCase().charAt(0) }}
    </div>
    <button
      :disabled="!validPhone || !codeRequestStatus.possible"
      @click="sendCode"
    >
      {{ $t("continue") }}
    </button>
    <p class="legal">
      {{ $t("legal.agree") }}
      «<router-link
        :to="{ name: 'Legal', params: { document: 'terms-of-service' } }"
        target="_blank"
      >
        {{
          $t("legal.tos_agree")
        }}
      </router-link>»
      {{ $t("and") }}
      «<router-link
        :to="{ name: 'Legal', params: { document: 'privacy-policy' } }"
        target="_blank"
      >
        {{
          $t("legal.policy_agree")
        }}
      </router-link>»
    </p>
    <AppLinks
      :color="$scss.n10"
      class="apps"
    />
  </form>
</template>

<script>
import AppLinks from '@/components/AppLinks.vue';

import toE164 from './toE164phone';

export default {
  emits: ['requestCode', 'startCountdown'],
  components: {
    AppLinks,
  },
  props: {
    codeRequestStatus: {
      type: Object,
      default: () => ({
        possible: true,
        until: 0,
      }),
    },
  },
  data() {
    return {
      phone: '+7 ',
      invalidPhone: false,
    };
  },
  computed: {
    validPhone() {
      return toE164(this.phone).length === 12;
    },
  },
  methods: {
    sendCode() {
      this.$emit('requestCode', toE164(this.phone));
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: flex;
  flex-direction: column;

  > * {
    margin: 12px 0;
    border-radius: 12px;
    border: none;
  }

  .apps {
    text-align: center;
    margin: 24px auto;
  }

  .phone {
    padding: 8px 12px;
    font: $font-rw-400;
    background: $clr-10;
    border: solid 1px transparent;
  }

  .error {
    border: solid 1px $clr-error;
  }

  .error-msg {
    margin-top: 0;
    color: $clr-error;
    font: $font-rn-200;
  }

  button {
    font: $font-sbw-400;
    padding: 12px 12px;
    background: $grad-brand;
    color: white;
    cursor: pointer;

    span {
      color: white;
    }
  }

  button:disabled {
    cursor: default;
    background: $clr-text-trietary;
  }

  .legal {
    text-align: center;
    padding: 12px 12px 0 12px;
    font: $font-rn-300;
    color: $clr-text-secondary;

    a {
      color: var(--clr-brand);
      cursor: pointer;
    }
  }
}
</style>
