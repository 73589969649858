<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      defaultSn: (state) => state.api.sn.defaultSn ?? state.api.sn.selectedSn,
    }),
    brand() {
      return {
        contactEmail: 'support@zhara.social',
      };
    },
  },
};
</script>

<!-- eslint-disable -->
<template>
  <div class="container">
    <h1>Пользовательское соглашение</h1>
    <div>{{ defaultSn.name }} INC</div>
    <div>Версия 2.2 | Создано: 11.05.2021</div>
    <p>Добро пожаловать в {{ defaultSn.name }} (далее “Платформа”)!</p>
    <p>
      Настоящее Соглашение регулирует использование вами Платформы
      {{ defaultSn.name }} и содержит информацию о Платформе {{ defaultSn.name }},
      указанную ниже. Текст Соглашения является публичной офертой, создавая
      аккаунт в {{ defaultSn.name }}, вы подтверждаете, что ознакомились с текущей
      версией Соглашения и безоговорочно принимаете настоящие условия
      (производите акцепт оферты), а Соглашение приобретает юридическую силу в
      отношениях между вами и Платформой {{ defaultSn.name }}.
    </p>
    <br />
    <h2>Платформа {{ defaultSn.name }}</h2>
    <p>
      Мы соглашаемся предоставлять вам доступ к платформе {{ defaultSn.name }}.
      Платформа включает в себя все продукты, функции, приложения, сервисы,
      технологии и программное обеспечение {{ defaultSn.name }}, которые мы
      предоставляем для всех желающих.
    </p>
    <p>Платформа включает следующие компоненты:</p>
    <ul>
      <li>
        Предложение персонализированной возможности создавать контент, общаться,
        узнавать новое и обмениваться опытом и впечатлениями с другими людьми.
      </li>
      <br />
      <li>Создание благоприятной, безопасной и открытой для всех среды.</li>
      <br />
      <li>
        Мы разрабатываем особые инструменты и предлагаем участникам нашего
        сообщества инновационные технологии, которые помогают сделать
        {{ defaultSn.name }}
        Вселенной, благоприятной и открытой в том виде, в каком каждый того
        хочет.
      </li>
      <br />
      <li>Поддержание стабильной экосистемы для пользователей Платформы.</li>
      <br />
      <li>
        Связь между вами и интересующими вас брендами, товарами иуслугами.
      </li>
      <br />
      <li>Исследования и инновации.</li>
    </ul>
    <br />
    <h2>Политика использования данных</h2>
    <p>
      В Политике конфиденциальности разъясняется, как мы собираем, используем и
      передаем информацию в различных сервисах {{ defaultSn.name }}. Чтобы
      использовать {{ defaultSn.name }}, вы должны принять
      <router-link
        :to="{ name: 'Legal', params: { document: 'privacy-policy' }}"
        target="_blank"
      >
        Privacy Policy
      </router-link>.
    </p>
    <br />
    <h2>Ваши обязательства</h2>
    <p>
      В обмен на наше обязательство предоставить доступ к Платформе вы берете на
      себя определенные обязательства перед нами.
    </p>
    <h3>Кто может использовать {{ defaultSn.name }}.</h3>
    <p>
      Мы хотим, чтобы наша Платформа была максимально открытой и доступной для
      всех, но вместе с тем безопасной, защищенной и соответствующей требованиям
      законодательства. Поэтому, чтобы стать частью сообщества {{ defaultSn.name }},
      вы должны соблюдать некоторые ограничения:
    </p>
    <ul>
      <li>
        Вам должно быть как минимум 12 лет или вы должны достичь минимального
        возраста, начиная с которого в вашей стране законодательно разрешено
        использовать {{ defaultSn.name }}.
      </li>
      <br />
      <li>
        Ваш аккаунт не должен быть ранее отключен нами за нарушение
        законодательства или каких-либо наших правил.
      </li>
      <br />
      <li>
        Вы не должны быть осуждены за совершение преступления на сексуальной или
        террористической почве.
      </li>
    </ul>
    <h3>Запрещенные способы использования {{ defaultSn.name }}.</h3>
    <p>
      Предоставление безопасной и открытой Платформы широкому сообществу
      требует, чтобы каждый из нас соблюдал правила.
    </p>
    <ul>
      <li>
        Вы не имеете права выдавать себя за других людей или указывать неточную
        информацию, размещать в рамках Платформы какую-либо информацию от имени
        третьих лиц, не имея на это соответствующего разрешения/согласия.
      </li>
      <br />
      <li>
        Вы не обязаны раскрывать свою личность в {{ defaultSn.name }}, но вы должны
        предоставить нам точную и актуальную информацию (в том числе
        регистрационную).
      </li>
      <br />
      <li>
        Вы не имеете права совершать незаконные, вводящие в заблуждение или
        обманные действия либо иные действия в незаконных или
        несанкционированных целях. Что включает в себя поддержку или восхваление
        террористической деятельности, организованной преступности и групп
        ненависти. В {{ defaultSn.name }} запрещено предлагать и оказывать
        сексуальные услуги, покупать и продавать огнестрельное оружие, алкоголь
        и табачные изделия в индивидуальном порядке, а также покупать и
        продавать наркотики, психотропные препараты или отпускаемые по рецепту
        лекарственные препараты (даже если они разрешены в вашем регионе). В
        {{ defaultSn.name }} cтрого запрещено браконьерство и продажа животных
        вымирающих видов, а также частей их тел. В {{ defaultSn.name }} запрещено
        рекламировать и каким-либо иным образом агитировать информацию о
        террористической деятельности, организованной преступности и групп
        ненависти, сексуальные услуги, огнестрельное оружие, алкоголь и табачные
        изделия в индивидуальном порядке, а также наркотики или отпускаемые по
        рецепту лекарственные препараты (даже если они разрешены в вашем
        регионе). Мы считаем недопустимыми угрозы опубликовать интимные фото
        других людей, а также публикацию материалов сексуального характера с
        участием несовершеннолетних.
      </li>
      <br />
      <li>
        Вы не имеете права нарушать (а также помогать другим нарушать или
        поощрять нарушения со стороны других лиц) настоящие Условия или наши
        политики, в том числе, в частности,
        <router-link
          :to="{ name: 'Legal', params: { document: 'privacy-policy' }}"
          target="_blank"
        >
          Privacy Policy
        </router-link>.
      </li>
      <br />
      <li>
        Вы не имеете права препятствовать нормальной работе Платформы или
        вмешиваться в его работу, предпринимать какие бы то ни было попытки
        несанкционированного доступа к любой части или функциям Платформы, или к
        любой сети, соединенной с Платформой.
      </li>
      <br />
      <li>
        Вы не имеете права предпринимать попытки создания аккаунтов, сбора
        информации или доступа к ней несанкционированными способами. Сюда
        относятся создание аккаунтов и сбор информации с помощью
        автоматизированных средств без нашего прямого разрешения - использование
        каких-либо алгоритмов, методов, методик, процессов, устройств для
        получения доступа к информации третьих лиц с использованием Платформы;
        получение доступа, приобретение, копирование или отслеживание любой
        части Платформы, для воспроизведения или получения доступа в обход
        используемой системы навигации или отображения Платформы; получение или
        попытки получения любых материалов, документов или информации при помощи
        любых средств, специально не обеспечиваемых через Платформу.
      </li>
      <br />
      <li>
        Вы не имеете права предпринимать попытки купить, продать или передать
        какой-либо компонент вашего аккаунта (в том числе ваше имя пользователя)
        либо запрашивать, собирать, обрабатывать или использовать учетные и
        персональные данные или аватары других пользователей.
      </li>
      <br />
      <li>
        Вы не имеете права публиковать чужую частную или конфиденциальную
        информацию или совершать какие-либо действия, нарушающие права других
        лиц, в том числе права на интеллектуальную собственность.
      </li>
      <br />
      <li>
        Вы не имеете права использовать при работе с Платформой ненормативные,
        нецензурные, бранные, оскорбительные, угрожающие, дискриминирующие (по
        любому признаку) слова и выражения, использовать в качестве аватарок,
        прикреплять к комментариям, либо размещать ссылки на изображения,
        содержащие элементы порнографии, насилия, пропаганды терроризма,
        неонацизма, дискриминации по любому признаку, алкогольной продукции или
        наркотических средств, нецензурные, оскорбительные сообщения,
        ненормативную лексику.
      </li>
      <br />
      <li>
        Вы не имеете права оскорблять, порочить, унижать, угрожать, причинять
        ущерб деловой репутации или каким-либо иным образом ущемлять права
        других Пользователей и третьих лиц,
      </li>
      <br />
      <li>
        Вы не имеете права размещать, публиковать или распространять
        противозаконную, непристойную, вредоносную информацию, информацию,
        провоцирующую или пропагандирующую противозаконную и преступную
        деятельность, межнациональные или этнические конфликты, войны,
        столкновения, мятежи, общественные волнения, распространять информацию,
        разжигающую расовую или межнациональную ненависть и вражду, размещать
        инструкции по совершению преступных или противозаконных действий,
        инструкции по совершению суицида.
      </li>
      <br />
      <li>
        Вы не имеете права распространять какие-либо программные вирусы, трояны,
        поврежденные файлы, а также любые другие элементы разрушительного или
        вводящего в заблуждение характера.
      </li>
    </ul>
    <p>
      Если вы заметили что-то из вышеперечисленного, вы можете пожаловаться на
      контент, который, по вашему мнению, является незаконным или нарушает ваши
      права на интеллектуальную собственность через Сообщение команде
      {{ defaultSn.name }} в приложении или можете написать нам на почту
      <a :href="`mailto:${brand.contactEmail}`">{{ brand.contactEmail }}</a>
    </p>
    <h3>Предоставляемые вами разрешения.</h3>
    <p>
      В рамках нашего соглашения вы также даете нам разрешения, необходимые для
      предоставления доступа к Платформе.
    </p>
    <ul>
      <li>
        Ваши права на ваш контент остаются неизменными. Мы не претендуем на
        права собственности на ваш контент, публикуемый вами на Платформе или с
        ее помощью. Вместо этого, когда вы публикуете или загружаете контент,
        являющийся объектом прав на интеллектуальную собственность (например,
        фото или видео), на нашу Платформу или делитесь таким контентом, вы
        даете нам неисключительную, не требующую лицензионных отчислений,
        действующую по всему миру лицензию с правом передачи и выдачи
        сублицензий на хранение, использование, распространение, изменение,
        запуск, копирование, публичное исполнение или показ, перевод вашего
        контента и создание производных работ на его основе. Вы можете в любое
        время прекратить действие этой лицензии, удалив свой контент или
        аккаунт. Чтобы узнать, как мы используем информацию и как управлять
        вашим контентом или удалить его, ознакомьтесь с нашей
        <router-link
          :to="{ name: 'Legal', params: { document: 'privacy-policy' }}"
          target="_blank"
        >
          Privacy Policy
        </router-link>.
      </li>
      <br />
      <li>
        Разрешение на использование вашего имени пользователя, фото профиля и
        информации о ваших отношениях и действиях в связи с аккаунтами, рекламой
        и спонсорским контентом.
      </li>
      <br />
      <li>
        Вы даете нам разрешение на показ вашего имени пользователя, фото профиля
        и информации о ваших действиях (например, отметках "Нравится") или
        отношениях (например, подписках) рядом или в связи с аккаунтами,
        рекламой, предложениями или другим спонсорским контентом, на которые вы
        подписываетесь или с которыми вы взаимодействуете.
      </li>
      <br />
      <li>
        Вы соглашаетесь с тем, что встроенные сервисы могут скачивать и
        устанавливать обновления Платформы на ваше устройство.
      </li>
    </ul>
    <br />
    <h2>Монетизация</h2>
    <p>
      Мы даем креативным и талантливым людям уникальный шанс заработать на своем
      контенте. Для этого в {{ defaultSn.name }} предусмотрена возможность заработка
      для каждого пользователя. С полной информацией о возможностях заработка и
      о размерах вознаграждения за те или иные действия в {{ defaultSn.name }} вы
      можете ознакомиться в Профиле Пользователя в разделе Кошелек.
    </p>
    <p>
      Валютой расчётов между Сторонами является Евро (Euro), Доллар (USD), либо
      любая форма электронной (цифровой, криптографической) валюты в
      соответствии с отдельными правилами, разрабатываемыми и утверждаемыми
      Компанией для целей осуществления платежей и расчетов при работе Сервиса.
    </p>
    <p>
      Выплата вознаграждения осуществляется Компанией в течение 30 рабочих дней
      с даты размещения суммы вознаграждения в разделе Кошелек при условии
      предоставления Пользователем всей необходимой информации для выплаты
      вознаграждения.
    </p>
    <p>
      При выплате вознаграждения в размере, превышающем 1 999 Евро, со стороны
      кредитных организаций к {{ defaultSn.name }} могут предъявляться
      дополнительные требования в части обоснования осуществляемых платежей. В
      этом случае срок осуществления платежа, продлевается на срок выполнения
      требований кредитной организации. При этом Компания вправе запрашивать у
      Пользователя дополнительные документы и информацию, необходимые для
      осуществления платежа. В случае непредставления необходимых документов и
      информации, Пользователь не может предъявлять какие-либо требования
      Компании.
    </p>
    <p>
      Все выплаты производятся Пользователю за вычетом налога на добавленную
      стоимость, налога с продаж или иных налогов, если применимым
      законодательством обязанность по исчислению и уплате такого налога
      возложена на {{ defaultSn.name }}.
    </p>
    <p>
      Ответы на все интересующие вопросы можно задать через Сообщение команде
      {{ defaultSn.name }} в приложении или написать нам на почту
      <a :href="`mailto:${brand.contactEmail}`">{{ brand.contactEmail }}</a
      >.
    </p>
    <br />
    <h2>Триалы, демо- и бета- версии</h2>
    <p>
      Время от времени {{ defaultSn.name }} может предлагать пользователям
      возможность доступа и использования пробных, бета- или демо-версий
      Платформы для общедоступного коммерческого выпуска Платформы. Испытания,
      бета-версии или демо-версии могут (по усмотрению {{ defaultSn.name }}) быть
      ограничены по объему и предлагаются только пользователям, отвечающим
      определенным критериям, определенным {{ defaultSn.name }}. Кроме того, на
      пробные, бета- или демо-версии могут распространяться дополнительные
      условия.
    </p>
    <p>
      Испытания, бета-версии и демо-версии часто предлагаются в течение
      ограниченного периода времени для тестирования новых функций или функций
      приложения перед запуском таких функций. {{ defaultSn.name }} оставляет за
      собой право приостановить доступ к пробным, бета- или демо-версиям (или их
      частям) в любое время, без предварительного уведомления.
    </p>
    <p>
      Вы признаете и соглашаетесь, что пробные версии, бета-версии или
      демо-версии Приложения выпускаются только в целях тестирования и
      улучшения, в частности, для того, чтобы предоставить
      {{ defaultSn.name }} обратную связь о качестве и удобстве использования таких
      пробных версий, бета-версий или демо-версий. Некоторые функции также могут
      быть отключены в тестировании, бета-версии или демо-версии. Кроме того,
      бета-версии или демо-версии могут содержать ошибки и создавать
      несовместимости или повреждения для вашего мобильного устройства. В связи
      с этим, мы рекомендуем Вам сохранять полные резервные копии любой системы,
      которую Вы выбрали для установки или использования пробных, бета- или
      демо-версий приложения.
    </p>
    <br />
    <h2>Дополнительные права, которые мы сохраняем за собой</h2>
    <ul>
      <li>
        {{ defaultSn.name }} вправе в любое время производить работы по модификации
        программного обеспечения, составляющего Платформу, в целях улучшения
        Платформы, устранения неисправностей, ошибок, сбоев в работе Платформы,
        а также приостановить работу Платформы при обнаружении
        несанкционированного доступа к Платформе.
      </li>
      <br />
      <li>
        Если вы используете контент, являющийся объектом прав на
        интеллектуальную собственность, имеющийся у нас и предоставляемый нами
        на нашей Платформе (например, при создании поста и вы можете добавлять
        в него предоставляемые нами 3Д модели, изображения, оформление, видео
        или звуки), мы оставляем за собой все права на наш контент (но не на ваш
        контент).
      </li>
      <br />
      <li>
        Вы можете использовать нашу интеллектуальную собственность и товарные
        знаки только при наличии нашего предварительного письменного разрешения.
        Для этого напишите нам на почту
        <a :href="`mailto:${brand.contactEmail}`">{{ brand.contactEmail }}</a>
      </li>
      <br />
      <li>
        Вы должны получить письменное разрешение у нас или разрешение в рамках
        открытой лицензии, прежде чем изменять, создавать производные работы,
        декомпилировать или пытаться иным образом получить у нас исходный код.
      </li>
      <br />
      <li>
        Налоги и сборы с сумм вознаграждения, подлежащие исчислению и уплате в
        соответствии с применимым законодательством, вы оплачиваете
        самостоятельно. {{ defaultSn.name }} не несет ответственность за
        невыполнение вами своих налоговых обязательств. Вы не в праве требовать
        у {{ defaultSn.name }} выплаты возмещения (компенсации) налоговых или иных
        обязательных платежей, удержанных Компанией во исполнение требований
        применимого законодательства, с сумм выплаченного вам вознаграждения.
      </li>
    </ul>
    <br />
    <h2>
      Удаление контента и временное или постоянное отключение вашего аккаунта
    </h2>
    <p>
      Мы вправе удалить любой контент или информацию, которыми вы делитесь на
      Платформе, если сочтем, что они нарушают настоящие Условия использования
      или наши правила, либо если это разрешено или предусмотрено требованиями
      законодательства. Мы можем отказаться или немедленно прекратить
      предоставлять вам доступ к Платформе полностью или частично (в том числе
      отключить ваш аккаунт на временной или постоянной основе) с целью защиты
      нашего сообщества или сервисов либо при условии, что вы создаете риск или
      неблагоприятные правовые последствия для нас, нарушаете настоящие Условия
      использования или наши правила, систематически нарушаете права на
      интеллектуальную собственность других людей либо это разрешено или
      предусмотрено требованиями законодательства. В случае принятия мер по
      временному или постоянному отключению вашего аккаунта мы уведомим вас,
      если это будет уместно. Если вы считаете, что ваш аккаунт был отключен по
      ошибке, либо вы хотите отключить или удалить свой аккаунт, напишите нам
      <a :href="`mailto:${brand.contactEmail}`">{{ brand.contactEmail }}</a
      >.
    </p>
    <br />
    <h2>При возникновении разногласий</h2>
    <p>
      Любые поправки к нашему соглашению или отказы от него должны оформляться
      письменно и подписываться нами. Если Вы хотите отказаться от настоящего
      соглашения, то Вы должны направить на электронную почту
      <a :href="`mailto:${brand.contactEmail}`">{{ brand.contactEmail }}</a>
      заявление об этом в формате отсканированного письменного документа,
      удостоверенного Вашей подписью и прекратить любое использование Платформы.
      Если мы не добиваемся принудительного исполнения какого-либо аспекта
      настоящего соглашения, это не считается отказом от него.
    </p>
    <h3>Кто обладает правами по настоящему соглашению.</h3>
    <p>Настоящее соглашение не дает никаких прав третьим сторонам.</p>
    <p>
      Вам запрещается передавать свои права или обязанности по настоящему
      соглашению без нашего согласия.
    </p>
    <p>
      Мы вправе уступать наши права и обязанности другим лицам. Например, это
      может произойти в случае смены собственника (при слиянии, поглощении или
      продаже активов) или в силу закона.
    </p>
    <h3>Кто понесет ответственность, если что-нибудь случится.</h3>
    <p>
      Наша Платформа предоставляется "как есть", и мы не можем гарантировать ее
      безопасность, защиту и идеальную работу. Мы не гарантируем Пользователю
      достижения каких-либо результатов вследствие использования Платформы, а
      также соответствие Платформы или отдельных ее элементов ожиданиям
      Пользователя. В ТОЙ МЕРЕ, В КАКОЙ ЭТО РАЗРЕШЕНО ЗАКОНОМ, МЫ ТАКЖЕ
      ОТКАЗЫВАЕМСЯ ОТ ВСЕХ ПРЯМО ВЫРАЖЕННЫХ И ПОДРАЗУМЕВАЕМЫХ ГАРАНТИЙ, В ТОМ
      ЧИСЛЕ ПОДРАЗУМЕВАЕМЫХ ГАРАНТИЙ ПРИГОДНОСТИ ДЛЯ ЦЕЛЕЙ, В КОТОРЫХ ОБЫЧНО
      ИСПОЛЬЗУЮТСЯ ТАКИЕ ПРОДУКТЫ, ИЛИ ДЛЯ КОНКРЕТНЫХ ЦЕЛЕЙ, НЕОТЧУЖДАЕМОСТИ
      ПРАВА СОБСТВЕННОСТИ И ОТСУТСТВИЯ НАРУШЕНИЙ ПРАВ НА ИНТЕЛЛЕКТУАЛЬНУЮ
      СОБСТВЕННОСТЬ.
    </p>
    <p>
      Мы также не контролируем высказывания и действия людей и других лиц и не
      несем ответственности за их (или ваши) действия и поведение (в сети
      Интернет и вне ее) или контент (в том числе незаконный или
      предосудительный). Кроме того, мы не несем ответственности за сервисы и
      функции, предлагаемые другими людьми или компаниями, даже если вы
      осуществляете доступ к ним через нашу Платформу. Размещаемые на Платформе
      ссылки на любые сайты, продукты, услуги, любую информацию коммерческого
      или некоммерческого характера, не является одобрением или рекомендацией
      данных продуктов (услуг) со стороны {{ defaultSn.name }}.
    </p>
    <p>
      Наша ответственность за все, что происходит в рамках Платформы (также
      именуемая "ответственностью") ограничивается в максимальном объеме,
      разрешенном законом. В случае возникновения проблем с нашей Платформой мы
      не в состоянии предсказать все их возможные последствия. Вы соглашаетесь,
      что мы не будем нести ответственность за любую упущенную прибыль или
      выручку, потерянную информацию или данные либо за косвенные, штрафные или
      побочные убытки, возникающие из настоящих Условий или в связи с ними, даже
      если нам было известно о возможности их возникновения. Это положение
      распространяется в том числе на удаление нами вашего контента, информации
      или аккаунта, а также на убытки, которые могут быть причинены вам в
      результате невозможности использования Платформы вследствие ошибок,
      пропусков, перерывов в работе, удаления файлов, дефектов, задержек в
      работе или передаче данных и других причин технологического характера, а
      также ненадлежащего качества каналов связи общего пользования, посредством
      которых вы осуществляете доступ к Платформе.
    </p>
    <p>
      Пока не доказано обратное, любые действия, совершенные с использованием
      аккаунта Пользователя Платформы, считаются совершенными соответствующим
      Пользователем.
    </p>
    <p>
      Вы должны самостоятельно обеспечивать конфиденциальность своих Учетных
      данных, не передавать свои Учетные данные третьим лицам и самостоятельно
      выбирать способ их хранения.
    </p>
    <h3>Как мы решаем споры</h3>
    <p>
      В случае возникновения между Сторонами разногласий или споров,
      {{ defaultSn.name }}
      будет предпринимать все усилия к их разрешению путем внутренних
      переговоров. Оставшиеся неурегулированными разногласия, касающиеся условий
      настоящего Договора, разрешаются в арбитражном суде Республики Кипр, в
      соответствии с процессуальными правилами и действующего закона.
    </p>
    <h3>Материалы, представляемые по вашей инициативе.</h3>
    <p>
      Нам важны отзывы и другие предложения, но мы можем использовать их без
      каких-либо ограничений или обязанностей по выплате вам вознаграждения за
      них, а также не обязаны хранить их в тайне.
    </p>
    <br />
    <h2>Поправки и изменения</h2>
    <p>
      Настоящие Условия использования представляют собой полное соглашение между
      {{ defaultSn.name }} и Пользователем относительно Платформы и заменяют все
      предыдущие соглашения и договоренности между нами.
      {{ defaultSn.name }} оставляет за собой право изменять или модифицировать
      настоящие Условия использования в любое время. Любые изменения в настоящих
      Условиях использования будут заблаговременно уведомляться Пользователям и
      вступать в силу с момента принятия Пользователями. Если Вы не принимаете
      измененные или новые Условия использования, Вы не имеете права доступа,
      пользования или продолжения пользования Платформой.
      {{ defaultSn.name }} рекомендует Вам периодически просматривать Условия
      использования для того, чтобы быть информированным о любых изменениях.
      {{ defaultSn.name }} далее оставляет за собой право изменять или
      модифицировать содержимое Приложения в любое время без предварительного
      уведомления и не будет нести никакой ответственности перед любой стороной
      за возможные последствия таких изменений. {{ defaultSn.name }} может
      приостановить, прекратить или ограничить доступ к любой части Платформы в
      любое время и без предварительного уведомления.
    </p>
    <p>
      Используя Платформу после вступления в силу изменений и/или дополнений, Вы
      соглашаетесь на любые и все такие изменения и/или дополнения.
    </p>
    <p>
      {{ defaultSn.name }} не берет на себя обязательств по архивированию предыдущих
      версий настоящих Условий использования. Вместо этого
      {{ defaultSn.name }} рекомендует вам распечатать и сохранить копию каждой
      версии настоящих Условий использования.
    </p>
    <br />
    <h2>Контактная информация</h2>
    <p>
      Вопросы, комментарии и пожелания относительно данного Соглашения
      направлять по адресу:
    </p>
    <p>Boumpoulinas, 1, 3rd floor, Flat/Office 31, 1060, Nicosia, Cyprus</p>
    <p>
      <a :href="`mailto:${brand.contactEmail}`">{{ brand.contactEmail }}</a>
    </p>
  </div>
</template>
