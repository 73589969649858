import Channel from '../Channel';

// handles https://arround-msg.gora.studio/doc/#api-Channel-get
export default function channelFromBackend(data) {
  const channel = new Channel();

  channel.id = data.id;
  channel.admin = data.admin;
  channel.muted = data.muted;
  channel.name = data.name;
  channel.username = data.username;
  channel.avatar = data.avatar;
  channel.createdAt = new Date(data.created_at);
  channel.description = data.description;
  channel.subscriber = data.subscriber;
  channel.subscriberCount = data.subscriber_count;

  return channel;
}
