<template>
  <div class="counter">
    <EyeIcon />&nbsp;
    {{ $formatNum(post.viewCount) }}
  </div>
</template>

<script>
import EyeIcon from '@/components/icons/Eye.vue';

import Message from '@/models/Message';
import Round from '@/models/Round';

export default {
  components: {
    EyeIcon,
  },
  props: {
    post: {
      type: [Round, Message],
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  color: inherit;
}

.counter {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
