<template>
  <div
    v-if="number !== 0"
    class="bubble"
  >
    {{ number }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    type: {
      type: String,
      default: 'total',
    },
  },
  computed: {
    ...mapGetters([
      'unreadTotal',
      'unreadDm',
      'unreadChannel',
      'unreadGroup',
    ]),
    number() {
      switch (this.type) {
        default:
          return this.unreadTotal;
        case 'dm':
          return this.unreadDm;
        case 'channel':
          return this.unreadChannel;
        case 'group':
          return this.unreadGroup;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bubble {
  min-width: 16px;
  width: fit-content;
  height: 16px;
  border-radius: 11px;
  background-color: var(--clr-brand);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 4px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}
</style>
