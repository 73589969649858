<template>
  <div class="stream-card">
    <div
      class="preview"
      @click="openStream"
    >
      <img
        :src="stream.preview"
      >
      <div class="overlay">
        {{ $t('stream.watch') }}
      </div>
    </div>
    <div class="info">
      <router-link
        :to="{ name: 'Profile', params: { id: stream.idt_user } }"
      >
        <img
          :src="stream.avatar"
          class="avatar supel"
        >
      </router-link>
      <div>
        <router-link
          class="username"
          :to="{ name: 'Profile', params: { id: stream.idt_user } }"
        >
          {{ stream.username }}
        </router-link>
        {{ stream.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  props: {
    stream: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapMutations(['selectStream']),
    openStream() {
      this.$router.push({ name: 'Stream', params: { id: this.stream.idt_stream } });
    },
  },
};
</script>

<style lang="scss" scoped>
.stream-card {
  position: relative;
  width: 100%;
  background: white;
  border-radius: 16px;
  margin: 0 0 32px 0;
  padding: 16px;
  box-sizing: border-box;

  .preview {
    position: relative;
    width: 100%;
    border-radius: 16px;
    cursor: pointer;

    img {
      width: inherit;
      border-radius: inherit;
      height: auto;
      display: block;
    }

    .overlay {
      border-radius: inherit;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: opacity .8s;
      background: rgba(0, 0, 0, 0.3);
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;

      &:hover {
        opacity: 1;
      }
    }
  }

  .info {
    margin-top: 16px;
    display: flex;
    font: $font-rn-300;

    .avatar {
      width: 32px;
      height: 32px;
      margin-right: 16px;
    }

    .username {
      font: $font-sbn-300;
    }
  }
}
</style>
