<template>
  <div>
    <video
      loop
      :src="round.video"
      :poster="round.preview"
      :preload="round.preview ? 'none' : 'metadata'"
      :muted="muted"
      @click.stop="roundClick"
    />
    <div
      v-if="!playing"
      class="playbtn"
    />
  </div>
</template>

<script>
import VideoRound from '@/models/VideoRound';

export default {
  props: {
    round: {
      type: VideoRound,
      required: true,
    },
    muted: {
      type: Boolean,
    },
  },
  data() {
    return {
      playing: false,
    };
  },
  methods: {
    roundClick(e) {
      if (e.target.paused) {
        this.playing = true;
        e.target.play();
      } else {
        this.playing = false;
        e.target.pause();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
video {
  display: block;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.playbtn {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: no-repeat center url("~@/assets/icons/play-white.svg"),
    no-repeat center url("~@/assets/icons/transbg.svg");
}
</style>
