<template>
  <div class="media">
    <EmbeddedVideo
      :video="round.media"
      pillarboxed
      class="player"
    />
  </div>
</template>

<script>
import EmbeddedVideo from '@/components/EmbeddedVideo.vue';

export default {
  components: {
    EmbeddedVideo,
  },
  props: {
    round: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import './media';

.player {
  width: 100%;
  max-height: calc(100vh - #{$top-nav-height});
}
</style>
