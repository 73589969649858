import Chat from '../Chat';

// handles https://arround-msg.gora.studio/doc/#api-Datatype-chat
export default function chatFromBackend(data) {
  const chat = new Chat({
    id: data.partner_id || data.group_id || data.channel_id,
    type: data.type,
    name: data.name,
    username: data.username,
    avatar: data.avatar,
    pinned: data.pinned,
    admin: data.admin,
    verified: data.verified,
    muted: data.muted,
    archived: data.archived,
    firstUnreadId: data.first_unread_id,
    unreadCount: data.unread_count,
    createdAt: new Date(data.created_at),
    updatedAt: new Date(data.updated_at),
    lastMessage: data.last_message,
  });

  if (data.type === 'support') {
    chat.id = data.id;
  }

  return chat;
}
