import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import api from './api/index';
import initialState from './initialState';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    locale: state.locale,

    api: {
      profile: {
        profile: state.api.profile.profile,
      },
      feed: {
        personalFeedSelected: state.api.feed.personalFeedSelected,
        selectedChannelsCategory: state.api.feed.selectedChannelsCategory,
      },
      messenger: {
        jinnyHistory: state.api.messenger.jinnyHistory,
      },
    },
  }),
});

export default new Vuex.Store({
  state: initialState.root,
  mutations: {
    setRoute(state, route) {
      state.route = route;
    },
    setLocale(state, { locale, vm }) {
      if (locale) {
        state.locale = locale;
      }
      vm.$root.$i18n.locale = state.locale;
    },
  },
  modules: {
    api,
  },
  plugins: [vuexLocal.plugin],
});
