<template>
  <div class="dumb-input">
    <input
      :value="content"
      @input="handleInput"
      @keydown.enter="handleSend"
    >
    <div
      :class="{ disabled: !hasText }"
      class="send"
      @click="handleSend({ preventDefault: ()=>{} })"
    >
      <ArrowIcon />
    </div>
  </div>
</template>

<script>
import ArrowIcon from '@/components/icons/Arrow.vue';

export default {
  components: {
    ArrowIcon,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      content: this.value,
    };
  },
  computed: {
    hasText() {
      function onlyWhitespace(str) {
        return !str.replace(/\s/g, '').length;
      }
      return !onlyWhitespace(this.content);
    },
  },
  methods: {
    handleInput(event) {
      this.content = event.target.value;
      this.$emit('input', this.content);
    },
    handleSend(event) {
      if (this.content) {
        this.content = '';
        event.preventDefault();
        this.$emit('send', event);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dumb-input {
 position: relative;

  input {
    word-break: break-all;
    font: $font-rw-400;
    width: 100%;
    border-radius: 18px;
    border: none;
    padding: 6px 48px 6px 15px;
    box-sizing: border-box;
    background-color: $clr-10;
    overflow: auto;

    &:focus {
      outline: none;
    }
  }

  .send {
    position: absolute;
    cursor: pointer;
    right: 4px;
    bottom: 50%;
    background: var(--clr-brand);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotateZ(180deg) scale(1) translateY(-50%);
    transition: background 0.3s;

    &.disabled {
      background: $clr-40;
    }

    svg {
      color: white;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .msg-input {
    input {
      font: $font-rw-500;
    }

    .send {
      width: 35px;
      height: 35px;
    }
  }
}
</style>
