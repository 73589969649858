import api from '@/api';

export default class Round {
  id;

  title;

  description;

  liked;

  lat;

  lng;

  viewCount;

  likeCount;

  preview;

  createdAt;

  author;

  commentCount;

  hasThreeD;

  link;

  constructor(round) {
    Object.assign(this, round);
  }

  like() {
    this.liked = true;
    this.likeCount += 1;
    api.feed.likeRound({ id: this.id });
    return this;
  }

  unlike() {
    this.liked = false;
    this.likeCount -= 1;
    api.feed.likeRound({ id: this.id });
    return this;
  }
}
