<template>
  <div class="round-page">
    <Back class="back-arrow" />

    <div v-if="round && mobile">
      <RoundComponent
        class="round"
        :round-raw="round"
        @openComments="openComments"
      />

      <Modal ref="commentsModal">
        <Comments :round="roundFromBackend(round)" />
      </Modal>
    </div>
    <div
      v-else-if="round"
      class="round"
    >
      <component
        :is="mediaComponent"
        :round="round"
      />
      <Comments
        :round="roundFromBackend(round)"
        class="comments"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import Back from '@/components/Back.vue';
import Comments from '@/components/Comments/Index.vue';
import Modal from '@/components/Modal.vue';
import RoundComponent from '@/components/Post/Index.vue';

import PhotoMedia from './PhotoMedia.vue';
import VideoMedia from './VideoMedia.vue';

import roundFromBackend from '@/models/dto/roundFromBackend';

export default {
  name: 'Round',
  components: {
    RoundComponent,
    Modal,
    Comments,
    Back,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      mobile: undefined,
      round: undefined,
      mediaComponent: undefined,
    };
  },
  async mounted() {
    this.roundFromBackend = roundFromBackend;
    this.mobile = window.innerWidth <= this.$scss.mobileBreakpoint;
    this.round = await this.getRound(this.id);
    this.mediaComponent = this.round.media ? VideoMedia : PhotoMedia;
  },
  methods: {
    ...mapActions(['getRound']),
    openComments() {
      this.$refs.commentsModal.open();
    },
  },
};
</script>

<style lang="scss" scoped>
.round-page {
  position: relative;
  padding-top: $top-nav-height !important;
  box-sizing: border-box;
  height: 100vh;

  .back-arrow {
    position: absolute;
    left: 32px;
    top: calc(32px + #{$top-nav-height});
    z-index: 20;
  }

  .round {
    position: relative;
    display: flex;
    height: 100%;

    .comments {
      padding: 16px;
      width: 400px;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .round-page {
    padding-top: 0 !important;
    height: 100%;

    .back-arrow {
      top: 32px;
    }

    .round {
      height: calc(100vh - 100px);
    }
  }
}
</style>
