<template>
  <component
    :is="isAuthenticated ? 'router-link' : 'div'"
    class="badge"
    :to="link"
    @click="!isAuthenticated && $emitter.emit('unauthedAction')"
  >
    <img
      :src="poster.avatar"
      class="avatar supel"
    >
    <div class="username">
      <h2>{{ poster.name }}</h2>
      <span class="secondary">{{ poster.username }}
        <span v-if="createdAt">· {{ timeSince(createdAt.getTime()/1000) }}</span>
      </span>
    </div>
  </component>
</template>

<script>
import { mapGetters } from 'vuex';

import formatDateTimeMixin from '@/mixins/formatDateTimeMixin';
import linkToChatMixin from '@/mixins/linkToChatMixin';

import Channel from '@/models/Channel';
import User from '@/models/User';

export default {
  mixins: [formatDateTimeMixin, linkToChatMixin],
  props: {
    poster: {
      type: [User, Channel],
      required: true,
    },
    createdAt: {
      type: Date,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
    }),
    link() {
      if (this.poster instanceof User) {
        return this.poster.link;
      }
      return this.getLinkToChat(this.poster);
    },
  },
};
</script>

<style lang="scss" scoped>
>>> {
  --primary: #{$clr-text-primary};
  --secondary: #{$clr-text-secondary};
}

.badge {
  display: flex;
  margin-bottom: 16px;
  cursor: pointer;

  .username {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    h2 {
      font: $font-sbw-400;
      color: var(--primary);
    }

    .secondary {
      font: $font-rn-300;
      color: var(--secondary);
    }
  }

  .avatar {
    min-width: 48px;
    width: 48px;
    height: 48px;
    object-fit: cover;
    object-position: center;
    margin-right: 12px;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .badge[data-theme='dark'] {
    --primary: white;
    --secondary: rgba(255, 255, 255, .8);
  }
}
</style>
