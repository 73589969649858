import axios from 'axios';

import api from '@/api';
import tokenManager from '@/api/tokenManager';

import initialState from '../initialState';

import feed from './feed';
import interesting from './interesting';
import messenger from './messenger';
import profile from './profile';
import sn from './sn';
import stream from './stream';

const API_URL = process.env.VUE_APP_API_URL;
const MSG_URL = process.env.VUE_APP_MSG_URL;

const Api = {
  v1: axios.create({
    baseURL: `${API_URL}/v1`,
  }),

  v2: axios.create({
    baseURL: `${API_URL}/v2`,
  }),

  msg: axios.create({
    baseURL: `${MSG_URL}/v3`,
  }),
};

const feedModule = feed(Api);
const profileModule = profile(Api);
const messengerModule = messenger();
const streamModule = stream(Api);
const interestingModule = interesting(Api);
const snModule = sn();

const apiModule = {
  state: initialState.api,

  mutations: {
    logout(state) {
      tokenManager.setToken();

      Object.keys(initialState.api).forEach((key) => {
        if (key !== 'sn') {
          state[key] = initialState.api[key];
        }
      });

      Object.keys(initialState.feed).forEach((key) => {
        state.api.feed[key] = initialState.feed[key];
      });

      Object.keys(initialState.messenger).forEach((key) => {
        state.api.messenger[key] = initialState.messenger[key];
      });
    },
    setToken(state, token) {
      if (token) {
        tokenManager.setToken(token);
      }

      Object.keys(Api).forEach((version) => {
        if (tokenManager.getToken()) {
          Api[version].defaults.headers.common['x-access-token'] = tokenManager.getToken();
        }
      });
    },
    setMessageSocket(state, data) {
      state.msgSocket = data;
    },
  },

  actions: {
    report({ _ }, {
      target, target_id, type, comment,
    }) {
      return Api.v1.post('/user/report', {
        target,
        target_id,
        type,
        comment,
      });
    },

    listNotifications({ _ }, { offset, limit }) {
      return Api.v2
        .get('/user/notifies', {
          params: {
            offset,
            limit,
          },
        })
        .then((response) => response.data.notifies);
    },

    async login({ commit }, accessToken) {
      commit('setToken', accessToken);

      const profileResponse = await api.user.fetchProfile({});
      commit('setProfile', profileResponse.profile);
    },

    async logout({ commit }) {
      await api.auth.logout();
      commit('logout');
      commit('setProfile', null);

      Object.keys(Api).forEach((version) => {
        delete Api[version].defaults.headers.common['x-access-token'];
      });
    },
  },

  getters: {
    isAuthenticated: (state) => !!state.profile.profile,
    msgSocket: (state) => state.msgSocket,
  },

  modules: {
    feed: feedModule,
    profile: profileModule,
    messenger: messengerModule,
    stream: streamModule,
    interesting: interestingModule,
    sn: snModule,
  },
};

export default apiModule;
