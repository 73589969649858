<template>
  <component
    :is="link ? 'router-link' : 'div'"
    class="entity"
    :to="link"
  >
    <img
      class="supel avatar"
      :src="entity.avatar || ''"
    >
    <div class="names">
      <div
        class="name"
        :class="{ 'white-text': whiteText }"
      >
        {{ entity.name }}
      </div>
      <div
        class="username"
        :class="{ 'white-text': whiteText }"
      >
        {{ entity.username }}
      </div>
    </div>
  </component>
</template>

<script>
export default {
  props: {
    entity: {
      type: Object,
      required: true,
    },
    link: {
      type: [Object, null],
      default: null,
    },
    whiteText: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.entity {
  display: flex;
  align-items: center;
  gap: 8px;

  .avatar {
    min-width: 32px;
    width: 32px;
    height: 32px;
  }

  .names, .name, .username {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .name {
    font: $font-sbn-400;

    &.white-text {
      color: white;
    }
  }

  .username {
    font: $font-rn-200;
    color: $clr-text-secondary;

    &.white-text {
      color: $clr-20;
    }
  }
}
</style>
