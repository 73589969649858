<template>
  <div
    class="sidebar"
  >
    <div
      v-if="!footerOnly"
      class="main-links"
    >
      <SidebarItem
        :name="$t('posts')"
        :active="feedType === 'rounds'"
        type="rounds"
      >
        <RoomIcon :class="{ active: feedType === 'rounds' }" />
      </SidebarItem>

      <SidebarItem
        :name="$t('videos')"
        :active="feedType === 'videos'"
        type="videos"
      >
        <VideoIcon :class="{ active: feedType === 'videos' }" />
      </SidebarItem>

      <SidebarItem
        :name="$t('channels')"
        :active="feedType === 'channels'"
        type="channels"
      >
        <ChannelsIcon :class="{ active: feedType === 'channels' }" />
      </SidebarItem>

      <!-- <SidebarItem
        :name="$t('streams')"
        :active="feedType === 'streams'"
        type="streams"
      >
        <StreamsIcon :class="{ active: feedType === 'streams' }" />
      </SidebarItem> -->
    </div>
    <AppLinks
      color="white"
      class="apps"
    />
    <div
      class="footnote"
    >
      <LanguageSelector />
      <div class="bottom-links">
        <a href="https://arround.world">
          {{ $t("about_project") }}
        </a>
        <router-link
          :to="{ name: 'Legal', params: { document: 'terms-of-service' } }"
          target="_blank"
        >
          {{ $t("legal.tos") }}
        </router-link>
        <router-link
          :to="{ name: 'Legal', params: { document: 'privacy-policy' } }"
          target="_blank"
        >
          {{ $t("legal.policy") }}
        </router-link>
      </div>
      <div>© {{ year }} {{ selectedSn.name }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import ChannelsIcon from '@/components/icons/Channels.vue';
import RoomIcon from '@/components/icons/Room.vue';
// import StreamsIcon from '@/components/icons/Streams.vue';
import VideoIcon from '@/components/icons/Video.vue';

import AppLinks from '@/components/AppLinks.vue';

import LanguageSelector from './LanguageSelector.vue';
import SidebarItem from './SidebarItem.vue';

export default {
  name: 'Sidebar',
  components: {
    SidebarItem,
    AppLinks,
    LanguageSelector,
    RoomIcon,
    VideoIcon,
    ChannelsIcon,
    // StreamsIcon,
  },
  props: {
    footerOnly: Boolean,
  },
  computed: {
    ...mapState({
      selectedSn: (state) => state.api.sn.selectedSn,
    }),
    feedType() {
      if (this.$route.params.type) {
        return this.$route.params.type;
      }
      return null;
    },
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  .main-links {
    display: flex;
    flex-direction: column;
    background: $clr-05;
    z-index: 10;

    svg {
      color: $clr-text-trietary;

      &.active {
        color: white;
      }
    }
  }

  .apps {
    margin-top: 45px;
  }

  .footnote {
    margin-top: 20px;
    font: $font-rn-300;
    color: $clr-text-secondary;

    > * {
      margin-bottom: 14px;
    }

    .bottom-links {
      display: flex;
      flex-wrap: wrap;

      > * {
        margin-right: 0.5rem;
      }
    }
  }
}
</style>
