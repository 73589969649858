<template>
  <div class="description">
    <div class="icons">
      <LikeCounter
        class="icon"
        :post="round"
      />
      <div class="icon">
        <CommentIcon />
        {{ round.commentCount }}
      </div>
      <span class="spacer" />
      <div
        class="icon btn"
        @click="reportRound"
      >
        <FlagIcon />
      </div>
    </div>

    <!-- eslint-disable -->
    <!-- Вроде как vue-highlights защищен от xss -->
    <div
      class="message"
      v-html="highlightedMessage"
    />
    <!-- eslint-enable -->
    <ReportModal
      :id="round.id"
      ref="report"
      target="round"
    />
  </div>
</template>

<script>
import { autoLink } from 'vue-highlights';

import CommentIcon from '@/components/icons/Comment.vue';
import FlagIcon from '@/components/icons/Flag.vue';

import highlightOptions from '@/components/highlightOptions';
import LikeCounter from '@/components/LikeCounter.vue';
import ReportModal from '@/components/ReportModal.vue';

import Round from '@/models/Round';

export default {
  components: {
    ReportModal,
    LikeCounter,
    CommentIcon,
    FlagIcon,
  },
  props: {
    round: {
      type: Round,
      required: true,
    },
  },
  computed: {
    highlightedMessage() {
      return autoLink(this.round.description, highlightOptions);
    },
  },
  methods: {
    async reportRound() {
      this.$refs.report.open();
    },
  },
};
</script>

<style lang="scss" scoped>
.description {
  font: $font-rn-400;

  .icons {
    display: flex;

    .icon {
      display: inline-flex;
      margin-right: 16px;

      svg, .like {
        margin-right: 8px;
        height: 22px;
        width: 22px;

        &.liked {
          background: $grad-red;
          mask: url('~@/assets/icons/heart-filled.svg') no-repeat center;
        }
      }
    }

    .spacer {
      flex-grow: 1;
    }
  }

  .message {
    margin-top: 24px;
    line-break: anywhere;
  }
}
</style>
