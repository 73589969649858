<template>
  <div class="streams-feed">
    <router-link
      class="create-stream supel"
      :to="{ name: 'CreateStream' }"
    >
      <PlusIcon />
    </router-link>

    <div class="half">
      <StreamCard
        v-for="stream in evenFeed"
        :key="stream.idt_stream"
        :stream="stream"
      />
    </div>
    <div class="half">
      <StreamCard
        v-for="stream in oddFeed"
        :key="stream.idt_stream"
        :stream="stream"
      />
    </div>

    <div
      v-if="noContent"
      class="all-watched"
    >
      {{ $t("all_watched") }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import PlusIcon from '@/components/icons/Plus.vue';

import StreamCard from './StreamCard.vue';

export default {
  name: 'DesktopStreamsFeed',
  components: {
    StreamCard,
    PlusIcon,
  },
  data() {
    return {
      feed: [],
    };
  },
  computed: {
    ...mapState({
      personalFeedSelected: (state) => state.api.feed.personalFeedSelected,
    }),
    evenFeed() {
      return this.feed.filter((_, index) => index % 2 === 0);
    },
    oddFeed() {
      return this.feed.filter((_, index) => index % 2 === 1);
    },
    noContent() {
      return this.feed.length === 0;
    },
  },
  watch: {
    personalFeedSelected: {
      async handler() {
        this.feed = await this.fetchStreamsFeed() || [];
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(['fetchStreamsFeed']),
  },
};
</script>

<style lang="scss" scoped>
.streams-feed {
  position: relative;
  display: flex;

  .create-stream {
    position: absolute;
    transform: translateX(-100%);
    background: $grad-brand;
    cursor: pointer;
    font-weight: bold;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    svg {
      color: white;
    }
  }

  .half {
    width: 50%;
    display: flex;
    padding: 0 16px;
    box-sizing: border-box;
    flex-direction: column;
  }

  .all-watched {
    position: absolute;
    width: 100%;
    padding: 85px 0;
    text-align: center;
  }
}
</style>
