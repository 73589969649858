import Chat from './Chat';

export default class Channel extends Chat {
  description;

  subscriber;

  subs;

  subscriberCount;

  type = 'channel';

  constructor(chat) {
    super(chat);
    Object.assign(this, chat);
  }
}
