import api from '@/api';

import initialState from '../initialState';

import inviteCodeFromBackend from '@/models/dto/inviteCodeFromBackend';
import userFromBackend from '@/models/dto/userFromBackend';

export default (Api) => ({
  state: initialState.profile,
  getters: {
    profile: (state) => (state.profile ? userFromBackend(state.profile) : null),
  },
  mutations: {
    setProfile(state, profile) {
      state.profile = profile;
    },

    updateProfile(state, profile) {
      state.profile = {
        ...state.profile,
        ...profile,
      };
    },
  },
  actions: {
    async fetchUserRounds({ rootState }, { userId, offset, longVideo }) {
      const data = await api.user.fetchPosts({
        userId,
        offset,
        longVideo,
        snId: rootState.api.sn.selectedSn.id,
      });

      return data.rounds;
    },

    fetchProfile({ _ }, id) {
      let idt_user;
      let username;

      // eslint-disable-next-line
      if (id == +id) {
        idt_user = id;
      } else {
        username = id;
      }

      return Api.v1
        .get('/user/profile', {
          params: {
            idt_user,
            username,
          },
        })
        .then((result) => {
          if (result.data.profile.avatar === '') {
            result.data.profile.avatar = '';
          }

          return result.data.profile;
        });
    },

    follow({ _ }, idt_user) {
      return Api.v1
        .post('/user/follow', {
          idt_user: Number(idt_user),
        });
    },

    unFollow({ _ }, idt_user) {
      return Api.v1
        .delete(`/user/follow/${idt_user}`);
    },

    block({ _ }, idt_user) {
      return Api.v2
        .post('/user/blackList', {
          idt_user: Number(idt_user),
        });
    },

    unBlock({ _ }, idt_user) {
      return Api.v2
        .delete('/user/blackList', {
          params: {
            idt_user: Number(idt_user),
          },
        });
    },

    getBlockedUsers({ _ }) {
      return Api.v2
        .get('/user/blackList')
        .then((result) => result.data);
    },

    editProfile({ commit }, profile) {
      const cleanProfile = {};
      Object.keys(profile).forEach((key) => {
        if (profile[key] !== '') {
          cleanProfile[key] = profile[key];
        }
      });

      return Api.v1
        .put('/user/profile', cleanProfile)
        .then((response) => {
          commit('setProfile', response.data.profile);
          return response.data.success;
        })
        .catch((error) => error.response.body);
    },

    changeAvatar({ commit }, file) {
      const formData = new FormData();
      formData.append('image', file);

      return Api.v1
        .put('/user/avatar', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => commit('setProfile', response.data.profile));
    },

    async getInviteCodes({ _ }) {
      const data = await api.user.fetchInviteCodes();
      return data.map(inviteCodeFromBackend);
    },

    createInviteCode({ rootState }, value) {
      return api.user.createInviteCode({
        value,
        snId: rootState.api.sn.selectedSn.id,
      });
    },

    archiveInviteCode({ _ }, id) {
      return api.user.archiveInviteCode({ id });
    },

    emailCodeRequest({ _ }, email) {
      return Api.v2
        .post('/user/email/code', {
          email,
        });
    },

    getUserChannels({ rootState }, { userId, admin, limit }) {
      return api.user.fetchChannels({
        userId,
        admin,
        limit,
        snId: rootState.api.sn.selectedSn.id,
      });
    },
  },
});
