<template>
  <div
    ref="videoFeed"
    class="videoFeed"
  >
    <div class="bg-pattern" />
    <swiper
      ref="swiper"
      :options="swiperOptions"
      class="swiper"
      @slideChange="handleSwipe"
    >
      <swiper-slide
        v-for="(video, i) in videoFeed"
        :key="video.id"
      >
        <div class="roundContainer">
          <img
            class="poster"
            :src="video.url_preview || video.preview"
          >

          <video
            v-if="activeIndex === i && feedType === 'rounds'"
            loop
            playsinline
            webkit-playsinline
            :muted="muted"
            :poster="video.url_preview"
            preload="auto"
            class="video"
            :autoplay="interactedWith && playing"
            :src="video.media.url + '#t=0.001'"
            @canplay="canPlay = true"
            @click="playPause"
          />
          <StreamPlayer
            v-else-if="activeIndex === i && feedType === 'streams'"
            :stream="video"
            class="video"
            :muted="muted"
            @canplay="canPlay = true"
          />

          <Overlay
            :round-raw="video"
            :playing="playing || feedType === 'streams'"
            :can-play="canPlay"
            :muted="muted"
            @muteToggle="muted = !muted"
            @openComments="openComments(video)"
          />
        </div>
      </swiper-slide>
    </swiper>

    <AllWatched
      v-if="allWatched"
      @refetch="refetchFeed"
    />
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import {
  mapGetters, mapState, mapActions, mapMutations,
} from 'vuex';
import 'swiper/css/swiper.css';

import StreamPlayer from '@/components/StreamPlayer/Index.vue';

import AllWatched from './AllWatched.vue';
import Overlay from './Overlay.vue';

export default {
  name: 'MobileRoundFeed',
  components: {
    Swiper,
    SwiperSlide,
    StreamPlayer,
    Overlay,
    AllWatched,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      height: undefined,
      canPlay: false,
      playing: false,
      activeIndex: 0,
      muted: true,
      interactedWith: false,
      satDetected: false,
      commentsRound: undefined,
      streamFeed: [],
      swiperOptions: {
        direction: 'vertical',
        resistanceRatio: 0.5,
      },
    };
  },
  computed: {
    ...mapGetters({
      roundFeed: 'videoFeed',
      isAuthenticated: 'isAuthenticated',
    }),
    ...mapState({
      personalFeedSelected: (state) => state.api.feed.personalFeedSelected,
    }),
    swiper() {
      return this.$refs.swiper.$swiper;
    },
    feedType() {
      if (this.$route.params.type) {
        return this.$route.params.type;
      }
      return null;
    },
    videoFeed() {
      if (this.feedType === 'rounds') {
        return this.roundFeed.map((round) => ({
          ...round,
          id: round.idt_round,
        }));
      }
      return this.streamFeed.map((stream) => ({
        ...stream,
        id: stream.idt_stream,
      }));
    },
    sat() {
      const sat = getComputedStyle(document.body).getPropertyValue(
        '--sat',
      );
      return Number(sat.substring(0, sat.length - 2));
    },
    allWatched() {
      return this.videoFeed.length === 0;
    },
  },
  watch: {
    personalFeedSelected() {
      this.refetchFeed();
    },
    isAuthenticated() {
      this.refetchFeed();
    },
    feedType() {
      this.refetchFeed();
    },
  },
  updated() {
    // Мб отключить через какое-то время
    if (!this.satDetected && this.$refs.videoFeed) {
      this.height = this.$refs.videoFeed.clientHeight - this.sat;
      // if (this.sat) {
      //   alert(`Got sat ${this.$refs.videoFeed.clientHeight} - ${this.sat} = ${this.height}`);
      //   this.satDetected = true;
      // }
    }
  },
  created() {
    this.refetchFeed();
  },
  methods: {
    ...mapActions(['fetchRoundFeed', 'fetchStreamsFeed', 'viewRound']),
    ...mapMutations(['dropFeed']),
    async refetchFeed() {
      if (this.feedType === 'rounds') {
        this.dropFeed();
        this.fetchRoundFeed(0);
      } else {
        this.streamFeed = await this.fetchStreamsFeed();
      }
    },

    handleSwipe() {
      this.canPlay = false;
      this.activeIndex = this.swiper.activeIndex;

      this.$nextTick(() => {
        const video = this.swiper.slides[this.activeIndex].querySelector('.video');
        video.play().finally(() => {
          this.playing = !video.paused;
        });
      });

      if (this.isAuthenticated && this.feedType === 'rounds') {
        this.viewRound(this.videoFeed[this.activeIndex - 1].idt_round);
      }

      if (this.activeIndex === this.videoFeed.length - 2 && this.feedType === 'rounds') {
        this.fetchRoundFeed(this.$store.state.feed.length);
      }
    },
    pause(event) {
      event.target.pause();
    },
    async play(event) {
      if (!this.interactedWith) {
        this.interactedWith = true;
        this.muted = false;
      }

      event.target.play();
    },
    playPause(event) {
      if (this.playing) {
        this.pause(event);
      } else {
        this.play(event);
      }
      this.playing = !event.target.paused;
    },
    openComments(round) {
      this.$emit('openComments', round);
    },
  },
};
</script>

<style lang="scss" scoped>
.videoFeed {
  position: fixed;
  top: calc(env(safe-area-inset-top) * -1);
  padding-top: env(safe-area-inset-top);
  height: calc(100% - 100px);
  overflow: hidden;
  width: 100%;

  .bg-pattern {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url('~@/assets/pttrn.svg');
    opacity: .1;
  }

  .video {
    position: relative;
    top: 0;
    width: 100%;
    height: calc(100% - 100px);
    object-fit: cover;
    box-shadow: 0px -2px 8px rgba(8, 11, 48, 0.2);
  }

  .hidden {
    opacity: 0;
    //pointer-events: none;
  }

  .swiper {
    height: 100%;

    .roundContainer {
      height: 100vh;

      .poster {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }

      .poster {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        pointer-events: none;
        object-fit: cover;
      }
    }
  }
}
</style>
