<template>
  <div v-if="own" />
  <button
    v-else-if="isFollowingProxy"
    class="btn passive"
    @click="unFollow"
  >
    <SubscribedIcon />{{ $t("subscribed") }}
  </button>
  <button
    v-else
    class="btn light"
    @click="follow"
  >
    {{ $t("subscribe") }}
  </button>
</template>

<script>
import SubscribedIcon from '@/components/icons/Subscribed.vue';

export default {
  emits: ['update'],
  components: {
    SubscribedIcon,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    isFollowing: Boolean,
  },
  data() {
    return {
      isFollowingProxy: this.isFollowing,
    };
  },
  computed: {
    own() {
      return this.id === Number(this.$store.getters.profile?.id);
    },
  },
  methods: {
    async follow() {
      if (this.$store.getters.isAuthenticated) {
        this.isFollowingProxy = true;
        const result = await this.$store.dispatch('follow', this.id);
        if (result.status === 200) {
          this.$emit('update', true);
        }
      } else this.$emitter.emit('unauthedAction');
    },
    async unFollow() {
      if (this.$store.getters.isAuthenticated) {
        this.isFollowingProxy = false;
        const result = await this.$store.dispatch('unFollow', this.id);
        if (result.status === 200) {
          this.$emit('update', false);
        }
      } else this.$emitter.emit('unauthedAction');
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  font: $font-sbw-300;
  padding: 5px 16px;
  border-radius: 12px;
  height: fit-content;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    padding: 2px;
    box-sizing: border-box;
    margin-right: 6px;
  }

  &.light {
    border: 1px solid var(--clr-brand);
    color: var(--clr-brand);
  }

  &.passive {
    border: 1px solid $clr-20;
    color: $clr-text-secondary;

    svg {
      color: inherit;
    }
  }
}
</style>
