<template>
  <div class="login">
    <h1 v-if="!waitingCode">
      {{ $t("login.title", { snName: selectedSn.name, snNameRu: selectedSn.nameRu }) }}
    </h1>
    <ArrowIcon
      v-if="waitingCode"
      class="back"
      @click.native="back"
    />

    <div class="forms-wrapper">
      <CodeInput
        v-if="waitingCode"
        :code-request-status="codeRequestStatus"
        :transport="transport"
        :phone="phone"
        @requestCode="sendCode"
        @loggedIn="loggedIn"
        @newUser="handleNewUser"
      />
      <PromocodeInput
        v-else-if="waitingPromocode"
        :phone-token="phoneToken"
        @loggedIn="loggedIn"
      />
      <PhoneInput
        v-else
        :code-request-status="codeRequestStatus"
        @requestCode="sendCode"
        @startCountdown="startRequestCountdown"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import api from '@/api';

import ArrowIcon from '@/components/icons/Arrow.vue';

import CodeInput from './CodeInput.vue';
import PhoneInput from './PhoneInput.vue';
import PromocodeInput from './PromocodeInput.vue';

export default {
  name: 'LoginModal',
  components: {
    PhoneInput,
    PromocodeInput,
    CodeInput,
    ArrowIcon,
  },
  data() {
    return {
      waitingCode: false,
      waitingPromocode: false,
      phoneToken: null,
      codeRequestStatus: {
        possible: true,
        until: 0,
      },
      requestCount: 0,
      phone: undefined,
      transport: undefined,
    };
  },
  computed: {
    ...mapState({
      selectedSn: (state) => state.api.sn.selectedSn,
    }),
  },
  methods: {
    back() {
      this.waitingCode = false;
    },
    loggedIn() {
      this.$emit('loggedIn');
    },
    handleNewUser(phoneToken) {
      this.phoneToken = phoneToken;
      this.waitingCode = false;
      this.waitingPromocode = true;
    },
    startRequestCountdown() {
      if (this.codeRequestStatus.possible) {
        this.codeRequestStatus.possible = false;
        this.codeRequestStatus.until = 60;
        const timerInterval = setInterval(() => {
          this.codeRequestStatus.until -= 1;
        }, 1000);

        setTimeout(() => {
          this.codeRequestStatus.possible = true;
          clearInterval(timerInterval);
        }, 60 * 1000);
      }
    },
    async sendCode(phone) {
      let serverFails = 0;
      this.requestCount += 1;
      if (this.requestCount % 3 === 0) {
        this.transport = 'call';
      } else {
        this.transport = 'sms';
      }

      if (phone) {
        this.phone = phone;
      }

      const attemptCodeRequest = async () => {
        const toggleTransport = () => {
          if (this.transport === 'call') {
            this.transport = 'sms';
          } else {
            this.transport = 'call';
          }
        };

        const handleError = (response) => {
          const errorCode = response.code;
          switch (errorCode) {
            case 1:
              serverFails += 1;
              if (serverFails <= 2) {
                toggleTransport();
                attemptCodeRequest();
              } else {
                console.log(response.messages);
              }
              break;
            case 3:
              this.rateLimit = true;
              this.startRequestCountdown();
              break;
            default:
              console.log(response.messages);
          }
        };

        const response = await api.auth.sendPhoneCode({
          transport: this.transport,
          phone: this.phone,
        });

        if (response.code) {
          handleError(response);
        } else {
          this.waitingCode = true;
          this.startRequestCountdown();
        }
      };

      attemptCodeRequest();
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font: $font-h-300;
  position: absolute;
  top: 16px;
  left: 20px;
}

.exit,
.back {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
  top: 12px;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: $clr-text-secondary;
}

.exit {
  background-color: $clr-10;
}

.back {
  left: 16px;
  box-sizing: border-box;
  padding: 8px;
}

.forms-wrapper {
  margin-top: 14px;
}

@media screen and (max-width: $mobile-breakpoint) {
  .forms-wrapper {
    margin-top: 32px;
  }
}
</style>
