import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      msgSocket: 'msgSocket',
      isAuthenticated: 'isAuthenticated',
      profile: 'profile',
    }),
  },
  async mounted() {
    if (this.isAuthenticated) {
      this.initMessenger();
    }
  },
  watch: {
    msgSocket() {
      this.msgSocket.addEventListener('message', this.handleResponse);
    },
    async isAuthenticated(authenticated) {
      if (authenticated) {
        this.initMessenger();
      }
    },
  },
  methods: {
    ...mapActions(['getChats']),
    async initMessenger() {
      await this.getChats({ archived: false });
      await this.getChats({ archived: true });
      await this.initMessagesStream();
      this.msgSocket.addEventListener('message', this.handleResponse);
    },
    async initMessagesStream() {
      return new Promise((resolve) => {
        if (this.msgSocket) {
          this.msgSocket.addEventListener('open', () => {
            resolve();
          });

          this.msgSocket.addEventListener('close', () => {
            setTimeout(() => {
              console.log('RECONNECT');
              this.$store.dispatch('openMsgSocket');
              this.initMessagesStream();
            }, 1000);
          });

          resolve();
        } else {
          this.$store.dispatch('openMsgSocket').then(() => {
            setTimeout(() => {
              this.initMessagesStream();
            }, 1000);
          });
        }
      });
    },
    handleResponse(response) {
      if (!response.data) return;

      const handlers = {
        message_create: this.messageCreate,
      };

      const action = JSON.parse(response.data);
      const actionName = Object.keys(action)[0];

      setTimeout(() => {
        handlers[actionName]?.(action[actionName]); // eslint-disable-line
      });
    },
    messageCreate(msg) {
      if (msg.author_id !== this.profile.id) {
        this.$notification.show(`${this.$t('messenger.msg_from')} ${msg.author.name}`, {
          body: msg.text,
          icon: msg.author.avatar,
          silent: true,
        }, {
          onclick: () => {
            this.$router.push({ name: 'Messenger', params: { chatId: msg.chat_id } });
          },
        });
        const audio = new Audio(require('@/assets/sounds/spitball.mp3')); // eslint-disable-line
        audio.play();
      }
    },
  },
};
