<template>
  <div class="overlay">
    <slot />
    <div class="bottom">
      <component
        :is="isAuthenticated ? 'router-link' : 'div'"
        class="author"
        :to="round.author.link"
        @click="!isAuthenticated && $emitter.emit('unauthedAction')"
      >
        <img
          :src="round.author.avatar"
          class="avatar supel"
        >
        <div class="username">
          {{ round.author.username }}
        </div>
      </component>
      <!-- eslint-disable -->
      <!-- Вроде как vue-highlights защищен от xss -->
      <div
        class="message"
        v-html="highlightedMessage"
      />
      <!-- eslint-enable -->
    </div>
  </div>
</template>

<script>
import { autoLink } from 'vue-highlights';
import { mapGetters } from 'vuex';

import highlightOptions from '@/components/highlightOptions';

import Round from '@/models/Round';

export default {
  props: {
    round: {
      type: Object,
      required: true,
    },
    muted: Boolean,
    cached: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    highlightedMessage() {
      return autoLink(this.round.description, highlightOptions);
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  color: white;
  filter: $visibility-shadow;
  pointer-events: none;

  * {
    pointer-events: auto;
  }

  .bottom {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 16px;

    .author {
      display: flex;
      align-items: center;

      .avatar {
        width: 40px;
        height: 40px;
      }

      .username {
        margin-left: 16px;
        color: white;
        font: $font-sbw-300;
      }
    }

    .message {
      margin-top: 16px;
      color: white;
      font: $font-rw-300;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
</style>
